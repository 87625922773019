/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddRounded, VisibilityRounded, DeleteRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { format } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { valueAsDate } from '../../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useLocales from '../../../hooks/useLocales';
import { addButtonStyle, colors } from '../../../../styles/themeStylesSx';
import { getSubscriptions } from '../../../redux/admin/adminCustomerSlice';
import SkeletonRows from '../../layout/SkeletonRows';
import SidePanel from '../../sidepanel/SidePanel';
import AddEditSubscription from './AddEditSubscription';
import {SubscriptionType} from '../../../types/subscription';

type SubscriptionStateType = {
  open?: boolean;
  subscription?: SubscriptionType;
  add?: boolean;
  remove?: boolean;
};

export default function ListSubscriptions() {
  const { t } = useLocales();

  const [state, setState] = useState<SubscriptionStateType>({ open: false, add: false });
  const { subscriptions, loading, loaded, reload, loadingSubscriptions } = useAppSelector(store => store.adminCustomer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (reload && !loaded && !loadingSubscriptions) {
      dispatch(getSubscriptions());
      setState({ open: false });
    }
  }, [reload, loading, loaded, ]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <SidePanel
        open={ state.open === true }
        close={ () => setState({ open: false })}
      >
        <Box sx={{ ml: 2 }}>
          <AddEditSubscription add= {false} remove= {false} open = {true} subscription = {state.subscription} close={ () => setState({ add: false, remove: false, open: false }) } />
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.remove === true }
        close={ () => setState({ remove: false })}
      >
        <Box sx={{ ml: 2 }}>
          <AddEditSubscription add= {false} remove= {true} open = {false} subscription = {state.subscription} close={ () => setState({ add: false, remove: false, open: false }) } />
        </Box>
      </SidePanel>

      <SidePanel
        open={ state.add === true }
        close={ () => setState({ open: false })}
      >
        <Box sx={{ }}>
        <AddEditSubscription add= {true} remove= {false} open = {false } subscription = {state.subscription} close={ () => setState({ add: false, remove: false, open: false }) } />
        </Box>
      </SidePanel>

      { loading && <SkeletonRows rows={5} /> }

      <Box sx={{ width: '95%', display: { xs: 'inline-table', md: 'none' }}}>
        {
          (subscriptions || []).map((row: any, idx: number) => {
            const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
            return (<Paper sx={{ p: 1, mt: 1, mb: 1, lineHeight: 1.5, background: backgroundColor, width: '100%' }} key={idx}>
              <Box sx={{ display: 'flex'}}>
                <Box title={`${row.id}`}>{idx + 1}:  { t(row.name) }</Box>
                <Box sx={{ ml: 'auto'}}>
                  <IconButton onClick={() => setState({ open: true, subscription: row })}><VisibilityRounded /></IconButton>
                </Box>
              </Box>
            </Paper>);
          })
        }
      </Box>
      <Table sx={{ display: { xs: 'none', md: 'inline-table', color: 'secondary.main' }}}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{t('Identifier')}</TableCell>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Active')}</TableCell>
            <TableCell>{t('Created')}</TableCell>
            <TableCell>{t('Updated')}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (subscriptions || []).map( (row: any, i: number)=> {
              const dateValueCreated = valueAsDate(row.createdAt);
              const dateValueUpdated = valueAsDate(row.updatedAt);
              const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main', backgroundColor }} key={`${row.id}_row`}>
                  <TableCell sx={{
                    borderTopLeftRadius: 15, borderBottomLeftRadius: 15,
                    }}>{ row.id }</TableCell>
                  <TableCell >{ t(row.name) }</TableCell>
                  <TableCell >{ row.active ?  'Yes' : 'No' }</TableCell>
                  <TableCell >{ dateValueCreated ? format(dateValueCreated, 'dd.MM.yyyy') : '-' }</TableCell>
                  <TableCell >{ dateValueUpdated ? format(dateValueUpdated, 'dd.MM.yyyy') : '-' }</TableCell>
                  <TableCell>
                    <IconButton sx={{p: 0}} onClick={() => setState({ open: true, subscription: row })}><VisibilityRounded /></IconButton>
                  </TableCell>
                  <TableCell sx={{
                    borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                    <IconButton sx={{p: 0}} onClick={() => setState({ remove: true, subscription: row })}><DeleteRounded /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      <Box>
        <Box sx={{ mt: 2, ml: 'auto', width: '200px' }}>
          <Button
            color="secondary"
            sx={ addButtonStyle }
            variant="outlined"
            onClick={() => setState({ add: true })} endIcon={<AddRounded />}
          >
            {t('Add new subscription')}
          </Button>
        </Box>
      </Box>
    </div>
  );
}