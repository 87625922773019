
import React, { useEffect, useState } from 'react';
import { ArrowBackRounded, EditRounded } from '@mui/icons-material';
import { Button, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { UserType } from '../../types/users';
import SidePanel from '../sidepanel/SidePanel';
import { getUsers, updateUser } from '../../redux/user/adminUserSlice';
import AddEditAdminUser from './AddEditAdminUser';

export default function UserAdminComponent() {
  const { t } = useLocales();
  const params = useParams();

  const userId = params.userId ? parseInt(params.userId, 10) : undefined;

  const dispatch = useAppDispatch();
  const {
    list: listFromStore,
    reload: reloadFromStore,
    loading: loadingFromStore,
    loaded: loadedFromStore,
    saved: savedFromStore,
    saving: savingFromStore
  } = useAppSelector(store => store.adminUser);

  const user = listFromStore?.find((row: UserType) => userId && row.id === userId);

  const [state, setState] = useState({ open: false });


  useEffect(() => {
    if ((reloadFromStore && !loadedFromStore && !loadingFromStore) || (!loadedFromStore && !loadingFromStore)) {
      dispatch(getUsers());
    }
  }, [reloadFromStore, loadingFromStore, loadedFromStore]);

  useEffect(() => {
    if (savedFromStore && loadedFromStore) {
      setState({ open: false });
    }
  }, [savedFromStore, loadedFromStore]);

  const submit = (data: Partial<UserType>) => {
    if (data.id && data.id > 0) {
      dispatch(updateUser({ id: data.id as number, data }));
    }
  };

  if (!user) {
    return (
      <Box>
        {t('Not found')}
      </Box>
    );
  }
  return (
    <Box>
      <SidePanel
        open={ state.open }
        close={ () => setState({ open: false })}
      >
        <Typography sx={{ p: 2 }} variant="h6">{t('Edit user')}</Typography>
        <AddEditAdminUser saving={ savingFromStore } user={user} cancel={ () => setState({ open: false }) } submit={submit} />
      </SidePanel>
      <Link component={RouterLink} to='/admin/users'>
        <Button startIcon={<ArrowBackRounded />}>{t('Back')}</Button>
      </Link>
      <Box sx={{ p: 1 }}>
      {t('User')}: { user && user.id }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Name')}: { user.name && user.name }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Email')}: { user.email && user.email }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Phone')}: { user.email && user.phone }
      </Box>

      <Box>
        <Button onClick={() => setState({ open: true })} startIcon={<EditRounded />}>{t('Edit')}</Button>
      </Box>
    </Box>
  );
}