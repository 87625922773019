
import { AddRounded, EditRounded, PictureAsPdfRounded } from '@mui/icons-material';
import { Button, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { addUser, getUsers, deleteAdminUser } from '../../redux/user/adminUserSlice';
import SidePanel from '../sidepanel/SidePanel';
import AddEditAdminUser from './AddEditAdminUser';
import ConfirmableButton from '../buttons/ConfirmableButton';
import { UserType } from '../../types/users';
import { colors } from '../../../styles/themeStylesSx';

type UserStateType = {
  open: boolean
};
export default function ListAdminUsers(props: any) {
  const { t } = useLocales();
  const [state, setState] = useState<UserStateType>({ open: false });
  const { list, loading, loaded, reload, saving, error: loadError } = useAppSelector(store => store.adminUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (reload  && !loaded  && !loading && !loadError ) {
      dispatch(getUsers());
      setState({ open: false });
    }
  }, [reload, loading, loaded ]);
  const deleteItem = (id?: number) => {
    if (!id) {
      return;
    }
    dispatch( deleteAdminUser({ id }));
  };

  const submit = (data: Partial<UserType>) => {
    const phone = data.phone ? data.phone : '';
    if (data.name && data.email ) {
      dispatch(addUser({ name: data.name, email: data.email, phone: phone }));
    }
  };

  return (
    <div>
      <SidePanel
        open={ state.open }
        close={ () => setState({ open: false })}
      >
        <Typography sx={{ p: 2 }} variant="h6">{t('Add new user')}</Typography>
        <AddEditAdminUser saving={saving } cancel={ () => setState({ open: false }) } submit={submit} />
      </SidePanel>

      { loading  && <Skeleton animation="wave" />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Identifier')}</TableCell>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Email')}</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list?.map( (row, idx: number) => {
              return (
                <TableRow key={`${row.id}_row`} sx={{ backgroundColor: idx % 2 ? colors.backgroundGreen : '#fff' }}>
                  <TableCell>{ row.id }</TableCell>
                  <TableCell>{ row.name  }</TableCell>
                  <TableCell>{ row.email }</TableCell>

                  <TableCell>
                    <Box sx={{ display: 'flex' }}>
                      <Link to={`/admin/users/${row.id}`}>
                        <IconButton><EditRounded /></IconButton>
                      </Link>
                      <ConfirmableButton onSubmit={ () => deleteItem(row.id) } />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      <Box sx={{ mt: 2, ml: 'auto', width: '200px' }}>
        <Button
          color="secondary"
          onClick={() => setState({ open: true }) }
          startIcon={<AddRounded />}
        >{t('Add new user')}</Button>
      </Box>
    </div>
  );
}