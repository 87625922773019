import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { getDateFromStateOrObject, getValueFromStateOrObject } from "../../helpers/helpers";
import useLocales from "../../hooks/useLocales";
import { ServiceBookType, ServiceCaseType } from "../../types/serviceBook";
import { addServiceCaseAction, getServiceBookForId, updateServiceCaseAction } from '../../redux/serviceBooks/serviceBookSlice';
import SkeletonRows from '../layout/SkeletonRows';
import { showNotification } from '../../redux/notificationSlice';
import { AttachmentType } from "../../types/attachment";
import FileUpload from "../common/FileUpload";
import { uploadFilesAction } from "../../redux/attachment/attachmentSlice";
import { ClearRounded, DeleteRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { clearFixedCostsState } from '../../redux/fixedCosts/fixedCostsSlice';
import { userProfileApi } from '../../redux/services/userProfileService';

type PropsType = {
  cancel: () => void;
  serviceCase?: ServiceCaseType;
  itemType?: 'serviceCase' | 'inspectionCase';
  serviceBook?: ServiceBookType;
  source?: 'topbar' | 'servicebook' | 'dashboard';
  serviceName?: string;
}

type ServiceCaseState = {
  id?: number;
  createdAt: Date;
  servicePlanItemId: number;
  serviceBookId?: number;
  comment: string;
  cost?: string;
  date: Date;
  type: 'inspectionCase' | 'serviceCase';
  customerId?: number;
  attention?: boolean;
  action?: string;
  actionDate?: Date;
  createReminder?: boolean,
  reminderDate?: Date,
}

export default function AddEditServiceCase(props: PropsType) {
  const { serviceCase, source } = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<ServiceCaseState>>({ attention: serviceCase?.attention || false });
  const dispatch = useAppDispatch();
  const { loading: loadingAttachments, uploaded, result: uploadResult } = useAppSelector(store => store.attachment);
  const [attachments, setAttachments] = useState<Array<Partial<AttachmentType>>>([]);
  const { details: customer } = useAppSelector(store => store.customer);
  const [showDetails, toggleShowDetails] = useState(serviceCase?.actionDate ? true : false);

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });
  const serviceBook: ServiceBookType | undefined = props.serviceBook;

  const { loading, loaded, error, list, saving: savingList } = useAppSelector(store => store.serviceBook);
  const serviceBookId = serviceBook && serviceBook.id ? serviceBook.id : state.serviceBookId;
  const isLoaded = serviceBookId && loaded.includes(serviceBookId);
  const saving = serviceBookId && savingList.includes(serviceBookId);
  const loadedServiceBook = list.find(row => row.id === serviceBookId);
  const reminderAvailable = (customer?.reminders?.length || 0) < (customer?.reminderLimit || 0);

  const uploadFiles = (files: any) => {
    dispatch(uploadFilesAction(files));
  };

  useEffect(() => {
    if (serviceCase && serviceCase.attachments) {
      setAttachments(serviceCase.attachments);
    }
  }, [serviceCase]);

  useEffect(() => {
    if (uploaded && uploadResult) {
      setAttachments([...attachments, ...uploadResult]);
    }
  }, [uploaded, uploadResult]);

  useEffect(() => {
    if (!serviceBook && !isLoaded && state.serviceBookId && state.serviceBookId > 0) {
      dispatch(getServiceBookForId(state.serviceBookId));
    }
  }, [dispatch, serviceBook, isLoaded, state.serviceBookId]);

  const submit = () => {
    const data = { ...state };

    dispatch(userProfileApi.util.invalidateTags(['Reminders']));

    if ((!serviceCase || !serviceCase.id) && serviceBookId && customer) {
      const servicePlanItemId = props.serviceCase?.servicePlanItemId || data.servicePlanItemId || null;
      const actionDate = data.actionDate || (!data.attention ? data.date : undefined);

     dispatch(addServiceCaseAction({
        serviceBookId,
        data: {
          customerId: customer.id,
          serviceBookId: serviceBookId,
          comment: data.comment,
          date: data.date || new Date(),
          type: props.itemType,
          servicePlanItemId,
          cost: data.cost ? parseFloat(data.cost) : 0,
          attachments,
          action: data.action,
          actionDate,
          attention: data.attention,
          reminderDate: data.reminderDate,
        }
      }));
      dispatch(clearFixedCostsState());
    }
    else if (serviceCase && serviceCase.id) {
      const updateData: Partial<ServiceCaseType> = {
        servicePlanItemId: data.servicePlanItemId !== -1 ? data.servicePlanItemId : null,
        comment: data.comment,
        date: data.date || undefined,
        cost: data.cost ? parseFloat(data.cost) : undefined,
        attachments,
        action: data.action,
        actionDate: data.actionDate,
        attention: data.attention,
        reminderDate: data.reminderDate,
      };
      const checkInput = (data: any) => {
        const results = Object.keys(data).map((row: string) => {
          const val: any = data[row];
          if (val !== undefined) {
            return true;
          }
          return false;
        });
        return results.find(row => row === true);
      };
      if (checkInput(updateData) && serviceBookId) {
        dispatch(updateServiceCaseAction({ serviceBookId, id: serviceCase.id, data: updateData }));
        dispatch(clearFixedCostsState());
      }
      else {
        dispatch(showNotification({ message: t('Empty request, do some changes first'), severity: 'warning' }));
      }
    }
  };

  if (serviceBookId && loading.includes(serviceBookId)) {
    return <SkeletonRows rows={5} />;
  }
  if (error) {
    return (<Box sx={{ mb: 2, mt: 2 }}>{t('Error')}: {error}</Box>);
  }

  if (!serviceBook && !serviceBookId) {
    return (
      <Box sx={{ mt: 2 }}>
        <FormControl
          sx={{ mb: 1 }}
          fullWidth
        >
          <InputLabel>{t('Select service book')}</InputLabel>
          <Select
            label={t('Select service book')}
            onChange={(e: any) => handleChange('serviceBookId', parseInt(e.target.value))}
          >
            {
              customer && customer.serviceBooks.map(row =>
                <MenuItem key={`row_${row.id}`} value={`${row.id}`}>
                  {row.name}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
    </Box>
    );
  }

  const items = serviceBook?.servicePlanItems || loadedServiceBook?.servicePlanItems;

  return (
    <Box sx={{ mt: 2 }}>
      {
        source === 'topbar' && serviceBookId && <Box sx={{ display: 'flex', alignItems: 'center', pb: 1, pl: 1 }}>
          <Box>{t('Service book')}: { loadedServiceBook?.name }</Box>
          <IconButton onClick={() => setState({ ...state, serviceBookId: undefined })}><ClearRounded /></IconButton>
        </Box>
      }
      {
        (serviceCase && serviceCase.servicePlanItemId && source === 'dashboard') ? <Box sx={{ mb: 3, fontWeight: 'bold' }}>{ props.serviceName }</Box> :
          <>
            {
              items && items.length > 0 &&
              <Autocomplete
                disablePortal
                sx={{ mb: 2 }}
                id="add--user"
                noOptionsText={t('No options')}
                options={(items || []).map(row => ({ label: `${row.name}`, id: row.id })) }
                renderInput={(params) => <TextField {...params} fullWidth label={t('Select service')} />}
                onChange={(event, item: { label: string | undefined, id: number | undefined } | null) => {
                  if (item && item.id) {
                    handleChange('servicePlanItemId', item.id);
                  }
                }}
              />
            }
          </>
      }
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={t('Date')}
          inputFormat="dd.MM.yyyy"
          value={getDateFromStateOrObject(state, serviceCase, 'date', true)}
          onChange={(value: Date | null) => handleChange('date', value)}
          renderInput={(params: any) => <TextField variant="outlined" {...params} />}
        />
      </LocalizationProvider>
      <TextField
        sx={{ mt: 2 }}
        label={t('Description')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={getValueFromStateOrObject(state, serviceCase, 'comment')}
        onChange={(e) => handleChange('comment', e.target.value)}
      />

      {
        !(serviceCase && serviceCase.id) && <Box>

          <FormControlLabel sx={{ mt: 2 }} control={
            <Switch
              checked={ showDetails } onChange={ (e: any) => toggleShowDetails(e.target.checked) }
            />
          } label={t('Target needed service')}
          />
          <FormHelperText>{t('Check to add more details what was done')}</FormHelperText>
        </Box>
      }
      {
        (showDetails || (serviceCase && serviceCase.id)) && <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label={t('Date action completed')}
              inputFormat="dd.MM.yyyy"
              value={getDateFromStateOrObject(state, serviceCase, 'actionDate', true)}
              onChange={(value: Date | null) => handleChange('actionDate', value)}
              renderInput={(params: any) => <TextField sx={{ mt: 3 }} variant="outlined" {...params} />}
            />
          </LocalizationProvider>
          <TextField
            sx={{ mt: 2 }}
            label={t('Action done')}
            multiline
            rows={3}
            helperText={t('Short explanation what was done')}
            variant="outlined"
            fullWidth
            value={getValueFromStateOrObject(state, serviceCase, 'action')}
            onChange={(e) => handleChange('action', e.target.value)}
          />
          <TextField
            sx={{ mt: 2 }}
            label={`${t('Total')} (€)`}
            type="number"
            variant="outlined"
            fullWidth
            value={getValueFromStateOrObject(state, serviceCase, 'cost')}
            onChange={(e) => {
              handleChange('cost', e.target.value);
            }}
          />
        </>
      }

      {
        loadingAttachments ? <CircularProgress /> :
          <Box sx={{ mt: 2, mb: 2 }}><FileUpload onDrop={ files => uploadFiles(files) } /></Box>
      }
      {
        (attachments || []).map(attachment => {
          return (
            <Box key={`att_${attachment.id}`} sx={{ mt: 1, p: 1 }}>
              { attachment.id }: { attachment.filename } <IconButton onClick={() => setAttachments(attachments.filter(row => row.id !== attachment.id))}><DeleteRounded /></IconButton>
            </Box>
          );
        })
      }
      {
        !showDetails && <Box>
          <FormControlLabel control={
            <Switch
              checked={ state.attention } onChange={ (e: any) => handleChange('attention', e.target.checked) }
            />
          } label={t('Requires attention')}
          />
          <FormHelperText>{t('Service will be showing up in requires attention list until finished')}</FormHelperText>
        </Box>
      }
      {
        !showDetails && <Box sx={{ mt: 1 }}>
          <FormControlLabel control={
            <Switch
              disabled={!reminderAvailable}
              checked={ state.createReminder } onChange={ (e: any) => handleChange('createReminder', e.target.checked) }
            />
          } label={t('Remind me for the next service')}
          />
          <FormHelperText>{t('Create reminder for this type of plan item')}</FormHelperText>
        </Box>
      }
      {
        !reminderAvailable && <Box sx={{ my: 2 }}>{t('All reminders used ({1} pieces). Contact customer service to purchase more', [ `${customer?.reminderLimit || ''}` ])}</Box>
      }
      {
        state.createReminder && <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              label={t('Reminder date and time')}
              inputFormat="dd.MM.yyyy hh:mm"
              value={getDateFromStateOrObject(state, serviceCase, 'reminderDate', true)}
              onChange={(value: Date | null) => handleChange('reminderDate', value)}
              renderInput={(params: any) => <TextField sx={{ mt: 3 }} variant="outlined" {...params} />}
            />
          </LocalizationProvider>
          <Box sx={{ mt: 1 }}>
            <FormHelperText>{ t('Reminder will be sent to the phone number of this user') }</FormHelperText>
          </Box>
        </Box>
      }
      <Box sx={{ mt: 3 }}>
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button color="primary" variant="contained" sx={{ ml: 1 }} onClick={submit}>
              {t('Submit')}
            </Button>
        }
      </Box>
    </Box>
  );
}
