
import { useAppSelector } from '../hooks';
export default function useLocales() {
  const { translations, status } = useAppSelector(store => store.translation);

  function t(key: string | undefined, values?: string[]) {
    if (!key) {
      return '';
    }
    if (status === 'loading') {
      return key;
    }
    const found = translations[key];

    let translated = found ? `${translations[key]}` : `${key}`;
    if (values && values.length > 0) {
      // TODO, change to use reduce
      values.map( (value: string, idx: number) => {
        translated = translated.replace(`{${idx + 1}}`, value);
      });
    }
    return translated;
  }

  return {
    t
  };
}