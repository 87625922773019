import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { UserType } from '../../types/users';
import { getValueFromStateOrObject } from '../../helpers/helpers';

type AddEditUserPropsType = {
  cancel: () => void;
  submit: (data: Partial<UserType>) => void;
  user?: Partial<UserType>;
  saving?: boolean;
}

export default function AddEditAdminUser(props: AddEditUserPropsType) {
  const { submit, user, saving } = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<UserType>>({});

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  return (
    <Box sx={{ p: 2 }}>
      <TextField
        sx={{ mt: 1 }}
        label={t('Name')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, user, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />

      <TextField
        sx={{ mt: 1 }}
        label={t('Email')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, user, 'email') }
        onChange={(e) => handleChange('email', e.target.value)}
      />
       <TextField
        sx={{ mt: 1 }}
        label={t('Phone')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, user, 'phone') }
        onChange={(e) => handleChange('phone', e.target.value)}
      />


      <Box sx={{ mt: 1 }}>
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button color="primary" variant="contained" sx={{ ml: 1 }} onClick={() => submit({ ...state, id: user ? user.id : undefined })}>
              {t('Submit')}
            </Button>
        }
      </Box>
    </Box>
  );
}