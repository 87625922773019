import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { parseError } from '../../helpers/helpers';
import { FixedCost, FixedCostType } from '../../types/costs';
import { isIncluded, notIncluded } from '../reduxHelper';

export interface FixedCostState {
  loading: boolean,
  loaded: boolean,
  saving: boolean,
  saved: boolean,
  reload: boolean,
  costTypes: FixedCostType[],
  error: string | null,
  savingMany: boolean,
  savedMany: boolean,
  loadingWithCosts: number[],
  loadedWithCosts: number[],
  withCosts: FixedCostType[],
  errors: number[],
  deleting: boolean,
  deleted: boolean,
}

const initialState: FixedCostState = {
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  savingMany: false,
  savedMany: false,
  reload: false,
  costTypes: [],
  error: null,
  loadedWithCosts: [],
  loadingWithCosts: [],
  withCosts: [],
  deleting: false,
  deleted: false,
  errors: [],

};


export const getFixedCostTypesAction = createAsyncThunk('fixed-costs/for-servicebook', async (serviceBookId: number, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${serviceBookId}/fixed-cost-types`, thunkApi, method: 'GET' });
});

export const saveMultipleFixedCostTypesAction = createAsyncThunk('fixed-costs/save-multiple', async (params: { serviceBookId: number, names: string[] }, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/fixed-cost-types`, thunkApi, data: params,  method: 'POST' });
});

export const saveFixedCostTypeAction = createAsyncThunk('fixed-costs/save-one', async (params: { serviceBookId: number, name: string, id?: number }, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/fixed-cost-type`, thunkApi, data: params,  method: 'POST' });
});

export const loadCostsForServiceBookAction = createAsyncThunk('fixed-costs/for-servicebook-with-costs', async (params: { serviceBookId: number }, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/fixed-costs`, thunkApi, method: 'GET' });
});

export const saveFixedCostForServiceBookAction = createAsyncThunk('fixed-costs/save-for-service-book', async (params: { serviceBookId: number, fixedCost: FixedCost }, thunkApi: any) =>
  await apiRequest({ path: `/service-book/${params.serviceBookId}/fixed-costs`, data: params.fixedCost, thunkApi, method: 'POST' })
);
export const deleteFixedCostForServiceBookAction = createAsyncThunk('fixed-costs/delete-for-service-book', async (params: { serviceBookId: number, id: number }, thunkApi: any) =>
  await apiRequest({ path: `/service-book/${params.serviceBookId}/fixed-costs/${params.id}`, thunkApi, method: 'DELETE' })
);

export const deleteFixedCostTypeAction = createAsyncThunk('fixed-cost/delete', async (params: { serviceBookId: number, id: number }, thunkApi: any) =>
  await apiRequest({ path: `/service-book/${params.serviceBookId}/fixed-cost-type/${params.id}`, thunkApi, method: 'DELETE' })
);


export const fixedCostsSlice = createSlice({
  name: 'fixedCosts',
  initialState,
  reducers: {
    clear: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFixedCostTypesAction.pending, (state) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(getFixedCostTypesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.loaded = true;
      state.costTypes = action.payload;
    });
    builder.addCase(getFixedCostTypesAction.rejected, (state, action) => {
      state.loading = false;
      state.loaded = false;
      state.error = parseError(action.error);
    });

    builder.addCase(saveMultipleFixedCostTypesAction.pending, (state) => {
      state.savingMany = true;
      state.savedMany = false;
    });
    builder.addCase(saveMultipleFixedCostTypesAction.fulfilled, (state, action) => {
      state.savingMany = false;
      state.savedMany = true;
      state.loaded = false;
    });
    builder.addCase(saveMultipleFixedCostTypesAction.rejected, (state, action) => {
      state.savingMany = false;
      state.savedMany = false;
      state.error = parseError(action.error);
    });

    builder.addCase(saveFixedCostTypeAction.pending, (state) => {
      state.saving = true;
      state.saved = false;
    });
    builder.addCase(saveFixedCostTypeAction.fulfilled, (state, action) => {
      state.saving = false;
      state.saved = true;
      state.loaded = false;
      state.loadedWithCosts = [];
    });
    builder.addCase(saveFixedCostTypeAction.rejected, (state, action) => {
      state.saving = false;
      state.saved = false;
      state.error = parseError(action.error);
    });

    builder.addCase(loadCostsForServiceBookAction.pending, (state, action) => {
      const id = action.meta.arg.serviceBookId;
      state.loadingWithCosts = isIncluded(state.loadingWithCosts, id);
      state.loadedWithCosts = notIncluded(state.loadedWithCosts, id);
    });
    builder.addCase(loadCostsForServiceBookAction.fulfilled, (state, action) => {
      const id = action.meta.arg.serviceBookId;
      state.loadingWithCosts = notIncluded(state.loadingWithCosts, id);
      state.loadedWithCosts = isIncluded(state.loadedWithCosts, id);
      state.withCosts = [ ...state.withCosts.filter(row => row.serviceBookId !== id), ...action.payload ];
    });
    builder.addCase(loadCostsForServiceBookAction.rejected, (state, action) => {
      const id = action.meta.arg.serviceBookId;
      state.loadingWithCosts = notIncluded(state.loadingWithCosts, id);
      state.loadedWithCosts = notIncluded(state.loadedWithCosts, id);
      state.errors = isIncluded(state.errors, id);
    });

    builder.addCase(saveFixedCostForServiceBookAction.pending, (state) => {
      state.saving = true;
      state.saved = false;
    });
    builder.addCase(saveFixedCostForServiceBookAction.fulfilled, (state, action) => {
      state.saving = false;
      state.saved = true;
      state.loaded = false;
      state.reload = true;
      state.loadedWithCosts = state.loadedWithCosts.filter(row => row !== action.meta.arg.serviceBookId);
    });

    builder.addCase(deleteFixedCostForServiceBookAction.pending, (state) => {
      state.deleting = true;
      state.deleted = false;
    });
    builder.addCase(deleteFixedCostForServiceBookAction.fulfilled, (state, action) => {
      state.deleting = false;
      state.deleted = true;
      state.loaded = false;
      state.reload = true;
      state.loadedWithCosts = state.loadedWithCosts.filter(row => row !== action.meta.arg.serviceBookId);
    });
    builder.addCase(deleteFixedCostTypeAction.pending, (state) => {
      state.deleting = true;
      state.deleted = false;
    });
    builder.addCase(deleteFixedCostTypeAction.fulfilled, (state, action) => {
      state.deleting = false;
      state.deleted = true;
      state.loaded = false;
      state.reload = true;
      state.loadedWithCosts = [];
    });
  }
});

export const { clear: clearFixedCostsState } = fixedCostsSlice.actions;


export default fixedCostsSlice.reducer;
