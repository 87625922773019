import React, { useEffect, useState } from 'react';
import { ArrowBackRounded, HelpOutlineRounded, HelpRounded, QuestionMarkOutlined, QuestionMarkRounded } from '@mui/icons-material';
import { Button, Container, IconButton, Link, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { backButtonStyle, colors, containerPaperStyle, headerStyles, tabsStyle, tabStyle } from '../../styles/themeStylesSx';
import { useParams, Outlet, Link as RouterLink, useNavigate, useLocation, Location, useOutletContext } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getServiceBookForId } from '../redux/serviceBooks/serviceBookSlice';

import useLocales from '../hooks/useLocales';
import SkeletonRows from '../components/layout/SkeletonRows';
import { ServiceBookType } from '../types/serviceBook';
import SidePanel from '../components/sidepanel/SidePanel';
import ContextualHelp from '../components/help/ContextualHelp';

export type ServiceBookOutletContextType = {
  serviceBook: ServiceBookType
};

type StateType = {
  showHelp: boolean;
};

export default function ServiceBookContainer() {
  const { t } = useLocales();
  const location: Location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [value, setValue] = React.useState('status');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/service-books/${serviceBookId}/${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const parts = location.pathname.split('/');
    const last = parts[parts.length - 1];
    if (last !== value) {
      setValue(last);
    }
  }, [location, value]);

  const windowWidth = window ? window.innerWidth : 1000;

  const { loading, loaded, error, list, deleted } = useAppSelector(store => store.serviceBook);
  const { details: customer } = useAppSelector(store => store.customer);
  const [state, setState] = useState<StateType>({ showHelp: false });

  const serviceBookId = params.serviceBookId ? parseInt(params.serviceBookId, 10) : -1;
  const isLoaded = loaded.includes(serviceBookId);
  const [width, setWidth] = useState(windowWidth);
  const dispatch = useAppDispatch();
  const serviceBook = list.find(row => row.id === serviceBookId);

  useEffect(() => {
    if ((!serviceBook || !isLoaded) && !deleted) {
      dispatch(getServiceBookForId(serviceBookId));
    }
  }, [dispatch, serviceBookId, isLoaded, serviceBook]);

  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);

  if (!serviceBookId) {
    return (
      <Container>
        <Box>{t('Invalid path')}</Box>
      </Container>
    );
  }

  if (loading.includes(serviceBookId)) {
    return <Container><SkeletonRows rows={5} /></Container>;
  }

  if (error) {
    return (<Box sx={{ mb: 2, mt: 2 }}>{t('Error')}: {error}</Box>);
  }

  if (!serviceBook || !customer) {
    return (
      <Container>
        <Box>{t('Service book not found')}</Box>
      </Container>
    );
  }

  if (serviceBook.customerId !== customer.id) {
    return (<Box sx={{ ml: 2 }}>
      <Typography variant="h6">{t('Invalid servicebook')}</Typography>
      <Link href='/'><Button color="secondary" sx={{ mt: 2 }} variant="outlined">{t('Back')}</Button></Link>
    </Box>);
  }

  const getSelectedTab = (value: string) => {
    switch(value) {
      case 'service-plan':
      case 'cost-types':
        return 'details';
      case 'fall':
      case 'spring':
      case 'winter':
      case 'summer':
        return 'service-calendar';
      default:
        return value;
    }
  };

  return (
    <Container>

      <Link sx={ backButtonStyle } component={RouterLink} to='/service-books'>
        <Button sx={{ color: 'secondary.main', fontWeight: 600 }} startIcon={<ArrowBackRounded />}>{t('Back')}</Button>
      </Link>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={ headerStyles }>{serviceBook.name}</Typography>
        <ContextualHelp styles={{ marginLeft: 'auto' }} variant='serviceBook' />
      </Box>
      <Tabs
        orientation={ width < 1000 ? 'vertical' : 'horizontal' }
        value={getSelectedTab(value)}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        sx={tabsStyle}
        aria-label="servicebook tabs"
      >
        <Tab
          disableRipple
          disableFocusRipple
          sx={ tabStyle }
          value="status"
          label={t('Status')}
        />
        <Tab
          value="details"
          label={t('Details')}
          sx={ tabStyle }
        />
        <Tab
          value="service-cases"
          label={t('Service history')}
          sx={ tabStyle }
        />
        <Tab
          value="service-calendar"
          label={t('Service calendar')}
          sx={ tabStyle }
        />
        <Tab
          value="documents"
          label={t('Service book documents')}
          sx={ tabStyle }
        />
      </Tabs>
      <Paper sx={containerPaperStyle}>
        <Outlet context={{ serviceBook }} />
      </Paper>

    </Container>
  );
}

export function useServiceBookOutletContext() {
  return useOutletContext<ServiceBookOutletContextType>();
}