import { Table, TableBody, TableCell, Box, TableHead, TableRow, CircularProgress } from '@mui/material';

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getServiceCasesForId } from '../../redux/report/reportSlice';
import ReportServiceCaseGraph from './ReportServiceCaseGraph';

export default function ReportServiceCasesComponent() {
  const { t } = useLocales();
  const dispatch = useAppDispatch();

  const { reloadServiceCases, loadedServiceCase,  lastReport, loading, reload, loaded, details: detailsReport } = useAppSelector(store => store.reports);
  const { details} = useAppSelector(store => store.customer);

  useEffect(() => {
    if (reloadServiceCases) {
      dispatch(getServiceCasesForId());
    }
  }, [reloadServiceCases, reload, dispatch, loaded, lastReport]);

  const mapped = detailsReport?.months.reduce((results: any, data: any) => {
    const result = results;
    const exist: number = result.findIndex((row: any )=> row.month === data.month && row.year === data.year );
    if (exist !== -1) {
      result[exist] = { ...result[exist], [data.name]: data.total, month: data.month, year: data.year, yearMonth : data.year + '.' + data.month, total: data.total + result[exist].total  };
    }
    else {
      result.push({ year: data.year, month: data.month, [data.name]: data.total, yearMonth : data.year + '.' + data.month, total: data.total });
    }
    return result;
  }, []);



  const totalArray = [{
      year: '',
      month: '',
      total: '',
    },
    {
      year: 'Total',
      month: '',
      total: detailsReport?.total
    }];

  return (
    <Box sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
    {loadedServiceCase && lastReport === 'serviceCase' ?
      <Table>

        <TableHead>
          <TableRow>
            <TableCell align="right" size="small">{t('Year/Month')}</TableCell>
            <TableCell align="right" size="small">{t('Total')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {

          mapped.map( (row: any, ind: number) => {
          return (
            <TableRow key = { ind }>
              <TableCell align="right" size="small">{ row.year + '/' + row.month }</TableCell>
              <TableCell align="right" size="small">{ row.total }</TableCell>
            </TableRow>
          );
          })

        }
        </TableBody>


        <TableBody>
          {totalArray.map((row, ind) => {
            return (
              <TableRow key = { ind }>
                <TableCell align="right" size="small">{ row.year }</TableCell>
                <TableCell align="right" size="small">{ row.total }</TableCell>
              </TableRow>
              );
            })
          }
        </TableBody>

     </Table>
      :
     <CircularProgress/>
    }
    {
      loadedServiceCase && lastReport === 'serviceCase' ?
      <Box sx={{ height: '500px', width: '100%' }}><ReportServiceCaseGraph /></Box>
       :
      <CircularProgress />
    }
  </Box>
  );
}