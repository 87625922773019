import { Table, TableBody, TableCell, Box, TableHead, TableRow, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getReceiptsForId } from '../../redux/report/reportSlice';
import ReportReceiptsGraph from './ReportReceiptsGraph';


export default function ReportReceiptComponent() {

  const { t } = useLocales();
  const dispatch = useAppDispatch();

  const { reload, lastReport, loaded, details: detailsReport } = useAppSelector(store => store.reports);

  useEffect(() => {
    if (reload) {
      dispatch(getReceiptsForId());
    }
  }, [reload, dispatch, loaded, lastReport]);

  const totalArray = [{
      year: '',
      month: '',
      total: '',
    },
    {
      year: 'Total',
      month: '',
      total: detailsReport?.total
    }];

  return (
    <Box sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right" size="small">{t('Year/Month')}</TableCell>
            <TableCell align="right" size="small">{t('Total')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            detailsReport?.months?.map( (row: any, ind: number) => {
              return (
                <TableRow key = { ind }>
                  <TableCell align="right" size="small">{ row.year + '/' + row.month }</TableCell>
                  <TableCell align="right" size="small">{ row.total }</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
        <TableBody>
        {totalArray.map((row, ind) => {
          return (
            <TableRow key = { ind }>
              <TableCell align="right" size="small">{ row.year }</TableCell>
              <TableCell align="right" size="small">{ row.total }</TableCell>
            </TableRow>
            );
          })
        }
        </TableBody>
    </Table>
    {
      detailsReport ?
       <Box sx={{ height: '500px', width: '100%' }}><ReportReceiptsGraph /></Box>
       :
       <CircularProgress />
    }
  </Box>
  );
}