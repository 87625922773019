import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { ServiceBookProperty } from '../../types/serviceBookProperty';
import { getToken } from '../profile/profileSlice';

export const getAdminServiceBookPropertiesAction = createAsyncThunk<any>('admin/servicebookproperties/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: `/admin/service-book-property-answer`, method: 'GET', token: getToken(thunkApi.getState()) });
});

export const saveAdminServiceBookPropertyAction = createAsyncThunk('admin/servicebookproperties/update', async (data: Partial<ServiceBookProperty>, thunkApi: any) => {
  return await apiRequest({ path: `/admin/service-book-property-answer`, data, token: getToken(thunkApi.getState()), method: 'POST' });
});
export const saveAdminServiceBookPropertyMappingAction = createAsyncThunk('admin/servicebookproperties/mapping', async (data: Partial<ServiceBookProperty>, thunkApi: any) => {
  return await apiRequest({ path: `/admin/service-book-property-answer/mapping`, data, token: getToken(thunkApi.getState()), method: 'POST' });
});

type AdminServiceBookPropertyState = {
  loading: boolean,
  loaded: boolean,
  list: ServiceBookProperty[] | null,
  error: string | null,
  saving: boolean,
  saved: boolean,
  reload: boolean,
  savingMapping: boolean,
  savedMapping: boolean,
}

const initialState: AdminServiceBookPropertyState = {
  list: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  reload: false,
  error: null,
  savingMapping: false,
  savedMapping: false,
};


export const adminServiceBookPropertySlice = createSlice({
  name: 'serviceBookPropertyAnswer',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getAdminServiceBookPropertiesAction.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.saved = false;
      state.loaded = false;
    });
    builder.addCase(getAdminServiceBookPropertiesAction.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.loaded = true;
      state.list = action.payload;
    });
    builder.addCase(getAdminServiceBookPropertiesAction.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.loaded = false;
    });

    builder.addCase(saveAdminServiceBookPropertyAction.pending, (state, action: PayloadAction<any>) => {
      state.saving = true;
      state.error = null;
      state.saved = false;
    });
    builder.addCase(saveAdminServiceBookPropertyAction.fulfilled, (state, action: PayloadAction<any>) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
    });
    builder.addCase(saveAdminServiceBookPropertyAction.rejected, (state, action: PayloadAction<any>) => {
      state.saving = false;
      state.error = action.payload;
      state.saved = false;
    });


    builder.addCase(saveAdminServiceBookPropertyMappingAction.pending, (state, action: PayloadAction<any>) => {
      state.savingMapping = true;
      state.error = null;
      state.savedMapping = false;
    });
    builder.addCase(saveAdminServiceBookPropertyMappingAction.fulfilled, (state, action: PayloadAction<any>) => {
      state.savingMapping = false;
      state.savedMapping = true;
      state.reload = true;
    });
    builder.addCase(saveAdminServiceBookPropertyMappingAction.rejected, (state, action: PayloadAction<any>) => {
      state.savingMapping = false;
      state.error = action.payload;
      state.savedMapping = false;
    });
  }
});

export default adminServiceBookPropertySlice.reducer;
