/* eslint-disable @typescript-eslint/no-explicit-any */
import {Autocomplete, Box, Button, CircularProgress, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import useLocales from '../../hooks/useLocales';
import { CustomerType } from '../../types/customer';
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { addCustomer, updateCustomer, getSubscriptions} from '../../redux/admin/adminCustomerSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

type AddEditCustomerPropsType = {
  cancel: () => void;
  customer?: CustomerType;
  saving?: boolean;
}
type StateType = {
  edit: boolean;
  customer?: CustomerType;
}

export default function AddEditAdminCustomer(props: AddEditCustomerPropsType) {
  const { cancel, customer, saving } = props;
  const { t } = useLocales();
  const [state, setState] = useState<StateType>({edit: false });
  const dispatch = useAppDispatch();
  const { subscriptions, saved, error } = useAppSelector(store => store.adminCustomer);
  const [selectedSubscription, setSelectedSubscription] = useState<number | undefined>();

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);

  useEffect(() => {
    if (saved && !error) {
      cancel();
    }
  }, [saved]);

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

 let searchSubscriptionId = customer?.subscriptionId;
 if( !searchSubscriptionId)
   searchSubscriptionId = 0;

  const submit = () => {
    const data: any = { ...state , subscriptionId: selectedSubscription};

    if (customer && customer.id !== undefined) {
      dispatch(updateCustomer({ id: customer.id, data }));
    }
    else {
      dispatch(addCustomer({ ...data }));
    }
  };

  let defaultaValueF = ({label:'', id:0});
  if (subscriptions) {
    const defaultValue =  subscriptions.find((subs: any) =>  subs?.id === searchSubscriptionId);

    if (defaultValue )
      defaultaValueF = ({ label: `${defaultValue.id}. ${defaultValue.name}`, id: defaultValue.id });
  }
  return (
    <Box sx={{ p: 2 }}>
      <TextField
        sx={{ mt: 1 }}
        label={t('Name')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Phone')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'phone') }
        onChange={(e) => handleChange('phone', e.target.value)}
      />

       <TextField
        sx={{ mt: 1 }}
        label={t('Invoicing email')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'email') }
        onChange={(e) => handleChange('email', e.target.value)}
      />
       <TextField
        sx={{ mt: 1 }}
        label={t('Address')}
        multiline
        rows={2}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'address') }
        onChange={(e) => handleChange('address', e.target.value)}
      />
       <TextField
        sx={{ mt: 1 }}
        label={t('Postal code')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'postalCode') }
        onChange={(e) => handleChange('postalCode', e.target.value)}
      />
       <TextField
        sx={{ mt: 1 }}
        label={t('City')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'city') }
        onChange={(e) => handleChange('city', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Country')}
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'country') }
        onChange={(e) => handleChange('country', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Description')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'description') }
        onChange={(e) => handleChange('description', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('currency')}
        // type="number"
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'currency') }
        onChange={(e) => handleChange('currency', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Timezone')}
        type="string"
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'timezone') }
        onChange={(e) => handleChange('timezone', e.target.value)}
      />
      {customer?.subscriptionId && customer.subscription?
      <Autocomplete
        disablePortal
        sx={{ mt: 4 }}
        defaultValue ={ defaultaValueF}
        id="add--subscription"
        isOptionEqualToValue={(option, value) => option.id === value.id}

        options={(subscriptions || []).filter((row: any)=> row.active === true ).map((row: any) => ({ label: `${row.id}. ${row.name}`, id: row.id }))}
        renderInput={(params) => <TextField {...params} fullWidth label={t('Modify subscription to customer')} />}
        onChange={(event: any, subscription: { label: string, id: number } | null) => setSelectedSubscription(subscription?.id) }
      />
      :
      <Autocomplete
        disablePortal
        sx={{ mt: 4 }}
        id="add--subscription"
        isOptionEqualToValue={(option, value) => option.id === value.id}

        options={(subscriptions || []).filter((row: any)=> row.active === true ).map((row: any) => ({ label: `${row.id}. ${row.name}`, id: row.id }))}
        renderInput={(params) => <TextField {...params} fullWidth label={t('Add subscription to customer')} />}
        onChange={(event: any, subscription: { label: string, id: number } | null) => setSelectedSubscription(subscription?.id) }
      />
      }

      <Box sx={{ mt: 1 }}>
        { customer &&
        <Button variant="outlined" onClick={cancel}>
          {t('Cancel')}
        </Button>
        }
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick={submit}
            >
              {t('Submit')}
            </Button>
        }
      </Box>

    </Box>
  );
}