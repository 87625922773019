import { AddRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, Container, IconButton, List, ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { addButtonStyle, colors } from '../../../styles/themeStylesSx';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import { valueAsDate } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { serviceBookApi, useGetServiceBookDocumentsQuery } from '../../redux/services/serviceBookService';
import { DocumentType } from '../../types/documents';
import AttachmentsIcon from '../attachments/AttachmentsIcon';
import AddEditDocument from '../documents/AddEditDocument';
import DocumentComponent from '../documents/DocumentComponent';
import SkeletonRows from '../layout/SkeletonRows';
import SidePanel from '../sidepanel/SidePanel';

export default function ServiceBookDocuments() {
  const { t } = useLocales();
  const { serviceBook } = useServiceBookOutletContext();
  const [state, setState] = useState<{add?: boolean, open?: boolean, documentId?: number | undefined}>({ add: false, open: false, documentId: undefined });
  const { data: documents, error, isLoading: loading } = useGetServiceBookDocumentsQuery(serviceBook.id);
  const { saved } = useAppSelector(store => store.documents);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (saved) {
      dispatch(serviceBookApi.util.invalidateTags(['Documents']));
      setState({ add: false });
    }
  }, [saved]);

  return (
    <Container>

      <SidePanel
        open={ state.add === true }
        close={ () => setState({ add: false })}
      >
        <Box sx={{ ml: 2 }}>
          <AddEditDocument serviceBookId={serviceBook.id} cancel={ () => setState({ ...state, add: false }) } />
        </Box>
      </SidePanel>

      <SidePanel
        open={ state.open === true }
        close={ () => setState({ open: false })}
      >
        <Box sx={{ ml: 2 }}>
          <DocumentComponent document={ (documents || []).find(row => row.id === state.documentId )} close={ () => setState({ ...state, open: false })} />
        </Box>
      </SidePanel>


      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ borderBottom: 'thin solid black', mb: 2,  }}>{t('Service book documents')}</Typography>
      </Box>
      { error && <Box>{t('Error loading the documents')}</Box> }
      { loading && <SkeletonRows rows={5} /> }
      {
        documents && documents.length === 0 && <Box>{t('No documents')}</Box>
      }
      <Box sx={{ display: { xs: 'block', md: 'none' }}}>
        {
          (documents || []).map((row: DocumentType, idx: number) => {
            const dateValue = valueAsDate(row.date);
            const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';

            return (<Paper key={`document_${idx}`} sx={{ p: 1, mt: 1, mb: 1, lineHeight: 1.5, background: backgroundColor }}>
              <Box sx={{ display: 'flex' }}>
                <Box title={`${row.id}`}>{idx + 1}: { dateValue ? format(dateValue, 'dd.MM.yyyy') : '' } { row.description }</Box>
                <Box sx={{ ml: 'auto'}}>
                  <IconButton onClick={() => setState({ open: true, documentId: row.id })}><VisibilityRounded /></IconButton>
                </Box>
              </Box>
            </Paper>);
          })
        }
      </Box>

      {
        documents && documents.length > 0 &&

          <Table sx={{ display: { xs: 'none', md: 'inline-table' }}}>
            <TableHead>
              <TableRow>
                <TableCell>{t('Date')}</TableCell>
                <TableCell>{t('Description')}</TableCell>
                <TableCell>{t('Attachment')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (documents || []).map( (row, idx) => {
                  const dateValue = valueAsDate(row.date);
                  const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
                  return (
                    <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${row.id}_row`}>
                      <TableCell sx={{backgroundColor: backgroundColor }}>{ dateValue ? format(dateValue, 'dd.MM.yyyy') : '-' }</TableCell>
                      <TableCell sx={{backgroundColor: backgroundColor }}>{ row.description }</TableCell>
                      <TableCell sx={{backgroundColor: backgroundColor }}>
                        <AttachmentsIcon attachments={ row.attachments } />
                      </TableCell>
                      <TableCell sx={{
                        backgroundColor: backgroundColor,
                        borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                        <IconButton sx={{p: 0}} onClick={() => setState({ open: true, documentId: row.id })}><VisibilityRounded /></IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
      }
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          sx={ addButtonStyle } variant="outlined" onClick={() => setState({ add: true })} endIcon={<AddRounded />}
        >
          {t('Add new document')}
        </Button>
      </Box>
    </Container>
  );
}
