import { EditRounded } from '@mui/icons-material';
import { Button, IconButton, List, ListItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { saveAdminServiceBookPropertyAction, getAdminServiceBookPropertiesAction } from '../../redux/admin/adminServiceBookPropertySlice';
import { ServiceBookProperty } from '../../types/serviceBookProperty';

type Props = {
  close?: () => void
};

const AdminManageServiceBookProperties = (props: Props) => {
  const { t } = useLocales();
  const { list, loading, loaded, saving, saved } = useAppSelector(store => store.adminServiceBookProperties);
  const [state, setState] = useState<any>({});
  const dispatch = useAppDispatch();

  const submit = () => {
    const data = { id: state.edit, property: state.property };
    dispatch(saveAdminServiceBookPropertyAction(data));
  };

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(getAdminServiceBookPropertiesAction());
    }
  }, [loading, loaded]);

  useEffect(() => {
    if (saved) {
      dispatch(getAdminServiceBookPropertiesAction());
      setState({ });
    }
  }, [saved]);

  useEffect(() => {
    if (state.addProperty) {
      setState({ ...state, property: '' });
    }
    else if (state.edit && +state.edit > 0 && list) {
      const item = list.find(row => row.id === +state.edit);
      if (item) {
        setState({ ...state, property: item.property, id: +state.edit });
      }
    }
  }, [state.addProperty, state.edit]);

  if (state.addProperty || (state.edit && list)) {
    return (
      <Box>
        <TextField
          size="medium"
          label={t('Property value')}
          value={state.property}
          name='property'
          onChange={ e => setState({ ...state, [e.target.name]: e.target.value })}
        />
        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={submit}>{ state.addProperty ? t('Add') : t('Save') }</Button>
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <List>
        <Typography variant="h6">{t('Create service book properties')}</Typography>
        <p>{t(`Technical details, if you are not aware what are these for, don't touch`)}</p>
        {
          list && list.length > 0 ?
            list.map((property: ServiceBookProperty, idx: number) => {
              return (
                <ListItem key={idx}>
                  {property.property} <IconButton onClick={() => setState({ edit: property.id })}><EditRounded /></IconButton>
                </ListItem>);
            }) :
          <ListItem>{t('No results found')}</ListItem>
        }
      </List>
      <Button onClick={() => setState({ ...state, addProperty: true })} variant="outlined">{t('Add new property')}</Button>

    </Box>
  );
};

export default AdminManageServiceBookProperties;