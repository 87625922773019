import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideNotification } from '../../redux/notificationSlice';
export default function NotificationComponent() {
  const dispatch = useAppDispatch();
  const { visible, message, severity } = useAppSelector(store => store.notification);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideNotification());
  };
  const getSeverity = (severity: string) => {
    switch (severity) {
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'success':
      return 'success';
    }
  };
  
  return (
    <Snackbar 
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
      open={visible}
      onClose={ handleClose }
      autoHideDuration={6000}
      message={ message }
    >
      <Alert onClose={handleClose} severity={ getSeverity(severity) } sx={{ width: '100%' }}>
        { message }
      </Alert>
    </Snackbar>
  );
}