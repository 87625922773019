import { isRejectedWithValue } from '@reduxjs/toolkit';
import { showNotification } from '../notificationSlice';


export const getMessageFromRTKAction = (action: any) => {
  if (action?.payload?.data) {
    return `${action.payload.data.statusCode} - ${action.payload.data.message}`;
  }
  if (action.error?.message) {
    return action.error.message;
  }
  return 'Something went wrong';
};


export const rtkErrorLoggerMiddleware = (api: any) => (next: any) => (action: any) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const message = getMessageFromRTKAction(action);
    const method = action.method;
    let path;
    path = action.path;
    if (!path) {
      const req = action.meta?.baseQueryMeta?.request || {};
      path = req.url;
    }

    api.dispatch(showNotification({
      severity: 'error',
      message: `${method || 'GET'} ${path}: action failed: ${message}`,
    }));


  }

  return next(action);
};
