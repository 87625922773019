import { AddRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getAgreements } from '../../redux/agreements/agreementsSlice';
import SidePanel from '../sidepanel/SidePanel';
import { parseAndFormatDate, valueAsDate } from '../../helpers/helpers';
import AddEditAgreement from './AddEditAgreement';
import SkeletonRows from '../layout/SkeletonRows';
import AgreementComponent from './AgreementComponent';
import AttachmentsIcon from '../attachments/AttachmentsIcon';
import { differenceInBusinessDays } from 'date-fns';
import { addButtonStyle, colors } from '../../../styles/themeStylesSx';

type AgreementStateType = {
  open?: boolean,
  agreementId?: number,
  add?: boolean,
};

export default function ListAgreements() {
  const { t } = useLocales();
  const [state, setState] = useState<AgreementStateType>({ open: false, add: false });
  const { list, loading, loaded, reload } = useAppSelector(store => store.agreements);
  const { details: customer } = useAppSelector(store => store.customer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAgreements());
  }, []);

  useEffect(() => {
    if (reload && !loaded && !loading) {
      dispatch(getAgreements());
    }
  }, [reload, loading, loaded]);

  return (
    <div>

      <SidePanel
        open={ state.add === true }
        close={ () => setState({ add: false })}
      >
        <Box sx={{ m: 2 }}>
          <AddEditAgreement cancel={ () => setState({ add: false }) } />
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.open === true }
        close={ () => setState({ open: false })}
      >
        <Box sx={{  }}>
          <AgreementComponent agreement={ list.find(row => row.id === state.agreementId) } close={ () => setState({ open: false })} />
        </Box>
      </SidePanel>

      { loading && <SkeletonRows rows={5} /> }

      <Box sx={{ display: { xs: 'block', md: 'none' }}}>
        {
          list.map((row, idx) => {
            const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
            return (<Paper sx={{ p: 1, mt: 1, mb: 1, lineHeight: 1.5, background: backgroundColor }} key={`idx_${idx}`} >
              <Box sx={{ display: 'flex' }}>
                <Box title={`${row.id}`}>{idx + 1}: { row.description }</Box>
                <Box sx={{ ml: 'auto'}}>
                  <IconButton onClick={() => setState({ open: true, agreementId: row.id })}><VisibilityRounded /></IconButton>
                </Box>
              </Box>
              <Box>{row.value } { row.unit }</Box>
            </Paper>);
          })
        }
      </Box>
      <Table sx={{ display: { xs: 'none', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('Agreement expiring')}</TableCell>
            <TableCell>{t('Description')}</TableCell>
            <TableCell>{t('Total')}</TableCell>
            <TableCell>{t('Attachment')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.map((row, idx) => {
              const expiryDate = row.expiryDate ? parseAndFormatDate(row.expiryDate) : null;
              const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
              const inDays = expiryDate ? differenceInBusinessDays(valueAsDate(row.expiryDate), new Date()) : null;
              return (
                <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${row.id}_row`}>
                  <TableCell sx={{backgroundColor: idx % 2 ? colors.backgroundGreen : 'transparent'  }}>
                    {
                      expiryDate ? <>{expiryDate}{inDays && inDays > 0 ? ` (${inDays} ${t('days')})` : ''}</> : ''
                    }
                  </TableCell>
                  <TableCell sx={{backgroundColor: backgroundColor }}>{ row.description }</TableCell>
                  <TableCell sx={{backgroundColor: backgroundColor }}>{ row.value } { row.unit }</TableCell>
                  <TableCell sx={{backgroundColor: backgroundColor }}>
                    <AttachmentsIcon attachments={row.attachments} />
                  </TableCell>
                  <TableCell sx={{
                    backgroundColor: backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                    <IconButton sx={{p: 0}} onClick={() => setState({ open: true, agreementId: row.id })}><VisibilityRounded /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      <Grid container>
        <Grid item sm={12}>
          <Box sx={{ mt: 2 }} display="flex" justifyContent="flex-end">
          <Button
            disabled={!customer?.isActive}
            sx={ addButtonStyle }
            variant="outlined" onClick={() => setState({ add: true })} endIcon={<AddRounded />}
          >
            {t('Add new agreement')}
          </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}