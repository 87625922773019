import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { RootState } from '../../store';
import { ServicePlanItemType } from '../../types/servicePlanItem';
import { getToken } from '../profile/profileSlice';

export interface AdminServicePlanState {
  list: Array<Partial<ServicePlanItemType>>;
  loading: boolean;
  loaded: boolean;
  error: string | null;
  saving: boolean;
  saved: boolean;
  reload: boolean;
  deleting: boolean;
  deleted: boolean;
}
const initialState: AdminServicePlanState = {
  list: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
  reload: true,
  deleting: false,
  deleted: false,
};

export const getAdminServicePlans = createAsyncThunk<ServicePlanItemType[]>('admin/service-plan-item/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/admin/service-plan-item', token: getToken(thunkApi.getState()), method: 'GET' });
});

export const addAdminServicePlan = createAsyncThunk<void, Partial<ServicePlanItemType>>('admin/service-plan-item/add', async (data: Partial<ServicePlanItemType>, thunkApi: any) => {
  return await apiRequest({ path: '/admin/service-plan-item', data, token: getToken(thunkApi.getState()), method: 'POST' });
});
export const updateAdminServicePlan = createAsyncThunk('admin/service-plan-item/update', async (params: { id: number, data: Partial<ServicePlanItemType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/service-plan-item/${params.id}`, data: params.data, token: getToken(thunkApi.getState()), method: 'PATCH' });
});

export const deleteAdminServicePlanAction = createAsyncThunk('admin/service-plan', async (params: { id: number }, thunkApi) => {
  return await apiRequest({ path: `/admin/service-plan-item/${params.id}`, method: 'DELETE', thunkApi });
});

export const adminServicePlanSlice = createSlice({
  name: 'adminServicePlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminServicePlans.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.saved = false;
      state.loaded = false;
    });

    builder.addCase(getAdminServicePlans.fulfilled, (state, action: PayloadAction<ServicePlanItemType[]>) => {
      state.loading = false;
      state.loaded = true;
      state.list = action.payload;
    });

    builder.addCase(getAdminServicePlans.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error getting admin service plan items', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    });

    builder.addCase(addAdminServicePlan.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(addAdminServicePlan.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(addAdminServicePlan.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error adding admin service plan item', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(updateAdminServicePlan.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(updateAdminServicePlan.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(updateAdminServicePlan.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating admin service plan item', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(deleteAdminServicePlanAction.pending, (state) => {
      state.deleted = false;
      state.deleting = true;
    });
    builder.addCase(deleteAdminServicePlanAction.fulfilled, (state) => {
      state.deleted = true;
      state.deleting = false;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(deleteAdminServicePlanAction.rejected, (state) => {
      state.deleted = false;
      state.deleting = false;
    });
  }
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAgreemnts = (state: RootState) => state.adminServicePlan.list;

export default adminServicePlanSlice.reducer;
