import { useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';

export default function ValidateAccessToFeature(props: {feature: string, children: any}) {
  const { t } = useLocales();
  const { details: customer } = useAppSelector(store => store.customer);
  const enabledFeatures = customer?.subscription?.features?.map(row => row.name) || [];
  const isTesting = customer?.isTesting;
  if (!isTesting && props.feature !== 'any' && !enabledFeatures.includes(props.feature)) {
    return <>{t('Unfortunately you have no access to this feature')}</>;
  }

  return props.children;
}