import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { UserType } from '../../types/users';
import { getToken } from '../profile/profileSlice';

export type UsersState = {
  list: UserType[] | null;
  loading: boolean;
  loaded: boolean;
  error: string | null;
  saving: boolean;
  saved: boolean;
  reload: boolean;

}

const initialState: UsersState = {
  list: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
  reload: true,
};


export const getUsers = createAsyncThunk<UserType[]>('admin-users/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/admin/user/list', token: getToken(thunkApi.getState()), method: 'GET' });
});

export const addUser = createAsyncThunk<void, Partial<UserType>>('admin-users/add', async (data: Partial<UserType>, thunkApi: any) => {
  return await apiRequest({ path: '/admin/user', data, token: getToken(thunkApi.getState()), method: 'POST' });
});
export const updateUser = createAsyncThunk('admin-users/update', async (params: { id: number, data: Partial<UserType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/user/${params.id}`, data: params.data, token: getToken(thunkApi.getState()), method: 'PATCH' });
});
export const deleteAdminUser = createAsyncThunk('admin-user/delete', async (params: { id: number }, thunkApi: any) => {
  return await apiRequest({ path: `/admin/user/${params.id}`,  token: getToken(thunkApi.getState()), method: 'DELETE' });
});

export const usersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    });

    builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<UserType> | PayloadAction<UserType[]>) => {
      state.loading = false;
      state.loaded = true;

      if (action.payload && (action.payload instanceof Array)) {
        state.list = action.payload;
      }
    });

    builder.addCase(getUsers.rejected, (state, action) => {
      console.error('Error getting users', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.error ? action.error.message || 'Something went wrong' : 'Something went wrong';
    });

    builder.addCase(addUser.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(addUser.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(addUser.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error adding user', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(updateUser.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(updateUser.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating user', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });
    builder.addCase(deleteAdminUser.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(deleteAdminUser.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(deleteAdminUser.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating user', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });
  }
});

export default usersSlice.reducer;
