// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import { EditRounded } from '@mui/icons-material';
import {  Button, Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { CustomerType } from '../../types/customer';
import AddEditAdminCustomer from './AddEditAdminCustomer';
import { getSubscriptions} from '../../redux/admin/adminCustomerSlice';
import SidePanel from '../sidepanel/SidePanel';

type CustomerComponentType = {
  customer?: CustomerType,
  close: () => void,
};
type StateType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscription: any;
  editCustomerDetails: boolean;
  customer?: CustomerType;
}

export default function CustomerAdminComponent(props: CustomerComponentType) {
  const { customer, close } = props;
  const { t } = useLocales();
  const dispatch = useDispatch();

  const [state, setState] = useState<StateType>({  subscription: ' ', customer : customer, editCustomerDetails: false });
  const { loading,  loaded, saved, saving} = useAppSelector(store => store.adminCustomer);

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);

  if (loading) {
    return <CircularProgress/>;
  }

  useEffect(() => {
    if (saved && loaded) {
      setState({ subscription: ' ', customer: state.customer, editCustomerDetails: false });
      close();
    }
  }, [saved, loaded]);

  if (!customer) {
    return (
      <Box>
        <Typography sx={{ p: 2 }} variant="h6">{t('Add customer')}</Typography>
        <AddEditAdminCustomer customer={customer} cancel={ () => setState({ subscription : state.subscription, customer: state.customer, editCustomerDetails: false }) } />
      </Box>
    );
  }

  const editDetails = () => {
    setState({ ...state, editCustomerDetails: true});
  };

  return (
    <div>
      { state.editCustomerDetails &&
        <SidePanel
          open={ state.editCustomerDetails }
          close={ () => setState({  editCustomerDetails: false, subscription: ' ', customer : customer, })}
        >
        <AddEditAdminCustomer saving={ saving } customer = {customer } cancel={ () => setState({ editCustomerDetails: false, subscription: ' ', customer : customer  }) } />
        </SidePanel>
      }

      <Box>
        <Box sx={{ p: 1 }}>
          {t('Name')}: { customer.name && customer.name }
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Phone')}: {customer.phone && customer.phone}
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Invoicing email')}: {customer.email && customer.email}
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Address')}: {customer.address && customer.address}
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Postal code')}: {customer.postalCode && customer.postalCode}
        </Box>
        <Box sx={{ p: 1 }}>
          {t('City')}: {customer.city && customer.city}
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Country')}: {customer.country && customer.country}
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Description')}: { customer.description && customer.description }
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Currency')}: { customer.currency }
        </Box>
        <Box sx={{ p: 1 }}>
          {t('Timezone')}: { customer.timezone }
        </Box>
          { customer.subscriptionId ?
          <Box sx={{ p: 1 }}>
            {t('Subscription')}:  {customer.subscription?.name}
          </Box>
          :

          <Box sx={{ p: 1 }}>
            {t('No subscription')}
          </Box>
        }
          <Box sx={{ p: 1 }}>
          <Button onClick={editDetails} startIcon={<EditRounded />}>{t('Edit')}</Button>
        </Box>
      </Box>
    </div>

  );
}