import React from 'react';
import './App.css';
import AppMenuBar from './app/components/layout/AppMenuBar';
import SideNavigation from './app/components/layout/SideNavigation';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Toolbar } from '@mui/material';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AgreementsContainer from './app/containers/AgreementsContainer';
import ServiceBooksContainer from './app/containers/ServiceBooksContainer';
import ReportsContainer from './app/containers/ReportsContainer';
import ReceiptsContainer from './app/containers/ReceiptsContainer';
import DocumentsContainer from './app/containers/DocumentsContainer';
import DashboardContainer from './app/containers/DashboardContainer';
import ListReceipts from './app/components/receipts/ListReceipts';
import ListDocuments from './app/components/documents/ListDocuments';
import ListAgreements from './app/components/agreements/ListAgreements';
import ServiceBookContainer from './app/containers/ServiceBookContainer';
import FixedCostsContainer from './app/containers/FixedCostsContainer';

import ServiceBookStatus from './app/components/serviceBooks/ServiceBookStatus';
import ServiceBookHistory from './app/components/serviceBooks/ServiceBookHistory';
import ServiceBookPlan from './app/components/serviceBooks/ServiceBookPlan';
import ServiceBookDetails from './app/components/serviceBooks/ServiceBookDetails';
import AuthenticationContainer from './app/containers/AuthenticationContainer';
import AdminDashboard from './app/components/admin/AdminDashboard';
import AdminContainer from './app/containers/admin/AdminContainer';
import UserAdminComponent from './app/components/admin/UserAdminComponent';
import ListAdminCustomers from './app/components/admin/ListAdminCustomers';
import AdminServiceItems from './app/components/admin/AdminServiceItems';
import ListAdminUsers from './app/components/admin/ListAdminUsers';
import NotificationComponent from './app/components/layout/NotificationComponent';
import ProfileComponent from './app/components/profile/ProfileComponent';
import ReportReceiptComponent from './app/components/reports/ReportReceiptComponent';
import ReportServiceCasesComponent from './app/components/reports/ReportServiceCasesComponent';
import ProfileContainer from './app/containers/ProfileContainer';

import { Auth0Provider } from '@auth0/auth0-react';
import ServiceCalendar from './app/components/serviceBooks/ServiceCalendar';
import { colors } from './styles/themeStylesSx';
import ServiceBookCostTypes from './app/components/serviceBooks/ServiceBookCostTypes';
import ProfileCustomerComponent from './app/components/profile/ProfileCustomerComponent';
import ListSubscriptions from './app/components/admin/subscription/ListSubscriptions';
import ServiceBookDocuments from './app/components/serviceBooks/ServiceBookDocuments';
import ProfileRemindersComponent from './app/components/profile/ProfileReminders';

const color1 = '#96CBA0';
const color2 = '#00687D';

const mdTheme = createTheme({
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.backgroundGreen,
          boxShadow: 'none',
          borderBottom: 'thin solid gray',
        }
      }
    },
  },
  palette: {
    primary: {
      main: color1,
    },
    secondary: {
      main: color2,
    },
    warning: {
      main: '#EF5F5F',
    }
  },
  typography: {
    fontFamily: [
      'Lexend', 'sans-serif'
    ].join(','),
    button: {
      fontWeight: 600,
    },
    h1: {
      fontWeight: 600
    },
    h2: {
      fontWeight: 600
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    },
    h6: {
      fontWeight: 600
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={mdTheme}>

      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        redirectUri={`${window.location.origin}/dashboard`}
        scope="read:current_user"
        uiLocales="fi"
        useRefreshTokens={true}
      >
        <AuthenticationContainer>
          <BrowserRouter>
            <Box sx={{ display: 'flex' }}>
              <AppMenuBar />
              <SideNavigation />
              <Box
                component="main"
                sx={{ flexGrow: 1, p: { xs: 0, md: 3 }, mt: { xs: 4, md: 0 } }}
              >
                <Toolbar />
                <Routes>
                  <Route path="profile" element={<ProfileContainer />}>
                    <Route path="customer" element={<ProfileCustomerComponent />} />
                    <Route path="reminders" element={<ProfileRemindersComponent />} />
                    <Route index element={<ProfileComponent />} />
                  </Route>
                  <Route path="fixed-costs" element={<FixedCostsContainer />} />
                  <Route path="admin" element={<AdminContainer />}>
                    <Route path="customers" element={<ListAdminCustomers />} />
                    <Route path="service-items" element={<AdminServiceItems />} />
                    <Route path="users" element={<ListAdminUsers />} />
                    <Route path="users/:userId" element={<UserAdminComponent />} />
                    <Route path="subscriptions" element={<ListSubscriptions/>} />
                    <Route index element={<AdminDashboard />} />
                  </Route>
                  <Route path="agreements" element={<AgreementsContainer />}>
                    <Route index element={ <ListAgreements />} />
                  </Route>
                  <Route path="service-books" element={<ServiceBooksContainer />} />
                  <Route path="service-books/:serviceBookId" element={<ServiceBookContainer />}>
                    <Route path="details" element={<ServiceBookDetails />} />
                    <Route path="service-plan" element={<ServiceBookPlan />} />
                    <Route path="service-cases" element={<ServiceBookHistory />} />
                    <Route path="service-calendar" element={<ServiceCalendar /> } />
                    <Route path="service-calendar/:year/:season" element={<ServiceCalendar /> } />
                    <Route path="status" element={<ServiceBookStatus />} />
                    <Route path="cost-types" element={<ServiceBookCostTypes />} />
                    <Route path="documents" element={<ServiceBookDocuments />} />
                  </Route>
                  <Route path="documents" element={<DocumentsContainer/>} >
                    <Route index element={ <ListDocuments />} />
                  </Route>
                  <Route path="reports" element={<ReportsContainer />}>
                    <Route path="receipts" element={<ReportReceiptComponent/>} />
                    <Route path="servicecase" element={<ReportServiceCasesComponent/>} />
                  </Route>
                  <Route path="receipts" element={<ReceiptsContainer />}>
                    <Route index element={ <ListReceipts />} />
                  </Route>
                  <Route path="dashboard" element={<DashboardContainer />} />
                  <Route path='/' element={<DashboardContainer />} />
                </Routes>
              </Box>
            </Box>
            <NotificationComponent />
          </BrowserRouter>
        </AuthenticationContainer>
      </Auth0Provider>
    </ThemeProvider>
  );
}

export default App;
