import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getValueFromStateOrObject } from "../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useLocales from "../../hooks/useLocales";
import { saveFixedCostTypeAction } from "../../redux/fixedCosts/fixedCostsSlice";
import { FixedCostType } from "../../types/costs";
import { ServiceBookType } from "../../types/serviceBook";

type Props = {
  new: boolean,
  cost?: FixedCostType,
  serviceBook: ServiceBookType,
  cancel: () => void,
};

export default function AddEditCostType(props: Props) {

  const { cost, cancel } = props;
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<Partial<FixedCostType>>({});
  const { saving, saved  } = useAppSelector(store => store.fixedCosts);

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  const submit = () => {
    if (cost && cost.id) {
      if (state.name && state.name.length > 0) {
        dispatch(saveFixedCostTypeAction({ name: state.name, id: cost.id, serviceBookId: props.serviceBook.id }));
      }
    } else {
      if (state.name && state.name.length > 0) {
        dispatch(saveFixedCostTypeAction({ name: state.name, serviceBookId: props.serviceBook.id }));
      }
    }
  };

  useEffect(() => {
    cancel();
  }, [saved]);

  return (
    <Box>
      <TextField
        sx={{ mt: 2, mb: 2 }}
        label={t('Name')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, cost, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <Box sx={{ mt: 1 }}>
        <Button variant="outlined" onClick={cancel}>
          {t('Cancel')}
        </Button>
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick={submit}
            >
              {t('Submit')}
            </Button>
        }
      </Box>
    </Box>
  );
}