import { AddRounded, EditRounded } from '@mui/icons-material';
import { Skeleton, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { colors } from '../../../styles/themeStylesSx';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { deleteAdminServicePlanAction, getAdminServicePlans } from '../../redux/admin/adminServicePlanSlice';
import ConfirmableButton from '../buttons/ConfirmableButton';
import SidePanel from '../sidepanel/SidePanel';
import AddEditAdminServiceItem from './AddEditAdminServiceItem';
import AdminManageServiceBookProperties from './AdminManageServiceBookProperties';
import AdminMapItemsToServiceBookProperties from './adminMapItemsToServiceBookProperties';

type AdminServiceItemsState = {
  add?: boolean;
  edit?: number | null;
  showProperties?: boolean;
  mapProperties?: boolean;
}

export default function AdminServiceItems(props: any) {
  const { t } = useLocales();
  const dispatch = useAppDispatch();

  const { list, loading, loaded, reload, deleting } = useAppSelector(store => store.adminServicePlan);
  const [state, setState] = useState<AdminServiceItemsState>({});

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getAdminServicePlans());
    }
  }, [dispatch, loaded, loading, reload]);

  const addNew = () => {
    setState({ add: true, edit: null });
  };

  const getCategoriesForType = (type: string) => {
    const categories = (list || []).filter(row => row.forType === type).reduce((categories: string[], item) => {
      const result = categories;
      const category = item.category || '';
      if (result.indexOf(category) === -1) {
        result.push(category);
      }
      return result;
    }, []);
    return categories;
  };

  const byType = (list || []).filter(row => row.forType).reduce((types: string[], item) => {
    const result = types;
    const type = item.forType || 'not set';
    if (result.indexOf(type) === -1) {
      result.push(type);
    }
    return result;
  }, []);

  const editItem = (id: number | undefined) => {
    if (!id) {
      return;
    }
    setState({ edit: id });
  };

  const deleteItem = (id?: number) => {
    if (!id) {
      return;
    }
    dispatch(deleteAdminServicePlanAction({ id }));
  };

  return (
    <Box sx={{ p: 2, m: 2 }}>
      <SidePanel
        open={ !!(state.add || (state.edit && state.edit > 0)) }
        close={ () => setState({ }) }
      >
        <AddEditAdminServiceItem close={ () => setState({})} item={ state.edit ? list.find(row => row.id === state.edit) : undefined } />
      </SidePanel>
      <SidePanel
        open={ state.showProperties === true }
        close={ () => setState({}) }
      >
        <Box>
          <AdminManageServiceBookProperties />
        </Box>
      </SidePanel>

      <SidePanel
        open={ state.mapProperties === true }
        close={ () => setState({}) }
      >
        <Box>
          <AdminMapItemsToServiceBookProperties />
        </Box>
      </SidePanel>
      {
        loading && <><Skeleton animation="wave" /><Skeleton animation="wave" /><Skeleton animation="wave" /></>
      }
      {
        loaded && (!list || list.length === 0) && t('No data found')
      }
      <Button variant="outlined" onClick={() => setState({ ...state, showProperties: true })}>{t('Manage service book properties')}</Button>
      <Button variant="outlined" sx={{ ml: 2 }} onClick={() => setState({ ...state, mapProperties: true })}>{t('Map service items to service book properties')}</Button>
      {
        loaded && list && list.length > 0 &&
        byType.map((type, typeIdx) => {
          const categories = getCategoriesForType(type);
          return (
            <Box key={`typeidx_${typeIdx}`} sx={{ mt: 2, mb: 4 }}>
              <Typography sx={{ mb: 1 }} variant="h5">{ t(`service_book_type_${type}`) } </Typography>
              {categories.map( (category, categoryIdx) => {
                const itemlist = list.filter(row => row.forType === type && (category === '' ? row.category === null : row.category === category));
                if (itemlist.length === 0) {
                  return <></>;
                }
                return (
                  <Box key={`category_${categoryIdx}`} sx={{ mt: 1, mb: 2 }}>
                    <Typography variant="h6">{ category || t('No category') }</Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>{t('Name')}</TableCell>
                          <TableCell>{t('Type')}</TableCell>
                          <TableCell>{t('Indoor or outdoor')}</TableCell>
                          <TableCell>{t('Season')}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          itemlist.map((row, idx) => {
                            const seasons = [];
                            if (row.winter) {
                              seasons.push(t('winter'));
                            }
                            if (row.spring) {
                              seasons.push(t('spring'));
                            }
                            if (row.fall) {
                              seasons.push(t('fall'));
                            }
                            if (row.summer) {
                              seasons.push(t('summer'));
                            }
                            return (
                              <TableRow sx={{ backgroundColor: idx % 2 ? colors.navigationSelectedList : '#fff' }} key={`item_${row.id}`}>
                                <TableCell>
                                  { idx + 1 }
                                </TableCell>
                                <TableCell>
                                  { row.name }
                                </TableCell>
                                <TableCell>
                                  { row.type }
                                </TableCell>
                                <TableCell>
                                  { row.indoorOrOutdoor }
                                </TableCell>
                                <TableCell>
                                  {
                                    seasons.join(', ')
                                  }
                                </TableCell>
                                <TableCell>
                                  <Box sx={{ display: 'flex' }}>
                                    <IconButton onClick={ () => editItem(row.id) }><EditRounded /></IconButton>
                                    <ConfirmableButton showLoader={deleting} onSubmit={ () => deleteItem(row.id) } />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        }
                      </TableBody>
                    </Table>
                  </Box>);
              })}
            </Box>
          );
        })

      }
      <Box sx={{ mt: 2 }}>
        <Button variant="outlined" color="secondary" onClick={ addNew } startIcon={ <AddRounded />}>{t('Add new service plan item to admin list')}</Button>
      </Box>
    </Box>
  );
}