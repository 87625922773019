import { FormControl, InputLabel, MenuItem, Select, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useLocales from "../../hooks/useLocales";
import { clearAgreementsState } from '../../redux/agreements/agreementsSlice';
import { setSelectedCustomerAction } from '../../redux/customer/customerSlice';
import { clearReceiptState } from '../../redux/receipts/receiptsSlice';
import { clearServiceBookState } from '../../redux/serviceBooks/serviceBookSlice';
import { clearReportsState } from '../../redux/report/reportSlice';
import { clearDocumentState } from '../../redux/documents/documentsSlice';
import { clearFixedCostsState } from "../../redux/fixedCosts/fixedCostsSlice";
export default function CustomerSelect() {
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { details, list } = useAppSelector(store => store.customer);

  if (list && list.length === 1 && details) {
    return <Box sx={{ display: { md: 'block', xs: 'none' }}}>{details.name}</Box>;
  }

  const selectCustomer = (id: number) => {
    dispatch(setSelectedCustomerAction(id));
    dispatch(clearReceiptState());
    dispatch(clearAgreementsState());
    dispatch(clearServiceBookState());
    dispatch(clearReportsState());
    dispatch(clearDocumentState());
    dispatch(clearFixedCostsState());
  };

  return (
    <FormControl
      sx={{ fontSize: { xs: '12px', md: '14px'} }}
      fullWidth
    >
      <InputLabel>{t('Select customer')}</InputLabel>
      <Select
        size="small"
        sx={{ fontSize: { xs: '12px', md: '14px'} }}
        label={t('Select customer')}
        value={ details ? details.id : '-1' }
        onChange={(e: any) => {
          if (e.target.value === '-1') {
            return;
          }
          selectCustomer(parseInt(e.target.value));
        }}
      >
        <MenuItem value="-1">{t('Select')}</MenuItem>
        {
          (list || []).map(row =>
            <MenuItem key={`row_${row.id}`} value={`${row.id}`}>
              { row.name }
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  );
}