import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { updateOwnCustomerAction } from '../../redux/customer/customerSlice';

import { CustomerType } from '../../types/customer';

type PropsType = {
  details: CustomerType | null,
  cancel?: () => void,
};

const EditCustomerComponent = (props: PropsType) => {

  const [state, setState] = useState<Partial<CustomerType>>({ });
  const dispatch = useAppDispatch();
  const { updated } = useAppSelector(store => store.profile);
  const { t } = useLocales();
  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  useEffect(() => {
    if (updated && props.cancel) {
      props.cancel();
    }
  }, [updated]);

  const submit = () => {
    const data: any = { ...state };
    dispatch(updateOwnCustomerAction({id: props.details?.id, data }));
  };

  return (
    <Box>
      <TextField
        sx={{ mt: 2 }}
        label={t('Name')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('Phone')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'phone') }
        onChange={(e) => handleChange('phone', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('Invoicing email')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'email') }
        onChange={(e) => handleChange('email', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('Address')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'address') }
        onChange={(e) => handleChange('address', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('Postal code')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'postalCode') }
        onChange={(e) => handleChange('postalCode', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('City')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'city') }
        onChange={(e) => handleChange('city', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('Country')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'country') }
        onChange={(e) => handleChange('country', e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <Button onClick={props.cancel}>{t('Cancel')}</Button>
        <Button variant="contained" onClick={submit}>{t('Submit')}</Button>
      </Box>

    </Box>
  );
};

export default EditCustomerComponent;