import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import useLocales from '../../hooks/useLocales';
import { CustomerType } from '../../types/customer';
import AddEditAdminNote from './AddEditAdminNote';
import ListAdminNotes from './ListAdminNotes';
type CustomerComponentType = {
  customer?: CustomerType,
  close: () => void,
};

export default function CustomerAdminNotes(props: CustomerComponentType) {
  const { customer } = props;
  const { t } = useLocales();

  if (customer?.logs?.length === 0) {
    return (
      <Box>
        <Typography sx={{ p: 2 }} variant="h6">{t('Add log')}</Typography>
        <AddEditAdminNote update= {false} customer={customer} />
      </Box>
    );
  }
  else {
    return (
      <Box>
        <Typography sx={{ p: 2 }} variant="h6">{t(`Log messages of ${customer?.name} `)}</Typography>
        <ListAdminNotes customer={customer} />
      </Box>
    );

  }
}