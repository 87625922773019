import { Box, Button, Container, Link, Typography, Grid } from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { getCurrentSeason } from '../../helpers/helpers';
import useLocales from '../../hooks/useLocales';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { getServiceCaseCalendarForSeason } from '../../redux/serviceBooks/serviceBookSlice';
import SkeletonRows from '../layout/SkeletonRows';
import ServiceBookCalendarSeasonItems from './ServiceBookCalendarSeasonItems';
import { subYears } from 'date-fns';

export type SeasonType = "spring" | "winter" | "summer" | "fall";

type StateType = {
  season: SeasonType;
  year: number;
}

const getSeasonFromString = (season: string): SeasonType => {
  switch (season) {
  case 'spring':
    return 'spring';
  case 'fall':
    return 'fall';
  case 'summer':
    return 'summer';
  case 'winter':
    return 'winter';
  }
  return getCurrentSeason();
};

export default function ServiceBookStatus() {
  const params = useParams();
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { calendar, loadingCalendar, loadedCalendar } = useAppSelector(store => store.serviceBook);
  const { serviceBook } = useServiceBookOutletContext();
  const month = new Date().getMonth() + 1;
  const currentYear = month === 12 ? subYears(new Date(), 1).getFullYear() : new Date().getFullYear();
  const [state, setState] = useState<StateType>({
    season: params.season ? getSeasonFromString(params.season) : getCurrentSeason(),
    year: params.year ? +params.year : currentYear,
  });

  useEffect(() => {
    dispatch(getServiceCaseCalendarForSeason({ year: state.year, season: state.season, serviceBookId: serviceBook.id }));
  }, [state.year, state.season]);

  useEffect(() => {
    if (params.year && params.season && params.season !== state.season) {
      setState({ ...state, year: +params.year, season: getSeasonFromString(params.season) });
    }
  }, [params.year, params.season]);

  if (!serviceBook) {
    return <Box>{t('Not found')}</Box>;
  }

  const year = state.year;
  const season = state.season;

  const seasonData = calendar.find(row => row.datakey === `${year}-${season}-${serviceBook.id}`);
  if (loadingCalendar) {
    return <SkeletonRows rows={ 3 } />;
  }
  if (!seasonData) {
    return <Box>{t('No season data found')}</Box>;
  }
  const seasonItems = seasonData.data;

  let prevYear = year;
  let nextYear = year;

  let prevSeason : SeasonType | undefined = undefined;
  let nextSeason : SeasonType | undefined = undefined;

  if (season === "spring") {
    prevSeason = "winter";
    nextSeason = "summer";
    prevYear = year;
  } else if (season === "summer") {
    prevSeason = "spring";
    nextSeason = "fall";
  } else if (season === "fall") {
    prevSeason = "summer";
    nextSeason = "winter";
    nextYear = year + 1;
  } else if (season === "winter") {
    prevSeason = "fall";
    nextSeason = "spring";
    prevYear = year - 1;
  }
  let seasonYear = `${state.year}`;
  if (season === 'winter') {
    seasonYear = `${prevYear} / ${nextYear}`;
  }
  return (
    <Container>
      <Grid sx={{ mb: 3, mt: 1 }} container spacing={2} justifyContent='space-evenly' alignItems='center'>
        <Grid item>
          <Link component={RouterLink} to={`/service-books/${serviceBook.id}/service-calendar/${prevYear}/${prevSeason}`}>
            <Button variant="outlined" startIcon={<ArrowBackRounded />} />
          </Link>
        </Grid>
        <Grid sx={{ display: { md: 'block', xs: 'none' }}} item>
          <Typography variant="h6">{t('Service calendar')} - { t(`Season_${season}`) } { seasonYear } </Typography>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={`/service-books/${serviceBook.id}/service-calendar/${nextYear}/${nextSeason}`}>
            <Button variant="outlined" startIcon={<ArrowForwardRounded />} />
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <Typography variant="h6">{t('Service calendar')} / { t(`Season_${season}`) } </Typography>
        </Box>

        <ServiceBookCalendarSeasonItems
          seasonItems={ seasonItems }
          serviceBook={serviceBook}
          season={season}
          year={year}
        />
      </Box>
    </Container>
  );
}