import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';

const translations: {[key: string]: string} = {
  Dashboard: 'Tilanne',
  Targets: 'Kohteet',
  Home: 'Koti',
  Car: 'Auto',
  Person: 'Henkilö',
  Agreements: 'Sopimukset',
  Receipts: 'Takuukuitit',
  Reports: 'Raportit',
  Add: 'Lisää',
  'Add new': 'Lisää uusi',
  'Expiry date': 'Vanhentumispäivä',
  'Agreement expiring': 'Sopimus päättyy',
  Description: 'Kuvaus',
  Total: 'Yhteensä',
  Attachment: 'Liite',
  Identifier: 'Tunniste',
  'Add new agreement': 'Lisää uusi sopimus',
  Date: 'Päiväys',
  'Add new receipt': 'Lisää uusi kuitti',
  Receipt: 'Kuitti',
  Agreement: 'Sopimus',
  Back: 'Takaisin',
  'Service plan': 'Huoltosuunnitelma',
  'Recently done': 'Viimeaikoina suoritetut',
  'Upcoming inspections': 'Tulevat tarkastukset',
  'Inspections': 'Tarkastukset',
  'Service cases': 'Huollot',
  'Handled': 'Suoritettu',
  'Upcoming services': 'Tulevat huollot',
  'Long term plan': 'Pitkän aikavälin toimenpiteet',
  'Service history': 'Tapahtumahistoria',
  'Service calendar': 'Tarkastuskalenteri',
  'Details': 'Tiedot',
  'Status': 'Tilanne',
  'Service status': 'Huoltotilanne',
  'Service books': 'Huoltokirjat',
  'Inspections history': 'Tarkastushistoria',
  'Interval': 'Huoltoväli',
  'mth': 'kk',
  'd': 'pv',
  'yr': 'v',
  'Costs': 'Kulut',
  'Cost': 'Kulu',
  'Ventilation': 'Ilmanvaihto',
  'Heating': 'Lämmitys',
  'Structure': 'Rakenne',
  'Address': 'Osoite',
  'Construction year': 'Rakennusvuosi',
  'Area': 'Huoneistoala',
  'Total Area': 'Kerrosala',
  'thermal': 'maalämpö',
  'electricity': 'sähkö',
  'wood': 'puu',
  'other': 'muu',
  'natural': 'painovoimainen',
  'mechanical': 'koneellinen',
  'log': 'Hirsi',
  'heatpump': 'ilmalämpöpumppu',
  'oil': 'öljy',
  'stone': 'kivi',
  'building': 'kiinteistö',
  'vehicle': 'ajoneuvo',
  'trailer': 'perävaunu',
  'car': 'henkilöauto',
  'Submit': 'Tallenna',
  'Cancel': 'Peruuta',
  Value: 'Arvo',
  Unit: 'Yksikkö',
  'No upcoming events!': 'Ei tulevia tapahtumia!',
  'Recently done inspections': 'Suoritetut tarkastukset',
  service_book_type_building: 'Rakennuksen huolto',
  service_book_type_vehicle: 'Auton huolto',
  'Drag and drop some files here, or click to select files': 'Raahaa tiedosto tähän, tai klikkaa ja valitse tiedosto',
  'Drop the files here': 'Raahaa tiedostot tähän',
  'Add new service case': 'Lisää huoltotapahtuma',
  'Attachments': 'Liitteet',
  'Price': 'Hinta',
  'Add new inspection case': 'Lisää tarkastustapahtuma',
  'Done date': 'Päiväys',
  'No upcoming inspections': 'Ei tulevia tapahtumia',
  'No upcoming services': 'Ei tulevia huoltoja',
  'My profile': 'Profiili',
  'Profile': 'Profiili',
  'Logout': 'Kirjaudu ulos',
  'Select customer': 'Valitse asiakkuus',
  'Select item': 'Valitse huolto',
  'Bought from': 'Ostopaikka',
  'Edit': 'Muokkaa',
  'Are you sure?': 'Oletko varma?',
  'Confrim': 'Vahvista',
  'Show details': 'Anna lisätietoja',
  'Documents': 'Dokumenttipankki',
  'Season_spring': 'Kevät',
  'Season_summer': 'Kesä',
  'Season_winter': 'Talvi',
  'Season_fall': 'Syksy',
  'Upcoming cases': 'Ajankohtaiset toimenpiteet',
  'Warranty receipts': 'Takuukuitit',
  'Settings': 'Asetukset',
  Open: 'Avaa',
  'Target needed service': 'Tehtiin huoltotoimenpide',
  'Action done': 'Mitä tehtiin',
  'Short explanation what was done': 'Lyhyt kuvaus siitä mitä tehtiin',
  'Check to add more details what was done': 'Jos kohde tarvitsi huoltoa, valitse tästä ja täytä lisätiedot',
  'Requires attention': 'Vaatii tarkistusta myöhemmin',
  'Service will be showing up in requires attention list until finished': 'Tarkistus näkyy tarkistuslistalla korostettuna siihen asti että merkitty tarkistetuksi',
  'Add new document': 'Lisää dokumentti',
  Type: 'Tyyppi',
  Select: 'Valitse',
  Document: 'Dokumentti',
  Customers: 'Asiakkaat',
  'Service items': 'Huolto-ohjelma',
  Users: 'Käyttäjät',
  Currency: 'Valuutta',
  Timezone: 'Aikavyöhyke',
  Confirm: 'Vahvista',
  'Add new customer': 'Lisää uusi asiakas',
  Name: 'Nimi',
  Email: 'Sähköposti',
  'Add new user': 'Lisää käyttäjä',
  'Select from service plan items': 'Lisää yleisestä tarkastussuunnitelmasta',
  'Add new service plan item': 'Lisää oma tarkistus suunnitelmaan',
  Category: 'Kategoria',
  Winter: 'Talvi',
  winter: 'talvi',
  Spring: 'Kevät',
  spring: 'kevät',
  Summer: 'Kesä',
  summer: 'kesä',
  Fall: 'Syksy',
  fall: 'syksy',
  Seasons: 'Vuodenajat',
  'Select all': 'Valitse kaikki',
  'Unselect all': 'Poista valinta kaikista',
  'No items found, you can still add your own': 'Tarkastuskohteita ei löytynyt, voit kuitenkin lisätä oman tarkistuksen',
  'Approximate cost': 'Hinta-arvio',
  Indoors: 'Sisätiloissa',
  Outdoors: 'Ulkona',
  'Unselect both to not matter': 'Jätä valitsematta jos ei liity tarkistukseen',
  'How many times it should be done in year': 'Kuinka monta kertaa vuodessa pitäisi suorittaa',
  'District heating': 'Kaukolämpö',
  area: 'huoneistoala',
  'totalArea': 'Kerrosala',
  'constructionYear': 'Rakennusvuosi',
  floors: 'Kerroksia',
  address: 'Osoite',
  Building: 'Rakennus',
  Vehicle: 'Ajoneuvo',
  Other: 'Muu',
  'Start planning from': 'Aloita suunnittelu päivästä',
  'Ventilation type': 'Ilmanvaihdon tyyppi',
  Gravity: 'Painovoimainen',
  gravity: 'painovoimainen',
  'Structure type': 'Rakennuksen tyyppi',
  'Electricity': 'Sähkö',
  'Heating type': 'Lämmitysmuoto',
  'Thermal / Ground heating': 'Maalämpö',
  Stone: 'Kivi',
  Mechanical: 'Koneellinen',
  Save: 'Tallenna',
  Wood: 'Puu',
  'Service case': 'Huolto / tarkistus',
  'Select service book': 'Valitse huoltokirja',
  'Select service': 'Valitse huolto',
  'Date action completed': 'Toimenpiteen päivämäärä',
  'Add user to customer': 'Liitä käyttäjä asiakkuuteen',
  'Edit customer users': 'Hallinnoi asiakkaan käyttäjiä',
  'Indoor or outdoor': 'Sisällä vai ulkona',
  'Season': 'Kausi',
  'Add new service plan item to admin list': 'Lisää uusi tapahtuma huoltolistaan',
  'Add new service book': 'Lisää huoltokirja',
  'Fixed costs': 'Kuluseuranta',
  'Add costs': 'Kirjaa kuluja',
  'Previous year': 'Edellinen vuosi',
  'Per month (estimate)': 'Kuukaudessa (arvio)',
  'Current year': 'Kuluva vuosi',
  'No cost types': 'Ei lisättyjä kululuokkia',
  'Inspection status': 'Tarkastustilanne',
  'Cost types': 'Kululuokat',
  'Add new cost type': 'Lisää uusi',
  'Add multiple': 'Lisää listalta',
  'Edit cost type': 'Muokkaa kululuokkaa',
  'Month (estimate)': 'Kk (arvio)',
  Landing: 'Etusivu',
  Delete: 'Poista',
  'No category': 'Kategorioimattomat',
  'Manufactured year': 'Valmistusvuosi',
  'License plate': 'Rekisterinumero',
  'Service book': 'Huoltokirja',
  'No options': 'Ei vaihtoehtoja',
  'No customer profile found. Start free test period from here!': 'Asiakkuutta ei vielä löydy, aloita ilmainen kokeilujakso tästä!',
  'No thanks, log me out!': 'Ei kiitos, kirjaa minut ulos',
  'Level': 'Käyttäjätaso',
  'Finish your profile details from here!': 'Tarkista käyttäjätietosi tästä',
  'Add your first service book from here': 'Lisää tästä ensimmäinen huoltokirja',
  'Phone': 'Puhelinnumero',
  Customer: 'Asiakkuus',
  'Invoicing email': 'Laskutussähköposti',
  'Does the property include': 'Onko kohteessa',
  'centralVacuumCleaner': 'Keskuspölynimuri',
  'fireplace': 'Takka/leivinuuni',
  'roofWindow': 'Kattoikkuna',
  'electricMeltingSystem': 'Sähköinen sulatusjärjestelmä (esim pihatie)',
  'solarPanels': 'Aurinkopaneelit',
  'Heating system': 'Lämmitysmuoto',
  'airCondition': 'Ilmalämpöpumppu',
  'waterAirHeatPump': 'Vesi-ilmalämpöpumppu (VILP)',
  'exhaustAirHeatPump': 'Poistoilmalämpöpumppu (PILP)',
  'thermalHeating': 'Maalämpö',
  'districtHeating': 'Kaukolämpö',
  'woodHeating': 'Puulämmitys',
  'oilHeating': 'Öljylämmitys',
  'pelletHeating': 'Pellettilämmitys',
  'electricHeating': 'Sähkölämmitys',
  'Heat distribution system': 'Lämmönsiirto',
  'waterCirculatingFloorHeating': 'Vesikiertoinen lattialämmitys',
  'waterCirculatingRadiatorHeating': 'Vesikiertoinen patterilämmitys',
  'electricFloorHeating': 'Sähköinen lattialämmitys',
  'electricRadiatorHeating': 'Sähköinen patterilämmitys',
  'Ventilation system': 'Ilmanvaihto',
  'mechanicalVentilation': 'Koneellinen ilmanvaihto',
  'naturalVentilation': 'Painovoimainen ilmanvaihto',
  'Next page': 'Seuraava',
  'Previous page': 'Edellinen',
  'There seems to be technical issue, reload page or try again later': 'Tekninen häiriö, yrityä myöhemmin uudelleen. Ongelman jatkuessa otathan yhteyttä asiakaspalveluun.',
  'No subscription': 'Ei sopimusta',
  'Your test subscription has ended': 'Koekäyttö on päättynyt, klikkaa tästä ja valitse palvelutaso jolla haluat jatkaa käyttöä',
  'licensePlate': 'Rekisterinumero',
  'manufacturedYear': 'Valmistusvuosi',
  'Any subscription': 'Mikä tahansa sopimus',
  'Expired test users': 'Loppunut koekäyttö',
  'Filter by subscription': 'Rajaa tuloksia sopimuksen perusteella',
  'All': 'Kaikki',
  'Customer details': 'Asiakastiedot',
  'Subscription': 'Sopimus',
  'Postal code': 'Postinumero',
  'City': 'Kaupunki',
  'Country': 'Maa',
  'Add new subscription': 'Lisää uusi sopimustyyppi',
  'Subscriptions': 'Sopimustyypit',
  'Active': 'Aktiivinen',
  'Created': 'Luontipäivä',
  'Updated': 'Päivityspvm',
  'Activate subscription': 'Aktivoi sopimus tästä',
  'Add cost': 'Lisää maksu',
  'Add cost for year': 'Lisää maksun suuruus vuositasolla',
  'Edit cost for year': 'Muokkaa maksun suuruutta vuositasolla',
  'Total for year': 'Yhteensä/vuosi',
  'Cost type': 'Maksun tyyppi',
  'Year': 'Vuosi',
  'Your test subscription ends in {1} days': 'Koekäyttö päättyy {1} päivän kuluttua',
  'Activate service': 'Aktivoi palvelu',
  'Read more about pricing and features from here': 'Lue lisää hinnoittelusta ja ominaisuuksista täältä',
  'Select subscription': 'Valitse taso',
  'Check the contact details': 'Tarkista yhteystiedot',
  'Submitting the details you agree on': 'Vahvistamalla hyväksyt',
  'Invalid servicebook': 'Virheellinen huoltokirja',
  'Account is not active, enable subscription to continue usage': 'Tili ei ole aktiivinen, valitse palvelutaso jatkaaksesi käyttöä',
  'electricSauna': 'Sähkölämmitteinen sauna',
  'woodSauna': 'Puulämmitteinen sauna',
  'Properties': 'Valinnat',
  'Comment': 'Kommentti',
  'Custom service': 'Muu huolto',
  'No cost types found': 'Kululuokkia ei määritelty',
  'Submit subscription': 'Vahvista',
  'Expires at': 'Vanhentuu',
  'Created at': 'Luontipäivä',
  'Edit docuemnt': 'Muokkaa dokumenttia',
  'Edit receipt': 'Muokkaa kuittia',
  'Error loading the documents': 'Virhe ladattaessa dokumentteja',
  'Failure loading the notifications': 'Virhe muistutusten lataamisessa',
  'Reminders': 'Muistutukset',
  'Failure loading the reminders': 'Virhe ladattaessa muistutuksia',
  'No reminders added. Create reminders through service case handling': 'Muistutuksia ei ole lisätty. Lisää muistutus huoltotapahtuman kuittauksen yhteydessä',
  'Remind me for the next service': 'Muistuta minua tekstiviestillä seuraavasta huollosta',
  'Create reminder for this type of plan item': 'Luo muistutus huollosta, muistutuksia pystyt hallinnoimaan profiilisivun muitutukset -osiosta.',
  'Reminder will be sent to the phone number of this user': 'Muistutus lähetetään proofilissa määritettyyn puhelinnumeroon',
  'Reminder date and time': 'Muistutusajankohta',
  'Scheduled at': 'Muistutusajankohta',
  'Target': 'Kohde',
  'There is limit of {1} reminders per year per customer for the default subscription': 'Tilaukseen kuuluu 10 muistutusta vuodessa asiakkuutta kohden',
  'Unfortunately you have no access to this feature': 'Ikävä kyllä tämä ei kuulu sopimukseesi. Olethan yhteydessä asiakaspalveluun.',
  'Documents of customer': 'Asiakkuuden dokumentit',
  'No documents': 'Ei dokumentteja',
  'Fulfilled': 'Käsitellyt',
  'Active reminders': 'Aktiiviset muistutukset',
  'Fulfilled reminders': 'Käsitellyt muistutukset',
  'All reminders used ({1} pieces). Contact customer service to purchase more': 'Muistutuksia käytetty maksimimäärä tälle kalenterivuodelle. ({1} kpl)',
  'All limited features': 'Kaikki Huoltokirja ominaisuudet',
  'SMS reminders': 'Tekstiviestimuistutukset',
  'Subscription_limited': 'Huoltokirja',
  'Subscription_full service': 'Kodin sähköinen kansio',
  'To change the subscription type contact the customer service via email {1} or calling the customer service {2}': 'Jos haluat muuttaa sopimus-tyyppiä, ota yhteys asiakaspalveluun sähköpostilla {1} tai soittamalla asiakaspalveluun {2}',
  'SMS reminders limit': 'Tekstiviestimuistutusten määrä',
  'Limit of reminders is per customer, check the customer page to review that': 'Tekstiviestimuistutusten määrä on asiakaskohtainen. Oletuksena 20kpl/kalenterivuosi. Tarkista määrä asiakassivulta',
  'Service book documents': 'Asiakirjat',
  'Reminder': 'Muistutus',
  'Sent at': 'Lähetetty',
  'Delete reminder': 'Poista muistutus',
  'Add reminder': 'Lisää muistutus',
  'Add sms reminder': 'Lisää tekstiviestimuistutus',
};

export interface TranslationsState {
  translations: {[key: string]: string};
  status: 'idle' | 'loading' | 'failed';
}

const initialState: TranslationsState = {
  translations: translations,
  status: 'idle',
};


export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    load: (state, action: PayloadAction<{[key: string]: string}>) => {
      state.status = 'idle';
      state.translations = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {}
});

export const { load } = translationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTranslations = (state: RootState) => state.translation.translations;

export default translationsSlice.reducer;
