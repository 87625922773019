import { Button, TextField, Box, Typography, FormControl, Select, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import useLocales from "../../hooks/useLocales";
import { FixedCost, FixedCostType } from "../../types/costs";
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { subYears } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { clearFixedCostsState, saveFixedCostForServiceBookAction } from "../../redux/fixedCosts/fixedCostsSlice";

type Props = {
  cost?: FixedCost,
  typeId?: number,
  costTypes: FixedCostType[],
  serviceBookId: number,
  close: () => void,
};
export default function AddEditFixedCost(props: Props) {
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { saving, saved } = useAppSelector(store => store.fixedCosts);
  const [state, setState] = useState<Partial<FixedCost>>({ fixedCostTypeId: props.typeId, year: props.cost ? props.cost.year : (new Date()).getFullYear() - 1 });

  useEffect(() => {
    if (saved === true) {
      props.close();
      dispatch(clearFixedCostsState());
    }
  }, [saved]);

  useEffect(() => {
    setState({ ...state, fixedCostTypeId: props.typeId });
  }, [props.typeId]);

  useEffect(() => {
    if (state.fixedCostTypeId && state.year && state.year > 0) {
      const existCost = getExistCost(state.fixedCostTypeId, state.year);
      if (existCost) {
        setState({ ...state, total: existCost.total });
      }
    }
  }, [state.fixedCostTypeId, state.year]);

  const getExistCost = (typeId: number, year: number) => {
    const existType = props.costTypes.find(row => row.id === typeId);
    const existCost = existType && existType.fixedCosts && existType.fixedCosts.find(row => row.year === year);
    if (existCost) {
      return existCost;
    }
  };
  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  const submit = () => {
    if (state.fixedCostTypeId && state.year && state.year > 0) {
      const exist = getExistCost(state.fixedCostTypeId, state.year);
      const id = exist ? exist.id : undefined;
      dispatch(saveFixedCostForServiceBookAction({ serviceBookId: props.serviceBookId, fixedCost: { ...state, id, total: parseFloat(`${state.total}`) || 0.0 } }));
    }
  };

  const years = Object.keys([...new Array(5)]).map(row => (subYears(new Date(), parseInt(row))).getFullYear());

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h6">{ props.cost ? t('Edit cost for year') : t('Add cost for year') }</Typography>
      <FormControl
        sx={{ mt: 1, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('Year')}</InputLabel>
        <Select
          label={t('Year')}
          name="year"
          value={ getValueFromStateOrObject(state, props.cost, 'year') }
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {
            (years || []).map((year: number) => {
              return (<MenuItem key={year} value={ year }>{year}</MenuItem>);

            })
          }
        </Select>
      </FormControl>
      <FormControl
        sx={{ mt: 1, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('Cost type')}</InputLabel>
        <Select
          label={t('Cost type')}
          name="fixedCostTypeId"
          value={ getValueFromStateOrObject(state, props.cost, 'fixedCostTypeId') }
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {
            (props.costTypes || []).map((type: FixedCostType) => {
              return (<MenuItem key={`type_${type.id}`} value={ type.id }>{type.name}</MenuItem>);

            })
          }
        </Select>
      </FormControl>

      <Box sx={{ mt: 2 }}>
        <TextField name="total" label={t('Total for year')} value={ `${state.total || ''}` } onChange={ (e) => handleChange(e.target.name, e.target.value)}  />
      </Box>
      <Box sx={{ mt: 2 }}>
        {
          saving ? <CircularProgress /> : <Button variant="contained" onClick={submit}>{t('Save')}</Button>
        }
      </Box>
    </>
  );

}