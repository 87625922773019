import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import useLocales from '../../hooks/useLocales';
import { AddRounded } from '@mui/icons-material';
import { Button, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import SidePanel from '../sidepanel/SidePanel';
import AddEditReceipt from '../receipts/AddEditReceipt';
import AddEditAgreement from '../agreements/AddEditAgreement';
import AddEditServiceCase from '../serviceCases/AddEditServiceCase';
import { useAuth0 } from "@auth0/auth0-react";
import CustomerSelect from '../customer/CustomerSelect';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../styles/themeStylesSx';
import AddEditDocument from '../documents/AddEditDocument';
import { drawerWidth } from './SideNavigation';
import { useAppSelector } from '../../hooks';

const modalStyle = {
  ml: 2,
  mr: 2,
  mb: 2,
};

type AppBarType = {
  open: boolean;
  type?: string;
}

export default function MenuAppBar() {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useLocales();
  const [state, setState] = React.useState<AppBarType>({ open: false, type: '-1' });
  const { details: customer } = useAppSelector(store => store.customer);

  const handleChange = (e: SelectChangeEvent)  => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickLogout = () => {
    logout({ returnTo: 'https://www.kodinkanta.fi/palautesivu/' });
  };

  if (!customer) {
    return <Box></Box>;
  }

  return (
    <AppBar
        sx={{
          width: {
            lg: `calc(100% - ${drawerWidth}px)`
          },
          marginLeft: {
            lg: drawerWidth,
          },
          paddingLeft: {
            xs: '50px',
          },
        }}
        position="fixed">
      <Toolbar>
        <Box sx={{ width: '100%', display: 'flex', color: 'primary.contrastText', alignItems: 'center' }}>
          <SidePanel
            open={state.open}
            close={() => setState({ ...state, open: false }) }
            aria-labelledby="add-modal-title"
            aria-describedby="add-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography sx={{ mb: 2 }} id="add-modal-title" variant="h6" component="h2">
                {t('Add')}
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="add-type-label">{t('Type')}</InputLabel>
                <Select
                  labelId="add-type-label"
                  name="type"
                  value={state.type}
                  label={t('Type')}
                  onChange={handleChange}
                >
                  <MenuItem value={'-1'}>{t('Select')}</MenuItem>
                  <MenuItem value={'receipt'}>{t('Receipt')}</MenuItem>
                  <MenuItem value={'agreement'}>{t('Agreement')}</MenuItem>
                  {
                    customer && customer.serviceBooks && customer.serviceBooks.length > 0 &&
                      <MenuItem value={'serviceCase'}>{t('Service case')}</MenuItem>
                  }
                  <MenuItem value={'document'}>{t('Document')}</MenuItem>
                </Select>
              </FormControl>
              {
                state.type === 'receipt' && <AddEditReceipt source="topbar" cancel={() => setState({ ...state, open: false })} />
              }
              {
                state.type === 'agreement' && <AddEditAgreement cancel={() => setState({ ...state, open: false })} source="topbar" />
              }
              {
                state.type === 'serviceCase' && <AddEditServiceCase source="topbar" cancel={() => setState({ ...state, open: false })} />
              }
              {
                state.type === 'document' && <AddEditDocument cancel={() => setState({ ...state, open: false })} />
              }
            </Box>
          </SidePanel>

          <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <Box>
            {
            !customer.isActive ?
              <Box sx={{ fontSize: 15, pr: 2 }}>{t('Account is not active, enable subscription to continue usage')}</Box> :
                <Button
                  disabled={!customer.isActive}
                  endIcon={ <AddRounded /> }
                  variant="outlined"
                  color="inherit"
                  sx={{ mr: 2, borderColor: 'gray', textTransform: 'none', fontSize: { xs: '12px', md: '14px' } }}
                  size="large" onClick={ () => setState({ ...state, open: true }) }>
                  {t('Add')}
                </Button>
            }
            </Box>
            <Box sx={{ mr: 1}}>
              <CustomerSelect />
            </Box>
            <IconButton
              sx={{ color: colors.darkerGreen }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={ () => navigate('/profile')}>{t('My profile')}</MenuItem>
            <MenuItem onClick={ () => navigate('/profile/reminders')}>{t('Reminders')}</MenuItem>
            <MenuItem onClick={ () => navigate('/profile/customer')}>{t('Customer')}</MenuItem>
            <MenuItem onClick={clickLogout}>{t('Logout')}</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}