import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { getValueFromStateOrObject, getDateFromStateOrObject } from '../../helpers/helpers';
import { ReceiptType } from '../../types/receipts';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { uploadFilesAction, resetUploadedAttachmentsAction } from '../../redux/attachment/attachmentSlice';
import FileUpload from '../common/FileUpload';
import { DeleteRounded } from '@mui/icons-material';
import { AttachmentType } from '../../types/attachment';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addReceipt, updateReceipt} from '../../redux/receipts/receiptsSlice';
import { startOfDay } from 'date-fns';

type AddEditReceiptPropsType = {
  cancel: () => void;
  receipt?: Partial<ReceiptType>;
  source?: 'topbar';
}

export default function AddEditReceipt(props: AddEditReceiptPropsType) {
  const { cancel, receipt, source } = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<ReceiptType>>({});
  const { loading, uploaded, result: uploadResult } = useAppSelector(store => store.attachment);
  const { saving, saved, reload } = useAppSelector(store => store.receipts);
  const { details: customer } = useAppSelector(store => store.customer);
  const dispatch = useAppDispatch();

  const [attachments, setAttachments] = useState<Array<Partial<AttachmentType>>>(receipt && receipt.attachments ? receipt.attachments : []);
  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  const uploadFiles = (files: any) => {
    dispatch(uploadFilesAction(files));
  };

  useEffect(() => {
    if (uploaded && uploadResult) {
      setAttachments([...attachments, ...uploadResult]);
    }
  }, [uploaded, uploadResult]);

  useEffect(() => {
    if (saved) {
      setState({});
      setAttachments([]);
      dispatch(resetUploadedAttachmentsAction());
    }
    if (source && source === 'topbar' && saved && reload) {
      cancel();
    }
  }, [source, saved, reload, cancel]);

  const submit = () => {
    const data: any = { ...state, date: state.date || startOfDay(new Date()),  attachments: attachments && attachments.length > 0 ? attachments : undefined };
    if (receipt && receipt.id !== undefined) {
      dispatch(updateReceipt({ id: receipt.id, data }));
    }
    else if (data.description && data.total && customer) {
      dispatch(addReceipt({ customerId: customer.id, ...data }));
    }
  };

  return (
    <Box sx={{ mt: 2 }}>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={t('Date')}
          inputFormat="dd.MM.yyyy"
          value={ getDateFromStateOrObject(state, receipt, 'date', true) }
          onChange={ (value: Date | null) => handleChange('date', value) }
          renderInput={(params: any) => <TextField variant="outlined" {...params} />}
        />
      </LocalizationProvider>
      <TextField
        sx={{ mt: 1 }}
        label={t('Bought from')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, receipt, 'boughtFrom') }
        onChange={(e) => handleChange('boughtFrom', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Description')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, receipt, 'description') }
        onChange={(e) => handleChange('description', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={`${t('Total')} (€)`}
        type="number"
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, receipt, 'total') }
        onChange={(e) => handleChange('total', +e.target.value)}
      />
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">{t('Attachments')}</Typography>
      {
        loading ? <CircularProgress /> :
          <FileUpload onDrop={ files => uploadFiles(files) } />
      }
      {
        (attachments || []).map(attachment => {
          return (
            <Box sx={{ mt: 1, p: 1 }}>
              { attachment.id }: { attachment.filename } <IconButton onClick={() => setAttachments(attachments.filter(row => row.id !== attachment.id))}><DeleteRounded /></IconButton>
            </Box>
          );
        })
      }
      <Box sx={{ mt: 1 }}>
        <Button variant="outlined" onClick={cancel}>
          {t('Cancel')}
        </Button>
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick={submit}
            >
              {t('Submit')}
            </Button>
        }
      </Box>
    </Box>
  );
}