import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { getValueFromStateOrObject, getDateFromStateOrObject } from '../../helpers/helpers';
import { DocumentType } from '../../types/documents';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { uploadFilesAction, resetUploadedAttachmentsAction } from '../../redux/attachment/attachmentSlice';
import FileUpload from '../common/FileUpload';
import { DeleteRounded } from '@mui/icons-material';
import { AttachmentType } from '../../types/attachment';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addDocument, updateDocument} from '../../redux/documents/documentsSlice';
import { startOfDay } from 'date-fns';

type AddEditDocumentPropsType = {
  cancel: () => void;
  document?: Partial<DocumentType>;
  source?: 'topbar';
  serviceBookId?: number | undefined;
}

export default function AddEditDocument(props: AddEditDocumentPropsType) {
  const { cancel, document, source, serviceBookId } = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<DocumentType>>({});
  const { loading, uploaded, result: uploadResult } = useAppSelector(store => store.attachment);
  const { saving, saved, reload } = useAppSelector(store => store.documents);
  const { details: customer } = useAppSelector(store => store.customer);
  const dispatch = useAppDispatch();

  const [attachments, setAttachments] = useState<Array<Partial<AttachmentType>>>(document && document.attachments ? document.attachments : []);
  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  const uploadFiles = (files: any) => {
    dispatch(uploadFilesAction(files));
  };

  useEffect(() => {
    if (uploaded && uploadResult) {
      setAttachments([...attachments, ...uploadResult]);
    }
  }, [uploaded, uploadResult]);

  useEffect(() => {
    if (saved) {
      setState({});
      setAttachments([]);
      dispatch(resetUploadedAttachmentsAction());
    }
    if (source && source === 'topbar' && saved && reload) {
      setState({});
      cancel();
    }
  }, [source, saved, reload, cancel]);

  const submit = () => {
    const data: any = {
      ...state,
      date: state.date || startOfDay(new Date()),
      attachments: attachments && attachments.length > 0 ? attachments : undefined,
      serviceBookId,
    };
    if (document && document.id !== undefined) {
      dispatch(updateDocument({ id: document.id, data }));
    }
    else if (data.description && customer) {
      dispatch(addDocument({ customerId: customer.id, ...data }));
    }
  };

  return (
    <Box sx={{ mt: 2 }}>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={t('Date')}
          inputFormat="dd.MM.yyyy"
          value={ getDateFromStateOrObject(state, document, 'date', true) }
          onChange={ (value: Date | null) => handleChange('date', value) }
          renderInput={(params: any) => <TextField variant="outlined" {...params} />}
        />
      </LocalizationProvider>

      <TextField
        sx={{ mt: 1 }}
        label={t('Description')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, document, 'description') }
        onChange={(e) => handleChange('description', e.target.value)}
      />
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">{t('Attachments')}</Typography>
      {
        loading ? <CircularProgress /> :
          <FileUpload onDrop={ files => uploadFiles(files) } />
      }
      {
        (attachments || []).map(attachment => {
          return (
            <Box key={`att_${attachment.id}`} sx={{ mt: 1, p: 1 }}>
              { attachment.id }: { attachment.filename } <IconButton onClick={() => setAttachments(attachments.filter(row => row.id !== attachment.id))}><DeleteRounded /></IconButton>
            </Box>
          );
        })
      }
      <Box sx={{ mt: 3 }}>
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick={submit}
            >
              {t('Submit')}
            </Button>
        }
      </Box>
    </Box>
  );
}