import { Container, Box, Card,  } from "@mui/material";
import { useEffect } from "react";
import ValidateAccessToFeature from '../components/authorization/ValidateAccessToFeature';
import ContextualHelp from '../components/help/ContextualHelp';
import SkeletonRows from "../components/layout/SkeletonRows";
import ServiceBookFixedCosts from "../components/serviceBooks/ServiceBookFixedCosts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { layoutFlexRow } from "../layout";
import { loadCostsForServiceBookAction } from "../redux/fixedCosts/fixedCostsSlice";

export default function FixedCostsContainer() {
  const { details, reload } = useAppSelector(store => store.customer);
  const { withCosts, loadingWithCosts, loadedWithCosts, errors } = useAppSelector(store => store.fixedCosts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    details?.serviceBooks.map(book => {
      const loading = loadingWithCosts.find(loadedFor => loadedFor === book.id);
      const loaded = loadedWithCosts.find(loadedFor => loadedFor === book.id);
      const error = errors.find(row => row === book.id);
      if (!loading && !loaded && !error) {
        dispatch(loadCostsForServiceBookAction({ serviceBookId: book.id }));
      }
    });
  }, [details, loadedWithCosts, loadingWithCosts]);

  return (
    <ValidateAccessToFeature feature='fixed-costs'>
      <Container>
        <Box sx={{ width: '100%', display: 'block' }}>
          <ContextualHelp variant='fixedCosts' styles={{ width: '50px', marginLeft: 'auto' }} />
        </Box>
        <Box sx={{
            ...layoutFlexRow,
          }}>
            {
              (details?.serviceBooks || []).map(serviceBook => {
                const loading = loadingWithCosts.includes(serviceBook.id);
                if (loading) {
                  return <Card sx={{
                    width: '100%',
                    p: { xs: 0.2, md: 2 }, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 2 }, mt: 2,
                  }}><SkeletonRows rows={3} /></Card>;
                }
                const costs = withCosts.filter(row => row.serviceBookId === serviceBook.id);
                return <ServiceBookFixedCosts key={`book_${serviceBook.id}`} costs={costs} serviceBook={ serviceBook } />;
              })
            }
          </Box>
      </Container>
    </ValidateAccessToFeature>
  );
}