import {
  AddRounded,
  ArrowRightAltRounded,
  PersonRounded, HouseRounded, DirectionsCarRounded
} from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';

import HouseLogo from '../../../icons/house.png';
import PersonLogo from '../../../icons/dude.png';
import CarLogo from '../../../icons/car.png';
import AgreementLogo from '../../../icons/agreement.png';
import EuroLogo from '../../../icons/euro.png';
import DocumentLogo from '../../../icons/document.png';
import PercentLogo from '../../../icons/100-percent-icon.png';
import StocksLogo from '../../../icons/kurssi.png';
import StarLogo from '../../../icons/star.png';
import StarsLogo from '../../../icons/stars.png';
import DiamondLogo from '../../../icons/diamond.png';

type Props = {
  type: string;
  sx?: SxProps;
};

export const CustomIcon = (props: Props) => {
  const commonProps = { width: '100%'   };
  const sxProps: SxProps = props.sx ? props.sx : { width: '60%', marginLeft: 'auto', marginRight: 'auto', '& > img': { marginTop: '0px' }};

  switch(props.type) {
    case 'house':
      return <Box sx={sxProps}><img {...commonProps} src={HouseLogo} alt="house" /></Box>;
    case 'person':
      return <Box sx={sxProps}><img {...commonProps} src={PersonLogo} alt="person" /></Box>;
    case 'car':
      return <Box sx={sxProps}><img {...commonProps} src={CarLogo} alt="car" /></Box>;
    case 'agreement':
      return <Box sx={sxProps}><img {...commonProps} src={AgreementLogo} alt="agreement" /></Box>;
    case 'receipt':
      return <Box sx={sxProps}><img {...commonProps} src={EuroLogo} alt="receipts" /></Box>;
    case 'document':
      return <Box sx={sxProps}><img {...commonProps} src={DocumentLogo} alt="receipts" /></Box>;
    case 'star':
      return <Box sx={sxProps}><img {...commonProps} src={StarLogo} alt="star" /></Box>;
    case 'stars':
      return <Box sx={sxProps}><img {...commonProps} src={StarsLogo} alt="stars" /></Box>;
    case 'diamond':
      return <Box sx={sxProps}><img {...commonProps} src={DiamondLogo} alt="diamond" /></Box>;
    case 'logo':
      return <Box sx={sxProps}><img { ...commonProps } src='/static/media/KodinKanta-logo-sloganiton-RGB1.73edf1019fca7ec33421a94b6cb2b341.svg' alt='logo' /></Box>;
    default:
      return <PersonRounded sx={{ width: '100%', height: '100%' }} />;
  }
};