import { EditRounded, KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, IconButton, List, ListItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { clearFixedCostsState, deleteFixedCostTypeAction, getFixedCostTypesAction, saveMultipleFixedCostTypesAction } from '../../redux/fixedCosts/fixedCostsSlice';
import SkeletonRows from '../layout/SkeletonRows';
import SidePanel from '../sidepanel/SidePanel';
import { AddRounded } from '@mui/icons-material';
import AddEditCostType from '../costTypes/addEditCostType';
import { FixedCostType } from '../../types/costs';
import { colors } from '../../../styles/themeStylesSx';
import ConfirmableButton from '../buttons/ConfirmableButton';

const typePresets = {
  building: ['Kiinteistövero', 'Vesi', 'Sähkö', 'Lämmitys', 'Tonttivuokra', 'Jätehuolto'],
  vehicle: [],
  other: [],
};

export default function ServiceBookCostTypes() {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { serviceBook } = useServiceBookOutletContext();
  const dispatch = useAppDispatch();
  const { costTypes, loading, loaded, saved, savedMany } = useAppSelector(store => store.fixedCosts);
  const [state, setState] = useState<{ new?: boolean, edit?: number, multiple?: boolean }>({ new: false, edit: undefined });
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  useEffect(() => {
    if (!loaded) {
      dispatch(getFixedCostTypesAction(serviceBook.id));
    }
  }, [loaded]);

  const deleteFixedCostType = (id?: number) => {
    if (!id) {
      return;
    }
    dispatch(deleteFixedCostTypeAction({ serviceBookId: serviceBook.id, id: id }));
    dispatch(clearFixedCostsState());
  };

  const selectType = (type: string, selected: boolean) => {
    const newList = selectedTypes.filter((row: string) => row !== type);
    if (selected) {
      newList.push(type);
    }
    setSelectedTypes(newList);
  };

  const submitMultiSelect = () => {
    dispatch(saveMultipleFixedCostTypesAction({ names: selectedTypes, serviceBookId: serviceBook.id }));
    dispatch(clearFixedCostsState());
  };

  useEffect(() => {
    if (savedMany || saved) {
      setState({ ...state, multiple: false, new: false });
    }
  }, [savedMany, saved]);

  const types = typePresets[serviceBook.type] as string[];
  const notSelectedTypes = types.filter(row => !costTypes.find(cost => cost.name === row));

  return (
    <Container>
      <SidePanel open={ state.new === true } close={() => setState({ ...state, new: false })}>
        <Typography variant="h6">{t('Add new')}</Typography>
        <AddEditCostType
          serviceBook={serviceBook}
          new={true}
          cancel={() => setState({ ...state, new: false })}
        />
      </SidePanel>

      <SidePanel open={ state.edit != undefined} close={() => setState({ ...state, edit: undefined })}>
        <Typography variant="h6">{t('Edit cost type')}</Typography>
        <AddEditCostType
          serviceBook={serviceBook}
          cost={state.edit ? costTypes.find(row => row.id === state.edit) : undefined } new={false}
          cancel={() => setState({ ...state, edit: undefined })}
        />
      </SidePanel>

      <SidePanel open={ state.multiple === true } close={ () => setState({ ...state, multiple: false })}>
        <Typography variant="h6">{t('Add multiple')}</Typography>
        <Box sx={{ mt: 1, mb: 1 }}>
          <FormControl>
            {
              notSelectedTypes.map((row: string) =>
                <FormControlLabel
                  key={`row_${row}`}
                  label={row}
                  control={<Checkbox onChange={(e: any) => selectType(row, e.target.checked) } checked={ selectedTypes.includes(row) } />} />
              )
            }
          </FormControl>
        </Box>
        <Button variant="outlined" color="secondary" disabled={ selectedTypes.length === 0 } onClick={ () => submitMultiSelect() }>{t('Save')}</Button>
      </SidePanel>

      <Button
        color="secondary"
        sx={{ fontWeight: 600 }}
        onClick={() => navigate(`/service-books/${serviceBook.id}/details`)} startIcon={<KeyboardArrowLeft />}
      >
        {t('Back')}
      </Button>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ borderBottom: 'thin solid black', mb: 2,  }}>{t('Cost types')}</Typography>
      </Box>
      { loading && <SkeletonRows rows={5} /> }
      <List>
        { (costTypes || []).map((row: FixedCostType, idx: number) =>
          <ListItem key={`type_${idx}`} sx={{ pt: 2, pb: 2, backgroundColor: idx % 2 ? colors.backgroundGreen : '#fff', borderRadius: 1 }}>
            { row.name }
            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
              <IconButton sx={{p:0}} onClick={ () => setState({ ...state, new: false, edit: row.id })}><EditRounded /></IconButton>
              <ConfirmableButton onSubmit={() => deleteFixedCostType(row.id)} />
              </Box>
          </ListItem>)}
      </List>
      {
        loaded && (costTypes || []).length === 0 && <Box sx={{ mb: 2 }}>{t('No cost types found')}</Box>
      }
      <Box>
        <Button startIcon={<AddRounded />} sx={{ mt: 2 }} variant="outlined" color="secondary" onClick={ () => setState({ ...state, new: true}) }>
          {t('Add new cost type')}
        </Button>
      </Box>
      {
        notSelectedTypes && notSelectedTypes.length > 0 ?
        <Box>
          <Button startIcon={<AddRounded />} sx={{ mt: 2 }} variant="outlined" color="secondary" onClick={ () => setState({ ...state, multiple: true}) }>
            {t('Add multiple')}
          </Button>
        </Box> : <></>
      }
    </Container>
  );
}