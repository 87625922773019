import { Button, Container, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Box, Typography, Paper } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import SidePanel from '../sidepanel/SidePanel';
import { useState } from 'react';
import { AddRounded, VisibilityRounded } from '@mui/icons-material';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import { parseAndFormatDate, sortArrayByDate } from '../../helpers/helpers';
import ServiceCaseComponent from '../serviceCases/ServiceCaseComponent';
import AddEditServiceCase from '../serviceCases/AddEditServiceCase';
import ListAttachments from '../attachments/ListAttachments';
import AttachmentsIcon from '../attachments/AttachmentsIcon';
import { ServiceCaseType } from '../../types/serviceBook';
import { colors } from '../../../styles/themeStylesSx';
import { useAppSelector } from '../../hooks';

type HistoryState = {
  open: boolean;
  add?: boolean;
  serviceCaseId?: number;
}

export default function ServiceBookHistory() {
  const { t } = useLocales();
  const [state, setState] = useState<HistoryState>({ open: false, add: false });
  const { serviceBook } = useServiceBookOutletContext();
  const { details: customer } = useAppSelector(store => store.customer);
  const serviceCases = serviceBook.serviceCases;
  const sortedCases = sortArrayByDate(serviceCases || [], 'date');
  return (
    <Container>
      <SidePanel
        open={state.add === true}
        close={ () => setState({ ...state, add: false })}
      >
        <AddEditServiceCase
          cancel={() => setState({ open: false })}
          serviceBook={serviceBook}
        />
      </SidePanel>
      <SidePanel
        open={state.open === true}
        close={() => setState({ open: false })}
      >
        <Box sx={{ ml: 0 }}>
          <ServiceCaseComponent
            cancel={() => setState({ open: false })}
            serviceBook={serviceBook}
            serviceCase={ state.serviceCaseId ? serviceCases?.find(row => row.id === state.serviceCaseId) : undefined}
          />
        </Box>
      </SidePanel>

      <Typography variant="h6" sx={{ borderBottom: 'thin solid black', mb: 2,  }}>{t('Service history')}</Typography>
      {
        <Box sx={{ display: { xs: 'block', md: 'none' }}}>
          {
            (sortedCases || []).map((row: ServiceCaseType, idx: number) => {
              return (
                <Paper sx={{ p: 1, mb: 1, backgroundColor: idx % 2 ? colors.backgroundGreen : '#fff' }} key={`case_${row.id}`}>
                  <Box sx={{ float: 'right'}}>
                    <IconButton onClick={() => setState({ ...state, open: true, serviceCaseId: row.id })}><VisibilityRounded /></IconButton>
                  </Box>
                  <Box>{ row.date ? parseAndFormatDate(row.date) : ''}</Box>
                  <Box>
                      { row.servicePlanItemId ? serviceBook.servicePlanItems.find(item => item.id === row.servicePlanItemId)?.name : t('Custom service')}
                      <br/><small>{ row.comment && row.comment.length > 100 ? row.comment.substring(0,100) + '...' : row.comment }</small>
                  </Box>
                  {
                    row.attachments &&
                      <Box>
                        <AttachmentsIcon attachments={row.attachments} />
                      </Box>
                  }
                </Paper>
              );
            })
          }
        </Box>
      }
      <Table sx={{ display: { xs: 'none', md: 'inline-table', color: 'secondary.main' }}}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{t('Done date')}</TableCell>
            <TableCell>{t('Service case')}</TableCell>
            <TableCell>{t('Attachments')}</TableCell>
            <TableCell>{t('Price')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            sortedCases.map((row: ServiceCaseType, idx: number) => {
              const dateValue = row.date ? parseAndFormatDate(row.date) : '';
              const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow key={`${row.id}_row`} sx={{ backgroundColor: backgroundColor, "& td": { border: 0 },}}>
                  <TableCell sx={{
                    backgroundColor: backgroundColor,
                    borderTopLeftRadius: 15, borderBottomLeftRadius: 15,
                    }}>{ dateValue }</TableCell>
                  <TableCell>
                    { row.servicePlanItemId ? serviceBook.servicePlanItems.find(item => item.id === row.servicePlanItemId)?.name : t('Custom service')}
                    <br/><small>{ row.comment && row.comment.length > 100 ? row.comment.substring(0,100) + '...' : row.comment }</small>
                  </TableCell>
                  <TableCell><AttachmentsIcon attachments={row.attachments} /></TableCell>
                  <TableCell>{row.cost ? `${row.cost} €` : '-'}</TableCell>
                  <TableCell sx={{
                    backgroundColor: backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                    <IconButton sx={{p: 0}} onClick={() => setState({ ...state, open: true, serviceCaseId: row.id })}><VisibilityRounded /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }

        </TableBody>
      </Table>
      <Box sx={{ mt: 2, width: '250px', marginLeft: 'auto' }}>
        <Button
          disabled={!customer?.isActive}
          color="secondary"
          onClick={() => setState({ ...state, add: true })} startIcon={<AddRounded />}
        >
          {t('Add new service case')}
        </Button>
      </Box>
    </Container>
  );
}
