import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { headerStyles, simpleContainerPaperStyle } from '../../styles/themeStylesSx';
import ValidateAccessToFeature from '../components/authorization/ValidateAccessToFeature';
import ContextualHelp from '../components/help/ContextualHelp';
import useLocales from '../hooks/useLocales';

export default function ReceiptsContainer() {
  const { t } = useLocales();
  return (
    <ValidateAccessToFeature feature='receipts'>
      <Container>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={ headerStyles }>{t('Warranty receipts')}</Typography>
          <ContextualHelp styles={{ marginLeft: 'auto' }} variant='receipts' />
        </Box>
        <Paper sx={ simpleContainerPaperStyle }>
            <Outlet />
        </Paper>
      </Container>
    </ValidateAccessToFeature>
  );
}