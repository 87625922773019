import { Box, Container, Typography } from '@mui/material';
import { getCurrentSeason } from '../../helpers/helpers';
import useLocales from '../../hooks/useLocales';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { getServiceCaseCalendarForSeason } from '../../redux/serviceBooks/serviceBookSlice';
import SkeletonRows from '../layout/SkeletonRows';
import ServiceBookCalendarSeasonItems from './ServiceBookCalendarSeasonItems';

export default function ServiceBookStatus() {
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { serviceBook } = useServiceBookOutletContext();
  const { calendar, loadingCalendar } = useAppSelector(store => store.serviceBook);

  const season = getCurrentSeason();
  const year = (new Date()).getFullYear();
  const items = calendar.find(row => row.datakey === `${year}-${season}-${serviceBook.id}`);
  useEffect(() => {
    if (!items) {
      dispatch(getServiceCaseCalendarForSeason({ serviceBookId: serviceBook.id, season, year }));
    }
  }, [season, year, items]);

  if (!serviceBook) {
    return <Box>{t('Not found')}</Box>;
  }

  if (loadingCalendar) {
    return <SkeletonRows rows={3} />;
  }

  return (
    <Container>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ borderBottom: 'thin solid black' }}>{t('Upcoming cases')} / {t(`Season_${season}`)}</Typography>
        {
          items && items.data &&
            <ServiceBookCalendarSeasonItems
              seasonItems={items.data}
              serviceBook={serviceBook}
              season={season}
              year={year}
            />
        }
      </Box>
    </Container>
  );
}