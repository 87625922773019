import { AutoAwesomeRounded, CheckRounded, DiamondRounded, StarOutlineRounded, StarRounded } from '@mui/icons-material';
import { Button, Link, TextField, Typography, Box, CircularProgress, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { enableSubscriptionAction } from '../../redux/customer/customerSlice';
import { CustomIcon } from '../icons/CustomIcon';

const iconStyle: SxProps = {
  fontSize: {
    md: 125,
    xs: 50,
  }
};

const subscriptions = [
  {
    icon: 'logo',
    name: 'Subscription_limited',
    planName: 'basic',
    price: '5.90',
    features: [
      'Service book',
      'Documents',
    ]
  },
  {
    icon: 'logo',
    name: 'Subscription_full service',
    planName: 'premium',
    price: '9.90',
    features: [
      'All limited features',
      'plus-divider',
      'SMS reminders',
      'Warranty receipts',
      'Agreements'
    ]
  }
];

const subscriptionSx: SxProps = {
  cursor: 'pointer',
  p: { md: 2, lg: 1, xs: 1 },
  pt: { md: 2, xs: 2 },
  pb: { md: 2, xs: 2 },
  fontSize: { md: 20, lg: 20, xs: 15, },
  minWidth: '150px',
  textAlign: 'center',
};

const boxSx: SxProps = {
  pt: 1,
  pb: 1,
  pl: 0.5,
  pr: 0.5,
  overflow: 'hidden',
  border: 'thin solid',
  borderRadius: 1,
};

type Props = {
  cancel: () => void
};

const CustomerSubscription = (props: Props) => {
  const { t } = useLocales();
  const [state, setState] = useState<any>({ subscription: 'premium', checked: [] });
  const dispatch = useAppDispatch();
  const { details, updating, updated } = useAppSelector(store => store.customer);

  useEffect(() => {
    if (updated) {
      props.cancel();
    }
  }, [updated]);

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  if (!details) {
    return (<Box sx={{ p: 2, m: 2 }}>{t('No customer found')}</Box>);
  }

  const submit = () => {
    dispatch(enableSubscriptionAction({ ...state, subscriptionName: state.subscription, id: details.id }));
  };

  const updateCheckedOption = (name: string, checked: boolean) => {
    const current = state.checked.slice(0).filter((row: string) => row !== name);
    if (checked === true) {
      current.push(name);
    }
    setState({ ...state, checked: current });
  };

  return (
    <Box sx={{ m: { md: 0, xs: 0 }, mt: { md: 1, xs: 0, }, p: { md: 1, xs: 0 } }}>
      <Typography sx={{ mb: 2 }} color="secondary" variant="h6">{t('Select subscription')}</Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: {
          md: 'space-evenly', xs: 'space-around'
        },
        flexWrap: {
          md: 'wrap', xs: 'wrap'
        }
      }}>
        {
          subscriptions.map((subscription, idx) => {
            const isSelected = state.subscription === subscription.planName.toLowerCase();
            return (
              <Box
                key={idx}
                sx={{
                  color: 'secondary.main',
                  width: { sm: '50%', xs: '100%' },
                  display: 'flex',
                  minWidth: '200px',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{ ...subscriptionSx }}
                  onClick={ () => setState({ ...state, subscription: subscription.planName.toLowerCase() })}
                >
                  <Box
                    sx={{
                      ...boxSx,
                      backgroundColor: isSelected ? grey[300] : 'white'
                    }}
                  >
                    <CustomIcon type={subscription.icon} sx={{ width: { md: '75%', sx: '50%' }, marginLeft: 'auto', marginRight: 'auto' }}  />
                    <p>{t(subscription.name)}</p>
                    <p><b>{subscription.price}€</b>/kk</p>
                    <Box sx={{ fontSize: 15 }}>({ (+subscription.price * 12).toFixed(2) }€/vuosi)</Box>
                  </Box>
                  <Box sx={{ ...boxSx,
                    textAlign: 'left',
                    mt: 2,
                    height: '170px',
                    fontSize: { md: 15, xs: 15 },
                  }}>
                    {
                      subscription.features.map((feature, idx) => {
                        if (feature === 'plus-divider') {
                          return <Box sx={{ marginLeft: '40%', fontSize: 25 }}> + </Box>;
                        }
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            key={`feature_${feature}_${idx}`}
                          >
                            <CheckRounded sx={{ color: 'secondary.main' }} /> {t(feature)}
                          </Box>
                        );
                      })
                    }
                  </Box>
                </Box>
              </Box>
            );
          })
        }
      </Box>
      <Box sx={{ mt: 2, mb: 2, fontSize: 18, width: '100%', textAlign: 'center', }}>
        <Link target="_blank" href="https://kodinkanta.fi/tilaa">{t('Read more about pricing and features from here')}</Link>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ mb: 2 }} color="secondary" variant="h6">{t('Check the contact details')}</Typography>
        <TextField
          sx={{ mt: 2 }}
          label={t('Name')}
          variant="outlined"
          fullWidth
          value={ getValueFromStateOrObject(state, details, 'name') }
          onChange={(e) => handleChange('name', e.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          label={t('Phone')}
          variant="outlined"
          fullWidth
          value={ getValueFromStateOrObject(state, details, 'phone') }
          onChange={(e) => handleChange('phone', e.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          label={t('Invoicing email')}
          variant="outlined"
          fullWidth
          value={ getValueFromStateOrObject(state, details, 'email') }
          onChange={(e) => handleChange('email', e.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          label={t('Address')}
          variant="outlined"
          fullWidth
          value={ getValueFromStateOrObject(state, details, 'address') }
          onChange={(e) => handleChange('address', e.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          label={t('Postal code')}
          variant="outlined"
          fullWidth
          value={ getValueFromStateOrObject(state, details, 'postalCode') }
          onChange={(e) => handleChange('postalCode', e.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          label={t('City')}
          variant="outlined"
          fullWidth
          value={ getValueFromStateOrObject(state, details, 'city') }
          onChange={(e) => handleChange('city', e.target.value)}
        />
      </Box>
      <Box sx={{ mt: 2, mb: 1 }}>
        <Box sx={{ fontSize: 16 }}>
          {t('Submitting the details you agree on')}
        </Box>
        <FormGroup>
          <FormControlLabel
            label={<Link target="_blank" href='https://www.kodinkanta.fi/kodinkanta-yleiset-sopimusehdot/'>{t('Kodinkanta sopimusehdot')}</Link>}
            control={
              <Checkbox
                checked={state.checked.includes('agreement')}
                onChange={(event) => updateCheckedOption('agreement', event.target.checked)}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={<Box><Link target="_blank" href='https://www.kodinkanta.fi/kodinkanta-lisapalveluehdot/'>{t('Lisäpalveluehdot')}</Link> ja </Box>}
            control={
              <Checkbox
                checked={state.checked.includes('additionalAgreement')}
                onChange={(event) => updateCheckedOption('additionalAgreement', event.target.checked)}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={<Box>sekä vahvistat lukeneesi <Link target="_blank" href='https://www.kodinkanta.fi/tietosuojaseloste/ '>{t('Tietosuojaselosteen')}</Link></Box>}
            control={
              <Checkbox
                checked={state.checked.includes('privacyNote')}
                onChange={(event) => updateCheckedOption('privacyNote', event.target.checked)}
                color="primary"
              />
            }
          />
        </FormGroup>
      </Box>
      {
        updating ? <CircularProgress /> :

          <Box
            sx={{ mt: 2, ml: { md: 2, xs: 0 } }}
          >
            <Button sx={{ mr: 2 }} onClick={props.cancel}>{t('Cancel')}</Button>
            <Button
              disabled={ !state.checked.includes('privacyNote') || !state.checked.includes('additionalAgreement') || !state.checked.includes('agreement')}
              variant="contained"
              onClick={submit}
            >
              {t('Submit subscription')}
            </Button>
          </Box>
      }
    </Box>
  );
};

export default CustomerSubscription;