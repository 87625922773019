import { EditRounded } from '@mui/icons-material';
import { Button, Container, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import { useNavigate} from 'react-router-dom';

import useLocales from '../../hooks/useLocales';
import SidePanel from '../sidepanel/SidePanel';
import AddEditServiceBook from './AddEditServiceBook';
import ConfirmableButton from '../buttons/ConfirmableButton';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { clearAddedOrDeletedAction, clearServiceBookState, deleteServiceBookAction } from '../../redux/serviceBooks/serviceBookSlice';
import { reloadCustomerAction } from '../../redux/customer/customerSlice';

const rowStyle: SxProps = {
  pb: 1,
  lineHeight: 1.5
};

const mainStyle: SxProps = {
  ...rowStyle,
  fontWeight: 'bold',
};

const allProperties: any = ['centralVacuumCleaner', 'fireplace', 'roofWindow', 'electricMeltingSystem', 'solarPanels', 'electricSauna', 'woodSauna', 'airCondition',
'waterAirHeatPump', 'exhaustAirHeatPump', 'thermalHeating', 'districtHeating',
'woodHeating', 'oilHeating', 'pelletHeating', 'electricHeating','waterCirculatingFloorHeating', 'waterCirculatingRadiatorHeating',
'electricFloorHeating', 'electricRadiatorHeating','mechanicalVentilation', 'naturalVentilation'];

export default function ServiceBookDetails() {
  const { t } = useLocales();
  const [state, setState] = useState({ edit: false });
  const { serviceBook } = useServiceBookOutletContext();
  const { deleting, deleted } = useAppSelector(store => store.serviceBook);
  const dispatch = useAppDispatch();

  const cancel = () => setState({ edit: false });

  const navigate = useNavigate();

  useEffect(() => {
    if (deleted) {
      dispatch(clearAddedOrDeletedAction());
      dispatch(clearServiceBookState());
      dispatch(reloadCustomerAction());
      navigate(`/service-books`);
    }
  }, [deleted]);

  const showServicePlan = () => {
    navigate(`/service-books/${serviceBook.id}/service-plan`);
  };
  const deleteBook = (id: number) => {
    dispatch(deleteServiceBookAction(id));
  };

  const buildingDetails: any = serviceBook?.buildingDetails;
  return (
    <Container>
      <SidePanel
        open={ state.edit }
        close={cancel}
      >
        <AddEditServiceBook
          new ={ false }
          serviceBook={ serviceBook }
          close={cancel}
        />
      </SidePanel>
      <Box sx={{ p: 1, pt: 0 }}>

        <Typography variant="h6" sx={{ borderBottom: 'thin solid black', mb: 2,  display: 'flex' }}>
          {t('Details')}
          <Box sx={{ mt: 1, ml: 'auto', display: 'flex', pb: 2 }}>
            <Button color="secondary" onClick={ () => setState({ edit: true })} startIcon={<EditRounded />} sx={{ mr: 2 }}>{t('Edit')}</Button>
            <ConfirmableButton buttonText ={t('Delete')} showLoader={deleting} onSubmit={() => deleteBook(serviceBook.id) } />
          </Box>

        </Typography>


        <Box sx={{ ...mainStyle }}>
          {t('Name')}: { serviceBook.name }
        </Box>
        <Box sx={{ ...mainStyle }}>
          {t('Description')}: { serviceBook.description }
        </Box>
        <Box sx={{ ...mainStyle }}>
          {t('Type')}: { t(serviceBook.type) }
        </Box>
        {
          serviceBook.type === 'building' &&
            <>
              <Box sx={{ ...rowStyle }}>
                {t('Construction year')}: { buildingDetails?.constructionYear }
              </Box>
            <Box sx={{ ...rowStyle }}>
                {t('Address')}: { buildingDetails?.address }
              </Box>
              <Box sx={{ ...rowStyle }}>
                {t('Area')}: { buildingDetails?.area } m²
              </Box>
              <Box sx={{ ...rowStyle }}>
                {t('Total Area')}: { buildingDetails?.totalArea } m²
              </Box>
              <Box sx={{ ...rowStyle }}>
                {t('Structure')}: { t(buildingDetails?.structure) }
              </Box>
              <Box sx={{ ...rowStyle }}>
                <b>{t('Properties')}:</b>
                {
                  allProperties
                    .filter((key: string) => buildingDetails && Object.keys(buildingDetails).includes(key) && buildingDetails[key] && buildingDetails[key] === true)
                    .map((key: string) => <p key={key}>{t(key)}</p>)
                }
              </Box>
            </>
        }

        {
          serviceBook.type === 'vehicle' &&
          <>
            <Box sx={{ ...rowStyle }}>
              {t('Manufactured year')}: { serviceBook.vehicleDetails?.manufacturedYear }
            </Box>
            <Box sx={{ ...rowStyle }}>
              {t('License plate')}: { serviceBook.vehicleDetails?.licensePlate }
            </Box>
          </>
        }
        <Box sx={{ width: '100%' }}>
          <Box sx={{ mt: 2, ml: 'auto' }}>
            <Button variant="outlined" color="secondary" onClick={ showServicePlan }>{t('Service plan')}</Button>
          </Box>
        </Box>
        {
          /*
          Disabled for now
          <Box sx={{ mt: 2, ml: 'auto' }}>
          <Button variant="outlined" color="secondary" onClick={ () => navigate(`/service-books/${serviceBook.id}/cost-types`) }>{t('Cost types')}</Button>
          </Box>
          */
        }

      </Box>
    </Container>
  );
}