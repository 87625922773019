import { ArrowBackRounded, EditRounded, PictureAsPdfRounded } from '@mui/icons-material';
import { Button, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { DocumentType } from '../../types/documents';
import AddEditDocument from './AddEditDocument';
import SidePanel from '../sidepanel/SidePanel';
import { deleteDocumentAction, getDocuments, updateDocument } from '../../redux/documents/documentsSlice';
import { valueAsDate } from '../../helpers/helpers';
import { loadAttachmentAction, resetLoadedAttachment } from '../../redux/attachment/attachmentSlice';
import { AttachmentType } from '../../types/attachment';
import { sidePanelEditButtonBox } from '../../../styles/themeStylesSx';
import ConfirmableButton from '../buttons/ConfirmableButton';

type DocumentComponentType = {
  document?: DocumentType,
  close: () => void,
};

export default function DocumentComponent(props: DocumentComponentType) {
  const { document, close } = props;
  const { t } = useLocales();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({ edit: false });

  const { loadedAttachment, attachment } = useAppSelector(store => store.attachment);
  const { reload, saving, saved, loading, loaded, deleting, deleted } = useAppSelector(store => store.documents);

  useEffect(() => {
    if (saved && loaded) {
      setState({ edit: false });
      close();
    }
  }, [saved, loaded]);

  useEffect(() => {
    if (loadedAttachment && attachment) {
      window.open(attachment.link);
      dispatch(resetLoadedAttachment());
    }
  }, [loadedAttachment, attachment]);

  useEffect(() => {
    if (deleted) {
      close();
    }
  }, [deleted]);

  if (!document) {
    return (
      <Box>
        {t('Not found')}
      </Box>
    );
  }

  const load = (id: number | undefined) => {
    if (!id) {
      return;
    }
    dispatch(loadAttachmentAction(id));
  };

  const dateParsed = valueAsDate(document.date);
  if (state.edit) {
    return (
      <Box>
        <Typography sx={{ p: 2 }} variant="h6">{t('Edit document')}</Typography>
        <AddEditDocument document={document} cancel={ () => setState({ edit: false }) } />
      </Box>
    );
  }
  return (
    <Box>
      <Box sx={{ p: 1 }}>
      {t('Document')}: { document.id }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Description')}: { document.description }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Date')}: { dateParsed ? format(dateParsed, 'dd.MM.yyyy') : '-' }
      </Box>
      {
        (document.attachments || []).map((attachment: Partial<AttachmentType>, idx: number) =>
          <Box key={`Attachment_${idx}`} sx={{ mt: 1 }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={ () => load(attachment.id)}
              startIcon={<PictureAsPdfRounded />}
            >
              { attachment.filename}
            </Button>
          </Box>
        )
      }
      <Box sx={ sidePanelEditButtonBox }>
        <Button color="secondary" onClick={() => setState({ edit: true })} startIcon={<EditRounded />}>{t('Edit')}</Button>
      </Box>
      {
        document && document.id &&
          <Box sx={{ mt: 2 }}>
            <ConfirmableButton
              showLoader={ deleting }
              onSubmit={ () => dispatch(deleteDocumentAction(document.id as number)) }
              buttonText={ t('Delete') }
            />
          </Box>
      }
    </Box>
  );
}