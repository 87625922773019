import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { RootState, AppThunk } from '../../store';
import { ReceiptType } from '../../types/receipts';
import { getToken } from '../profile/profileSlice';

export interface ReceiptsState {
  list: ReceiptType[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
  saving: boolean;
  saved: boolean;
  reload: boolean;
  deleting: boolean;
  deleted: boolean;
}

const fakeData = [{ id: 1, description: 'Kuitti 1', total: 10, date: new Date() }];

const initialState: ReceiptsState = {
  list: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
  reload: false,
  deleting: false,
  deleted: false,
};


export const getReceipts = createAsyncThunk<ReceiptType[]>('receipts/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/receipt', thunkApi, method: 'GET' });
});

export const addReceipt = createAsyncThunk<void, Partial<ReceiptType>>('receipts/add', async (data: Partial<ReceiptType>, thunkApi: any) => {
  return await apiRequest({ path: '/receipt', data, thunkApi, method: 'POST' });
});

export const updateReceipt = createAsyncThunk('receipts/update', async (params: { id: number, data: Partial<ReceiptType>}, thunkApi: any) => {
  return await apiRequest({ path: `/receipt/${params.id}`, data: params.data, thunkApi, method: 'PATCH' });
});

export const deleteReceiptAction = createAsyncThunk('receipts/delete', async (id: number, thunkApi: any) => {
  return await apiRequest({ path: `/receipt/${id}`, thunkApi, method: 'DELETE' });
});

export const receiptsSlice = createSlice({
  name: 'receipts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      state.list = [];
      state.loaded = false;
      state.reload = true;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: (builder) => {
    builder.addCase(getReceipts.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
    });

    builder.addCase(getReceipts.fulfilled, (state, action: PayloadAction<ReceiptType[]>) => {
      state.loading = false;
      state.loaded = true;
      state.list = action.payload;
    });

    builder.addCase(getReceipts.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error getting receipts', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    });

    builder.addCase(addReceipt.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(addReceipt.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(addReceipt.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error adding receipt', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(updateReceipt.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(updateReceipt.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(updateReceipt.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating receipt', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(deleteReceiptAction.pending, (state) => {
      state.deleting = true;
      state.deleted = false;
    });
    builder.addCase(deleteReceiptAction.fulfilled, (state) => {
      state.deleting = false;
      state.deleted = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(deleteReceiptAction.rejected, (state) => {
      state.deleting = false;
      state.deleted = false;
    });
  }
});

export const selectReceipts = (state: RootState) => state.receipts.list;

export const { clear: clearReceiptState } = receiptsSlice.actions;

export default receiptsSlice.reducer;
