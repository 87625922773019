import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { AttachmentLink } from '../../types/attachment';

export type AttachmentState = {
  loading: boolean;
  loadingAttachment: boolean;
  loadedAttachment: boolean;
  attachment: AttachmentLink | null;
  uploaded: boolean;
  error: string | null;
  result: any;
}

const initialState: AttachmentState = {
  loading: false,
  uploaded: false,
  error: null,
  result: null,
  loadingAttachment: false,
  loadedAttachment: false,
  attachment: null,
};


export const uploadFilesAction = createAsyncThunk('attachment/upload', async (files: any, thunkApi: any) => {
  return await apiRequest({ path: '/attachment/upload', method: 'POST', files, thunkApi});
});

export const loadAttachmentAction = createAsyncThunk('attachment/get', async (id: number, thunkApi: any) => {
  return await apiRequest({ path: `/attachment/load/${id}`, method: 'GET', thunkApi });
});

export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    resetLoaded: (state) => {
      state.loadedAttachment = false;
      state.loadingAttachment = false;
    },
    resetUploadedAttachmentsAction: (state) => {
      state.uploaded = false;
      state.result = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFilesAction.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.uploaded = false;
    });
    builder.addCase(uploadFilesAction.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.uploaded = true;
      state.result = action.payload;
    });
    builder.addCase(uploadFilesAction.rejected, (state, action: any) => {
      state.loading = false;
      state.uploaded = false;
      state.error = action.payload;
    });
    builder.addCase(loadAttachmentAction.pending, (state, action) => {
      const id = action.meta.arg;
      state.loadingAttachment = true;
      state.loadedAttachment = false;
    });
    builder.addCase(loadAttachmentAction.fulfilled, (state, action: PayloadAction<AttachmentLink>) => {
      const id = action.payload.id;
      if (!id) {
        state.loadingAttachment = false;
        state.loadedAttachment = false;
        return;
      }
      state.loadingAttachment = false;
      state.loadedAttachment = true;
      state.attachment = action.payload;
    });

    builder.addCase(loadAttachmentAction.rejected, (state) => {
      state.loadingAttachment = false;
      state.loadedAttachment = false;
    });
  }
});

export const { resetLoaded: resetLoadedAttachment, resetUploadedAttachmentsAction } = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
