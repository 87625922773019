import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { ReportType, ReportReceipts, ReportMonthType } from '../../types/reports';
import { reloadCustomerAction } from '../customer/customerSlice';


export type ReportState = {
  list: ReportType | null;
  details: ReportReceipts | null;
  loaded: boolean;
  saving:boolean;
  saved:boolean;
  reload:boolean;
  reloadServiceCases: boolean;
  loading: boolean;
  error: string | null;
  months: ReportMonthType | null;
  lastReport: string;
  loadedServiceCase: boolean;
}

const initialState: ReportState = {
  list: null,
  details: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  reload: true,
  reloadServiceCases: true,
  error: null,
  months: null ,
  lastReport: '',
  loadedServiceCase: false,
};

export const getReceiptsForId = createAsyncThunk<any>('reports', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/report/receipts', method: 'GET', thunkApi });
});
export const getServiceCasesForId = createAsyncThunk<any>('reportsServiceCases', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/report/serviceCases', method: 'GET', thunkApi });
});

export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clear: (state) => {
      state.list = null;
      state.loaded = false;
      state.reload = true;
      state.reloadServiceCases = true;
      state.details = null;
    }
  },
  extraReducers: (builder) => {
     builder.addCase(getReceiptsForId.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
      state.reload = false;
      state.error = null;
    });

    builder.addCase(getReceiptsForId.fulfilled, (state, action: PayloadAction<any> ) => {
      state.loading = false;
      state.loaded = true;
      state.list = action.payload;
      state.details = action.payload;
      state.reloadServiceCases = true;
      state.lastReport = 'reciept';
    });
    builder.addCase(getReceiptsForId.rejected, (state, action: any) => {
      console.log('Error fetching report', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
    });
    builder.addCase(getServiceCasesForId.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loadedServiceCase = false;
      state.reloadServiceCases = false;
      state.error = null;
    });

    builder.addCase(getServiceCasesForId.fulfilled, (state, action: PayloadAction<any> ) => {
      state.loading = false;
      state.loadedServiceCase = true;
      state.reload = true;
      state.list = action.payload;
      state.details = action.payload;
      state.lastReport = 'serviceCase';
    });
    builder.addCase(getServiceCasesForId.rejected, (state, action: any) => {
      console.log('Error fetching report', action);
      state.loading = false;
      state.loadedServiceCase = false;
      state.error = action.payload;
      state.reload = false;
    });
  }
});
export const { clear: clearReportsState } = reportSlice.actions;
export default reportSlice.reducer;
