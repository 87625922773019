import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText,
  InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { getValueFromStateOrObject } from "../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useLocales from "../../hooks/useLocales";
import { getAdminServicePlans } from "../../redux/admin/adminServicePlanSlice";
import { saveServicePlanForServiceBook } from "../../redux/serviceBooks/serviceBookSlice";
import { ServicePlanItemType } from "../../types/servicePlanItem";

type Props = {
  item?: Partial<ServicePlanItemType>,
  close: () => void,
  serviceBookType: string,
  serviceBookId: number,
}

type StateType = {
  type: string;
}

export default function AddEditServicePlanItemType(props: Props) {

  const { t } = useLocales();
  const { list, loading, loaded } = useAppSelector(store => store.adminServicePlan);
  const { saved: savedPlans, saving: savingPlans } = useAppSelector(store => store.serviceBook);
  const item = props.item;

  const [state, setState] = useState<Partial<ServicePlanItemType>>({ type: 'inspectionCase' });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(getAdminServicePlans());
    }
  }, [loaded]);

  useEffect(() => {
    if (savedPlans.includes(props.serviceBookId)) {
      props.close();
    }
  }, [savedPlans]);

  const handleChange = (name: string, value: number | string | null | undefined | boolean) => setState({ ...state, [name]: value });
  const handleIndoorChange = (name: string, value: boolean) => {
    if (state.indoorOrOutdoor === 'indoor' && name === 'indoor' && value === false) {
      setState({ ...state, indoorOrOutdoor: null });
    }
    else if (state.indoorOrOutdoor && name === 'outdoor' && value === false) {
      setState({ ...state, indoorOrOutdoor: null });
    }
    else {
      setState({ ...state, indoorOrOutdoor: name === 'indoor' ? 'indoor' : 'outdoor' });
    }
  };
  const submit = () => {
    const data = {
      ...state,
      cost: state.cost ? +state.cost : undefined,
      interval: state.interval ? (+state.interval * 30) : undefined,
    };
    if (item) {
      data.id = item.id;
    }
    dispatch(saveServicePlanForServiceBook({ id: props.serviceBookId, data }));
  };
  const prefillWithAdminItem = (id: number) => {
    const item = list.find(row => row.id === id);
    if (item) {
      setState({ ...item, cost: (item && item.cost ? +item.cost : undefined), id: undefined });
    }
  };
  return (
    <Box>
      {
        loading && <CircularProgress />
      }
      {
        /*
        !loading && loaded &&
          <FormControl
            sx={{ mt: 2, mb: 1 }}
            fullWidth
          >
            <InputLabel>{t('Select item')}</InputLabel>
            <Select
              label={t('Select item')}
              value={ getValueFromStateOrObject(state, item, 'itemId') }
              onChange={(e: any) => prefillWithAdminItem(parseInt(e.target.value))}
            >
              {
                list.filter(row => row.forType === props.serviceBookType).map(row =>
                  <MenuItem key={`row_${row.id}`} value={`${row.id}`}>
                    { row.category }:{ row.name }
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
        */
      }
      {
        /*

        <FormControl
          sx={{ mt: 2, mb: 1 }}
          fullWidth
        >
          <InputLabel>{t('Type')}</InputLabel>
          <Select
            label={t('Type')}
            value={ getValueFromStateOrObject(state, item, 'type') }
            onChange={(e: any) => handleChange('type', e.target.value)}
          >
            <MenuItem value="serviceCase">{t('Service case')}</MenuItem>
            <MenuItem value="inspectionCase">{t('Inspection')}</MenuItem>
          </Select>

        </FormControl>
      */
      }
      <TextField
        sx={{ mt: 1 }}
        label={t('Category')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, item, 'category') }
        onChange={(e) => handleChange('category', e.target.value)}
      />
       <TextField
        sx={{ mt: 1 }}
        label={t('Name')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, item, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <TextField
       sx={{ mt: 1 }}
       label={t('Description')}
       variant="outlined"
       rows={5}
       multiline
       fullWidth
       value={ getValueFromStateOrObject(state, item, 'description') }
       onChange={(e) => handleChange('description', e.target.value)}
     />
     <p><b>{t('Seasons')}</b></p>
     <Box sx={{ mt: 1, mb: 1 }}>
      <FormControl>
        {
          ['winter', 'spring', 'summer', 'fall'].map((season: string) => {
            if (!['winter', 'spring', 'summer', 'fall'].includes(season)) {
              return <></>;
            }
            const anyState = state as any;
            const anyItem = item as any;
            return (
              <FormControlLabel
                key={`season_${season}`} label={t(season)}
                control={
                  <Checkbox onChange={(e: any) => handleChange(season, e.target.checked) }
                    checked={ anyState[season] !== undefined ? anyState[season] === true : (item && anyItem[season] && anyItem[season] === true || false) }
                  />}
              />
            );

          })
        }
      </FormControl>
    </Box>

    <TextField
       sx={{ mt: 2 }}
       label={`${t('Approximate cost')} (EUR)`}
       variant="outlined"
       type="number"
       fullWidth
       value={ getValueFromStateOrObject(state, item, 'cost') }
       onChange={(e) => handleChange('cost', parseFloat(e.target.value))}
     />
     {
       /*

      <FormControl
          sx={{ mt: 2, mb: 1 }}
          fullWidth
        >
          <InputLabel>{t('Interval')}</InputLabel>
          <Select
            label={t('Interval')}
            name="interval"
            value={ getValueFromStateOrObject(state, item, 'interval') }
            onChange={(e: any) => handleChange('interval', parseInt(e.target.value))}
          >
            <MenuItem value={12}>
              1
            </MenuItem>
            <MenuItem value={6}>
              2
            </MenuItem>
            <MenuItem value={4}>
              3
            </MenuItem>
            <MenuItem value={3}>
              4
            </MenuItem>
          </Select>

          <FormHelperText>{t('How many times it should be done in year')}</FormHelperText>
        </FormControl>
        */
      }
      <FormGroup sx={{ mb: 1, mt: 1 }}>
        <FormControlLabel control={
          <Switch
            checked={ state.indoorOrOutdoor === 'indoor' } onChange={ (e) => handleIndoorChange('indoor', e.target.checked) } defaultChecked
          />
        } label={t('Indoors')} />

        <FormControlLabel control={
          <Switch
            checked={ state.indoorOrOutdoor === 'outdoor' } onChange={ (e) => handleIndoorChange('outdoor', e.target.checked) }
          />
        } label={t('Outdoors')} />

        <FormHelperText>{t('Unselect both to not matter')}</FormHelperText>
      </FormGroup>

      {
        savingPlans.includes(props.serviceBookId) ? <CircularProgress /> :
          <Button onClick={submit} variant="contained">
              { props.item ? t('Save') : t('Add') }
          </Button>
      }


    </Box>
  );
}