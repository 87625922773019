import React, { useEffect, useState } from 'react';
import { ArrowBackRounded, EditRounded, PictureAsPdfRounded } from '@mui/icons-material';
import { Button, IconButton, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { AgreementType } from '../../types/agreement';
import SidePanel from '../sidepanel/SidePanel';
import { clearAgreementsState, deleteAgreementAction } from '../../redux/agreements/agreementsSlice';
import AddEditAgreement from './AddEditAgreement';
import { parseAndFormatDate } from '../../helpers/helpers';
import { AttachmentType } from '../../types/attachment';
import { load } from '../../redux/translations/translationsSlice';
import { loadAttachmentAction, resetLoadedAttachment, resetUploadedAttachmentsAction } from '../../redux/attachment/attachmentSlice';
import ConfirmableButton from '../buttons/ConfirmableButton';

type AgreementComponentProps = {
  agreement?: AgreementType,
  close: () => void,
};

export default function AgreementComponent(props: AgreementComponentProps) {
  const { t } = useLocales();
  const { agreement, close } = props;

  const dispatch = useAppDispatch();
  const { loadedAttachment, attachment, uploaded } = useAppSelector(store => store.attachment);
  const { loaded, saved, deleting, deleted } = useAppSelector(store => store.agreements);

  const [state, setState] = useState<any>({ edit: false, id: null });

  useEffect(() => {
    if (saved && loaded) {
      setState({ edit: false });
      close();
    }
  }, [saved, loaded]);

  useEffect(() => {
    if (agreement && state.id !== agreement.id) {
      setState({ edit: false });
    }
  }, [agreement]);

  useEffect(() => {
    if (loadedAttachment && attachment) {
      window.open(attachment.link);
      dispatch(resetLoadedAttachment());
    }
  }, [loadedAttachment, attachment]);

  useEffect(() => {
    if (deleted) {
      close();
    }
  }, [deleted]);

  const load = (id: number | undefined) => {
    if (!id) {
      return;
    }
    dispatch(loadAttachmentAction(id));
  };

  if (!agreement) {
    return (
      <Box>
        {t('Not found')}
      </Box>
    );
  }
  if (state.edit) {
    return <AddEditAgreement agreement={agreement} cancel={ () => setState({ edit: false }) } />;
  }
  return (
    <Box>
      <SidePanel
        open={ state.edit }
        close={ () => setState({ edit: false })}
      >
        <Typography sx={{ p: 2 }} variant="h6">{t('Edit agreement')}</Typography>
      </SidePanel>
      <Box sx={{ p: 1 }}>
      {t('Description')}: { agreement.description }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Expires at')}: { agreement.expiryDate && parseAndFormatDate(agreement.expiryDate) }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Created at')}: { agreement.created && parseAndFormatDate(agreement.created) }
      </Box>
      <Box sx={{ p: 1 }}>
        {t('Value')}: { agreement.value } { agreement.unit }
      </Box>
      <Box sx={{ p: 1 }}>
        {
          (agreement.attachments || []).map((attachment: Partial<AttachmentType>, idx: number) =>
            <Button
              key={`idx_${idx}`}
              onClick={ () => load(attachment.id)}
              startIcon={<PictureAsPdfRounded />}
            >
              { attachment.filename}
            </Button>

          )
        }
      </Box>
      <Box sx={{ borderTop: 'thin solid gray', mt: 4 }}>
        <Button color="secondary" onClick={() => setState({ edit: true })} startIcon={<EditRounded />}>{t('Edit')}</Button>
      </Box>
      {
        agreement && agreement.id &&
          <Box sx={{ mt: 2 }}>
            <ConfirmableButton
              showLoader={ deleting }
              onSubmit={ () => dispatch(deleteAgreementAction(agreement.id as number)) }
              buttonText={ t('Delete') }
            />
          </Box>
      }
    </Box>
  );
}