/* eslint-disable @typescript-eslint/no-explicit-any */
import  { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import {  Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '../../../hooks';
import useLocales from '../../../hooks/useLocales';
import { SubscriptionType } from '../../../types/subscription';
import { addSubscription, deleteSubscription } from '../../../redux/admin/adminCustomerSlice';
import { getValueFromStateOrObject } from '../../../helpers/helpers';

type AddEditSubscriptionComponentType = {
  subscription?: SubscriptionType,
  open: boolean,
  add: boolean,
  remove: boolean,
  close: () => void,
};

export default function AddEditSubscription(props: AddEditSubscriptionComponentType) {

  const { subscription, close, open, add, remove } = props;
  const id: any = subscription?.id;
  const { t } = useLocales();
  const dispatch = useDispatch();
  const [state, setState] = useState({  edit: false, name: ' ', saved: false});
  const { list } = useAppSelector(store => store.adminCustomer);

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  const removeSubscription = () => {
    if (list?.findIndex((sub: any) =>    (sub.subscriptionId === id) ) === -1){
      dispatch(deleteSubscription({id}));
    }
    close();
  };

  const addSubscriptionRen = () => {
    const name : any = ({name : state.name});
    if (add) {
      dispatch(addSubscription({ name: name }));
    }
    close();
  };

  return (
    <div>
      {add ?
        <Box sx={{ p: 2 }}>
          <TextField
            sx={{ mt: 1 }}
            label={t('Subscription')}
            multiline
            rows={1}
            variant="outlined"
            fullWidth
            value={ getValueFromStateOrObject(state, subscription, 'name') }
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Box>
        :
        <Box>
          <Box sx={{ p: 1 }}>
            {t('Id')}: { subscription?.id && subscription.id }
          </Box>
          <Box sx={{ p: 1 }}>
            {t('Subscription')}: {subscription?.name && subscription.name}
          </Box>
        </Box>
      }
      {add &&
        <Button color="primary" variant="contained" sx={{ ml: 1 }}
          onClick={addSubscriptionRen}
        >
          {t('Add subscription')}
        </Button>
      }
      {remove &&
        <Button color="primary" variant="contained" sx={{ ml: 1 }}
          onClick={removeSubscription}
        >
          {t('Remove subscription')}
        </Button>
      }
      {open &&
          <Button color="primary" variant="contained" sx={{ ml: 1 }}
          onClick={close}
        >
          {t('Cancel')}
        </Button>
        }
      </div>
  );
}