/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { CustomerType } from '../../types/customer';
import { getToken } from '../profile/profileSlice';

export type AdminCustomerState = {
  list: CustomerType[] | null;
  loading: boolean;
  loaded: boolean;
  saving:boolean;
  savingUsers: boolean;
  savedUsers: boolean;
  saved:boolean;
  reload:boolean;
  error: string | null;
  listNotes: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customerData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptions: any;
  loadingSubscriptions: boolean;
  loadedSubscriptions: boolean;
  savingNote: boolean;
  savedNote: boolean;
}

const initialState: AdminCustomerState = {
  list: null,
  loading: false,
  loaded: false,
  saving: false,
  savingUsers: false,
  savedUsers: false,
  saved: false,
  reload: true,
  error: null,
  listNotes: false,
  customerData: '',
  subscriptions: '',
  loadingSubscriptions: false,
  loadedSubscriptions: false,
  savingNote: false,
  savedNote: false,

};

export const getAdminCustomersAction = createAsyncThunk<any>('admincustomer/getadmin', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer`, method: 'GET', token: getToken(thunkApi.getState()) });
});

export const deleteAdminCustomer = createAsyncThunk('admincustomer/delete', async (params: { id: number }, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}`,  token: getToken(thunkApi.getState()), method: 'DELETE' });
});


export const addCustomer = createAsyncThunk<void, Partial<CustomerType>>('admincustomer/add', async (data: Partial<CustomerType>, thunkApi: any) => {
  return await apiRequest({ path: '/admin/customer', data, token: getToken(thunkApi.getState()), method: 'POST' });
});

export const addUserForCustomerAction = createAsyncThunk('admincustomer/adduser', async (params: { id: number, userId: number}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}/user/${params.userId}`, token: getToken(thunkApi.getState()), method: 'POST' });
});
export const deleteUserFromCustomerAction = createAsyncThunk('admincustomer/deleteuser', async (params: { id: number, userId: number}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}/user/${params.userId}`, token: getToken(thunkApi.getState()), method: 'DELETE' });
});

export const updateCustomer = createAsyncThunk('admincustomer/update', async (params: { id: number, data: Partial<CustomerType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}`, data: params.data, token: getToken(thunkApi.getState()), method: 'PATCH' });
});

export const addLogToCustomer = createAsyncThunk('admincustomer/addlog', async (params: { id: number, message: Partial<CustomerType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}/log`, data: params.message, token: getToken(thunkApi.getState()), method: 'POST' });
});

export const updateLogToCustomer = createAsyncThunk('admincustomer/updatelog', async (params: { id: number, logId: number, message: Partial<CustomerType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}/log/${params.logId}`, data: params.message, token: getToken(thunkApi.getState()), method: 'PATCH' });
});
export const deleteLogFromCustomer = createAsyncThunk('admincustomer/deletelog', async (params: { id: number, logId: number, message: Partial<CustomerType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}/log/${params.logId}`, data: params.message, token: getToken(thunkApi.getState()), method: 'DELETE' });
});

export const editSubscriptionToCustomer = createAsyncThunk('admincustomer/editsubscription', async (params: { id: number | undefined, subscriptionId: number | undefined, customer: Partial<CustomerType>}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/customer/${params.id}/subscription/${params.subscriptionId}`, data: {name: params.subscriptionId, customerId: params.id, customer: params.customer}, token: getToken(thunkApi.getState()), method: 'PATCH' });
});

export const getSubscriptions = createAsyncThunk<any>('admincustomer/subscriptions',  async (_: any, thunkApi: any) => {
  return await apiRequest({ path: `/admin/subscription/list`, method: 'GET', token: getToken(thunkApi.getState()) });
});

export const addSubscription = createAsyncThunk('subscriptionOnly/addsubscription', async (params: {  name: any }, thunkApi: any) => {
  return await apiRequest({ path: `/admin/subscription/add`,  data: params.name, token: getToken(thunkApi.getState()), method: 'POST' });
});

export const deleteSubscription = createAsyncThunk('subscriptiononly/deletesubscription', async (params: { id: number}, thunkApi: any) => {
  return await apiRequest({ path: `/admin/subscription/${params.id}`, token: getToken(thunkApi.getState()), method: 'DELETE' });
});


export const adminCustomerSlice = createSlice({
  name: 'customer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(editSubscriptionToCustomer.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
      state.reload = false;
      state.listNotes = false;
    });
    builder.addCase(editSubscriptionToCustomer.fulfilled, (state, action:  PayloadAction<CustomerType> | PayloadAction<CustomerType[]> ) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
      state.listNotes = false;
      state.customerData = action;
    });
    builder.addCase(editSubscriptionToCustomer.rejected, (state, action: any) => {
      console.log('Error editing subscription', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
      state.listNotes = false;
    });

builder.addCase(updateLogToCustomer.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
      state.reload = false;
      state.listNotes = false;
    });

    builder.addCase(updateLogToCustomer.fulfilled, (state, action:  PayloadAction<CustomerType> | PayloadAction<CustomerType[]> ) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
      state.listNotes = true;
      state.customerData = action;
    });
    builder.addCase(updateLogToCustomer.rejected, (state, action: any) => {
      console.log('Error updating log', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
      state.listNotes = false;
    });

    builder.addCase(addLogToCustomer.pending, (state, action: PayloadAction<any>) => {
      state.savingNote = true;
      state.savedNote = false;
    });

    builder.addCase(addLogToCustomer.fulfilled, (state, action:  PayloadAction<CustomerType> | PayloadAction<CustomerType[]> ) => {
      state.savingNote = false;
      state.savedNote = true;
      state.reload = true;
    });
    builder.addCase(addLogToCustomer.rejected, (state, action: any) => {
      console.log('Error updating log', action);
      state.error = action.payload;
      state.savingNote = false;
      state.savedNote = false;
    });

    builder.addCase(deleteLogFromCustomer.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
      state.reload = false;
      state.listNotes = false;
    });

    builder.addCase(deleteLogFromCustomer.fulfilled, (state, action:  PayloadAction<CustomerType> | PayloadAction<CustomerType[]> ) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
      state.listNotes = true;
      state.customerData = action;
    });
    builder.addCase(deleteLogFromCustomer.rejected, (state, action: any) => {
      console.log('Error adding log', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
      state.listNotes = false;
    });

    builder.addCase(deleteSubscription.rejected, (state, action: any) => {
      console.log('Error adding log', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
      state.listNotes = false;
    });
    builder.addCase(deleteSubscription.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
      state.reload = false;
    });
    builder.addCase(deleteSubscription.fulfilled, (state, action: PayloadAction<CustomerType> | PayloadAction<CustomerType[]>) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
      state.loading = false;
      state.listNotes = true;
    });

    builder.addCase(getSubscriptions.pending, (state, action: PayloadAction<any>) => {
      state.loadingSubscriptions = true;
      state.loadedSubscriptions = false;
    });

    builder.addCase(getSubscriptions.fulfilled, (state, action: PayloadAction<CustomerType> | PayloadAction<CustomerType[]>) => {
      state.loadingSubscriptions = false;
      state.loadedSubscriptions = true;
      state.subscriptions = action.payload;
    });
    builder.addCase(getSubscriptions.rejected, (state, action: any) => {
      console.log('Error fetching admin subscription', action);
      state.loadingSubscriptions = false;
      state.loadedSubscriptions = false;
      state.error = action.payload;
    });
    builder.addCase(addSubscription.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
      state.reload = false;
    });

    builder.addCase(addSubscription.fulfilled, (state, action: PayloadAction<CustomerType> | PayloadAction<CustomerType[]>) => {
      state.loading = false;
      state.loaded = true;
      state.saved = true;
      state.reload = true;
    });
    builder.addCase(addSubscription.rejected, (state, action: any) => {
      console.log('Error fetching admin subscription', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
    });

    builder.addCase(getAdminCustomersAction.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
      state.reload = false;
    });

    builder.addCase(getAdminCustomersAction.fulfilled, (state, action: PayloadAction<CustomerType> | PayloadAction<CustomerType[]>) => {
      state.loading = false;
      state.loaded = true;
      state.saved = false;
      state.listNotes = false;
      // Handle case where customers response is array
      if (action.payload && (action.payload instanceof Array)) {
        state.list = action.payload;
      }
    });
    builder.addCase(getAdminCustomersAction.rejected, (state, action: any) => {
      console.log('Error fetching admin customer', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
      state.reload = false;
    });
    builder.addCase(addCustomer.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(addCustomer.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(addCustomer.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error adding customer', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(updateCustomer.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(updateCustomer.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(updateCustomer.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating customer', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(addUserForCustomerAction.pending, (state, _: any) => {
      state.savingUsers = true;
    });
    builder.addCase(addUserForCustomerAction.fulfilled, (state, _: any) => {
      state.savingUsers = false;
      state.savedUsers = true;
      state.reload = true;
      state.loaded = false;
      state.listNotes = false;
    });
    builder.addCase(addUserForCustomerAction.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating customers users', action);
      state.savingUsers = false;
      state.savedUsers = false;
      state.error = action.payload;
    });

     builder.addCase(deleteUserFromCustomerAction.pending, (state, _: any) => {
      state.savingUsers = true;
    });
    builder.addCase(deleteUserFromCustomerAction.fulfilled, (state, _: any) => {
      state.savingUsers = false;
      state.savedUsers = true;
      state.reload = true;
      state.loaded = false;
      state.listNotes = false;
    });
    builder.addCase(deleteUserFromCustomerAction.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error deleting customers users', action);
      state.savingUsers = false;
      state.savedUsers = false;
      state.error = action.payload;
    });

    builder.addCase(deleteAdminCustomer.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(deleteAdminCustomer.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
      state.listNotes = false;
    });
    builder.addCase(deleteAdminCustomer.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating customer', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
      state.listNotes = false;
    });
  }
});

export default adminCustomerSlice.reducer;
