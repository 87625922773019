import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  message: string;
  visible?: boolean;
  severity: 'error' | 'success' | 'warning';
}

const initialState = {
  message: '',
  visible: false,
  severity: 'error',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<State>) => {
      state.message = action.payload.message;
      state.visible = true;
      state.severity = action.payload.severity || 'warning';
    },
    hide: (state) => {
      state.visible = false;
    }
  },
});

export const { hide: hideNotification, show: showNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
