import { addDays, differenceInBusinessDays, differenceInDays, format, getUnixTime, isValid, parseISO, subDays } from 'date-fns';
import { SeasonType } from '../components/serviceBooks/ServiceCalendar';
import { CustomerType } from '../types/customer';
import { InspectionHistoryType, LongTermPlanItemType, ServiceBookType,  ServiceCaseType,  ServiceHistoryType,  UpcomingServiceCaseType } from '../types/serviceBook';
import { ServicePlanItemType } from '../types/servicePlanItem';

// Hide tasks due further than these days
const upperDayLimit = 360;

export const getPlanFromDate = (serviceBook: ServiceBookType, latest?: ServiceCaseType): Date => {
  if (latest && latest.createdAt) {
    return latest.createdAt;
  }
  return serviceBook.startPlanningFrom || subDays(new Date(), 30);
};

export const getLatestDoneForList = (list: ServiceCaseType[]): ServiceCaseType | undefined => {
  if (!list || list.length === 0) {
    return;
  }
  const sorted = (list || []).slice().sort((a, b) =>
  getUnixTime(valueAsDate(a.createdAt) || new Date()) - getUnixTime(valueAsDate(b.createdAt) || new Date())
  );
  return sorted[0];
};

// TODO: Move this to the api once available.
export const getUpcomingServiceCases = (serviceBook: ServiceBookType, season: 'spring' | 'winter' | 'fall' | 'summer'): UpcomingServiceCaseType[] => {
  if (!serviceBook || !serviceBook.servicePlanItems) {
    return [];
  }
  const plans = (serviceBook.servicePlanItems || []).filter(row => row[season] === true);
  const listDone = serviceBook.serviceCases;
  const todo = plans.reduce((acc: UpcomingServiceCaseType[], plan: ServicePlanItemType) => {
    const results = acc;
    if (!plan.interval || !plan.id) {
      return results;
    }
    const latestDone = (listDone || []).slice().sort((a, b) =>
    getUnixTime(valueAsDate(a.createdAt) || new Date()) - getUnixTime(valueAsDate(b.createdAt) || new Date())
    ).find((row: ServiceCaseType) => row.servicePlanItemId === plan.id);
    // If case is handled this month, skip.
    if (latestDone && getSeasonForDate(latestDone.date) !== season) {
      return results;
    }
    results.push({
      name: plan.name || '',
      description: plan.description || '',
      serviceBookId: serviceBook.id,
      servicePlanId: plan.id,
      type: plan.type,
    });

    return results;
  }, []);
  return todo.slice(0).sort((a: UpcomingServiceCaseType, b: UpcomingServiceCaseType) =>
  getUnixTime(valueAsDate(a.dueDate) || new Date()) - getUnixTime(valueAsDate(b.dueDate) || new Date()));
};

export const getLongTermPlanForServiceBook = (serviceBook: ServiceBookType): LongTermPlanItemType[] => {
  const farFuture = serviceBook.servicePlanItems?.filter(row => row.interval > 365) || [];

  return farFuture.reduce((acc: LongTermPlanItemType[], plan: ServicePlanItemType) => {
    const results = acc;
    if (!plan.interval || !plan.id) {
      return results;
    }
    const latestDone = (serviceBook.serviceCases || []).slice().sort((a, b) =>
    getUnixTime(valueAsDate(a.createdAt) || new Date()) - getUnixTime(valueAsDate(b.createdAt) || new Date())).find((row: ServiceCaseType) => row.servicePlanItemId === plan.id);
    if (!latestDone) {
      let dueIn: Date;
      if (serviceBook.startPlanningFrom) {
        const daysUsed = differenceInBusinessDays(new Date(), valueAsDate(serviceBook.startPlanningFrom) || new Date());
        dueIn = addDays(new Date(), (plan.interval - daysUsed));
      }
      else {
        dueIn = addDays(new Date(), plan.interval);
      }
      results.push({
        servicePlanId: plan.id,
        description: plan.name || '',
        cost: plan.cost,
        dueDate: dueIn
      });
    }
    else {
      const daysSince = differenceInBusinessDays(new Date(), valueAsDate(latestDone.createdAt) || new Date());
      const dueDate = addDays(new Date(), (plan.interval - daysSince));
      results.push({
        servicePlanId: plan.id,
        description: plan.name || '',
        cost: plan.cost,
        dueDate
      });
    }
    return results.slice(0).sort((a, b) => getUnixTime(a.dueDate) - getUnixTime(b.dueDate));
  }, []);

};

export const sortArrayByDate = (array: any, prop: string, order?: 'asc' | 'desc') => {
  return array.slice(0).sort((a: any, b: any) => {
    if (order === 'asc') {
      return getUnixTime(valueAsDate(a[prop])) - getUnixTime(valueAsDate(b[prop]));
    }
    return getUnixTime(valueAsDate(b[prop])) - getUnixTime(valueAsDate(a[prop]));
  });
};

export const parseAndFormatDate = (value: string | Date | null, formatDefined?: string): string => {
  if (!value) {
    return '';
  }
  if (isValid(value)) {
    return format(value as Date, formatDefined || 'dd.MM.yyyy');
  }
  const parsed = parseISO(value as string);
  if (isValid(parsed)) {
    return format(parsed, formatDefined || 'dd.MM.yyyy');
  }
  return '';
};

export const getValueFromStateOrObject = (state: any, object: any, key: string): string | number => {

  if (state && state[key] !== null && state[key] !== undefined) {
    return state[key];
  }
  if (object && object[key] !== null && object[key] !== undefined) {

    return object[key];
  }
  return '';
};

export const isBoolean = (value: string | boolean | number) : boolean => {
  if (value === 'true' || value === 1 || value === true) {
    return true;
  }
  return false;
};

export const getBooleanFromStateOrObject = (state: any, object: any, key: string): boolean => {

  if (state && state[key] !== null && state[key] !== undefined) {
    return isBoolean(state[key]);
  }
  if (object && object[key] !== null && object[key] !== undefined) {

    return isBoolean(object[key]);
  }
  return false;
};

export const valueAsDate = (value: string | Date | undefined | null): Date => {
  if (!value) {
    return new Date();
  }
  if (value instanceof Date) {
    return value;
  }
  if (value.length > 0) {
    const parsed = parseISO(value);
    if (isValid(parsed)) {
      return parsed;
    }
  }
  return new Date();
};
export const valueAsDateOrUndefined = (value: string | Date | undefined | null): Date | undefined => {
  if (!value) {
    return;
  }
  if (value instanceof Date) {
    return value;
  }
  if (value.length > 0) {
    const parsed = parseISO(value);
    if (isValid(parsed)) {
      return parsed;
    }
  }
};

export const getDateFromStateOrObject = (state: any, object: any, key: string, fallback: boolean): Date | null => {
  if (state[key]) {
    return valueAsDate(state[key]);
  }
  if (object && object[key]) {
    return valueAsDate(object[key]);
  }
  if (fallback) {
    return new Date();
  }
  return null;
};
export const parseError = (error: any) => {
  let message = 'Error: ';
  if (error && error.message) {
    message += `${error.message} `;
  }
  return message;
};

export const getCurrentSeason = (): SeasonType => {
  return getSeasonForDate(new Date());
};

export const isInThePast = (currentSeason: SeasonType, currentYear: number, targetSeason: SeasonType, targetYear: number): boolean => {
  if (currentYear < targetYear) {
    return true;
  }
  if (currentYear > targetYear) {
    return false;
  }
  if (currentSeason === 'winter') {
    return false;
  }
  if (currentSeason === 'spring') {
    return targetSeason === 'winter';
  }
  if (currentSeason === 'summer') {
    return !['winter', 'spring'].includes(targetSeason);
  }
  if (currentSeason === 'fall') {
    return !['winter', 'spring', 'summer'].includes(targetSeason);
  }
  console.error('Fallback case', currentSeason, targetSeason, currentYear, targetYear);
  return false;
};

export const getSeasonForDate = (dateProp: Date): SeasonType => {
  const date = dateProp || new Date();
  const month = date.getMonth() + 1;
  if ([3, 4, 5].includes(month)) {
    return 'spring';
  }
  else if ([6, 7, 8].includes(month)) {
    return 'summer';
  }
  else if ([9, 10, 11].includes(month)) {
    return 'fall';
  }
  else {
    return 'winter';
  }

};

export const sanitizeNumberField = (val: any) => {
  if (!val || val.length === 0) {
    return '';
  }
  const returnable = parseInt(val.replace(/,/gi, '.'));
  if (isNaN(returnable)) {
    return '';
  }
  return returnable;
};

export const roundToTwoDecimals = (valueProp: any) => {
  const value = parseFloat(valueProp);
  return Math.round(value * 100) / 100;
};

export const isCustomerActive = (details: CustomerType) : boolean => {
  const createdDateParsed = parseISO(details.createdAt);
  const daysTested = differenceInDays(new Date(), createdDateParsed);
  const hasSub = details.subscription && details.subscription.id !== null;
  return hasSub || daysTested <= 30;
};