import { Box } from "@mui/system";
import useLocales from '../../hooks/useLocales';
import { Button, Typography } from "@mui/material";
import { ServicePlanItemType } from "../../types/servicePlanItem";

type Props = {
  serviceItem?: ServicePlanItemType,
  close: () => void
}
export default function ServiceCaseInfo(props: Props) {
  const { t } = useLocales();

  const serviceItem = props.serviceItem;

  if (serviceItem) {
    return <Box sx={{ p: 2, lineHeight: '1.5' }}>
      <Typography sx={{ mb: 1 }} variant="h6">{t(serviceItem.name)}</Typography>
      {serviceItem.description}
    </Box>;
  }
  return <>{t('No extra information found ')}</>;
}