import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Typography, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { getBooleanFromStateOrObject, getDateFromStateOrObject, getValueFromStateOrObject, sanitizeNumberField } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { reloadCustomerAction } from '../../redux/customer/customerSlice';
import { updateServiceBook, addServiceBookAction, clearAddedOrDeletedAction } from '../../redux/serviceBooks/serviceBookSlice';
import { ServiceBookType } from '../../types/serviceBook';

export type AddEditServiceBookPropsType = {
  new: boolean;
  serviceBook?: ServiceBookType,
  close?: () => void,
}

type StateType = {
  name?: string;
  description?: string;
  type?: 'vehicle' | 'building' | 'other';
  startPlanningFrom?: Date;
}

export default function AddEditServiceBook(props: AddEditServiceBookPropsType) {
  const { serviceBook, close } = props;
  const dispatch = useAppDispatch();
  const { details } = useAppSelector(store => store.customer);
  const { saving, adding, saved, added } = useAppSelector(store => store.serviceBook);
  const [state, setState] = useState<StateType>({});
  const [phase, setPhase] = useState(0);
  const [buildingDetailsState, setBuildingDetailsState] = useState<any>({});
  const [vehicleDetailsState, setVehicleDetailsState] = useState<any>({});
  const { t } = useLocales();
  const handleChange = (e: any) => setState({ ...state, [e.target.name]: e.target.value });
  const handleBuildingCheckboxChange = (e: any) => setBuildingDetailsState(
    { ...buildingDetailsState, [e.target.name]: e.target.checked }
  );
  const handleBuildingChange = (e: any) => setBuildingDetailsState({ ...buildingDetailsState, [e.target.name]: e.target.value });
  const handleVehicleChange = (e: any) => setVehicleDetailsState({ ...vehicleDetailsState, [e.target.name]: e.target.value });
  const handleDateChange = (name: string, value: Date | null) => setState({ ...state, [name]: value });
  const handleNextPhase = () => setPhase(phase + 1);
  const handlePreviousPhase = () => setPhase(phase - 1);

  const isChecked = (key: string) =>  getBooleanFromStateOrObject(buildingDetailsState, buildingDetails, key) === true;

  useEffect(() => {
    if (close && serviceBook && saved && saved.includes(serviceBook.id)) {
      close();
    }
    if (added && close) {
      close();
      dispatch(clearAddedOrDeletedAction());
      dispatch(reloadCustomerAction());
    }
    if (close) {
      // Reset the phase state on open/close
      setPhase(0);
    }
  }, [serviceBook, saved, close, added, dispatch]);

  const buildingDetails = serviceBook ? serviceBook.buildingDetails : {};
  const vehicleDetails = serviceBook ? serviceBook.vehicleDetails : {};

  const submit = () => {
    const data: Partial<ServiceBookType> = { ...state };

    data.id = serviceBook ? serviceBook.id : undefined;
    if (getValueFromStateOrObject(state, serviceBook, 'type') === 'building') {
      data.buildingDetails = buildingDetailsState;
      if (serviceBook && serviceBook.buildingDetails && data.buildingDetails) {
        data.buildingDetails.id = serviceBook.buildingDetails.id;
      }
    }

    if (getValueFromStateOrObject(state, serviceBook, 'type') === 'vehicle') {
      data.vehicleDetails = vehicleDetailsState;
      if (serviceBook && serviceBook.vehicleDetails && data.vehicleDetails) {
        data.vehicleDetails.id = serviceBook.vehicleDetails.id;
      }
    }
    if (data.id) {
      dispatch(updateServiceBook(data));
    }
    else if (details && details.id) {
      data.customerId = details.id;
      dispatch(addServiceBookAction(data));
    }
  };

  const showLoader = serviceBook ? saving.includes(serviceBook.id) : adding;
  const showSave = phase === 1 || ['vehicle', 'other'].find(x => x === getValueFromStateOrObject(state, serviceBook, 'type'));

  if (phase === 1 && getValueFromStateOrObject(state, serviceBook, 'type') === 'building') {
    return (
      <Box>
        <p>1. {t('Does the property include')}:</p>
        {
          ['centralVacuumCleaner', 'fireplace', 'roofWindow', 'electricMeltingSystem', 'solarPanels', 'electricSauna', 'woodSauna'].map(key =>
            <>
            <FormControl>
              <FormControlLabel
                key={`building_field_${key}`}
                label={t(key)}
                name={key}
                control={
                  <Checkbox
                    onChange={ handleBuildingCheckboxChange }
                    checked={ isChecked(key) }
                  />
                  }
              />
            </FormControl>
            <br/>
            </>
          )
        }
        <p>2. {t('Heating system')}:</p>
        {
          ['airCondition', 'waterAirHeatPump', 'exhaustAirHeatPump', 'thermalHeating', 'districtHeating',
            'woodHeating', 'oilHeating', 'pelletHeating', 'electricHeating'].map(key =>
            <>
            <FormControl>
              <FormControlLabel
                key={`building_field_${key}`}
                label={t(key)}
                name={key}
                control={
                  <Checkbox
                    onChange={ handleBuildingCheckboxChange }
                    checked={ isChecked(key) }
                  />
                  }
              />
            </FormControl>
            <br/>
            </>
          )
        }
        <p>3. {t('Heat distribution system')}:</p>
        {
          ['waterCirculatingFloorHeating', 'waterCirculatingRadiatorHeating',
            'electricFloorHeating', 'electricRadiatorHeating'].map(key =>
            <>
            <FormControl>
              <FormControlLabel
                key={`building_field_${key}`}
                label={t(key)}
                name={key}
                control={
                  <Checkbox
                    onChange={ handleBuildingCheckboxChange }
                    checked={ isChecked(key) }
                  />
                  }
              />
            </FormControl>
            <br/>
            </>
          )
        }
        <p>4. {t('Ventilation system')}:</p>
        {
          ['mechanicalVentilation', 'naturalVentilation'].map(key =>
            <>
            <FormControl>
              <FormControlLabel
                key={`building_field_${key}`}
                label={t(key)}
                name={key}
                control={
                  <Checkbox
                    onChange={ handleBuildingCheckboxChange }
                    checked={ isChecked(key) }
                  />
                  }
              />
            </FormControl>
            <br/>
            </>
          )
        }
        <Box sx={{ display: 'flex' }}>
          <Button sx={{ mr: 2 }} variant="outlined" onClick={handlePreviousPhase} type="submit">{t('Previous page')}</Button>
          {
          showLoader ? <CircularProgress /> :
            <Button variant="contained" onClick={submit} type="submit">{t('Save')}</Button>
          }
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={t('Start planning from')}
          inputFormat="dd.MM.yyyy"
          value={ getDateFromStateOrObject(state, serviceBook, 'startPlanningFrom', true) }
          onChange={ (value: Date | null) => handleDateChange('startPlanningFrom', value) }
          renderInput={(params: any) => <TextField
            fullWidth
            sx={{ mt: 1, mb: 1 }}
            variant="outlined"
            {...params}
          />}
        />
      </LocalizationProvider>
      <TextField
        fullWidth
        sx={{ mt: 1, mb: 1 }}
        label={t('Name')}
        autoComplete='off'
        name="name"
        value={ getValueFromStateOrObject(state, serviceBook, 'name') }
        onChange={handleChange}
      />
      <TextField
        fullWidth
        sx={{ mt: 1, mb: 1 }}
        label={t('Description')}
        name="description"
        multiline
        rows={5}
        value={ getValueFromStateOrObject(state, serviceBook, 'description') }
        onChange={handleChange}
      />
      <FormControl
        sx={{ mt: 1, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('Type')}</InputLabel>
        <Select
          label={t('Type')}
          name="type"
          value={ getValueFromStateOrObject(state, serviceBook, 'type') }
          onChange={handleChange}
        >
          <MenuItem value="building">{t('Building')}</MenuItem>
          <MenuItem value="vehicle">{t('Vehicle')}</MenuItem>
          <MenuItem value="other">{t('Other')}</MenuItem>
        </Select>
      </FormControl>

      {
        getValueFromStateOrObject(state, serviceBook, 'type') === 'building' &&
        <>
          {
            ['address'].map(key =>
              <TextField
                key={`building_field_${key}`}
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                label={t(key)}
                name={key}
                value={ getValueFromStateOrObject(buildingDetailsState, buildingDetails, key) }
                onChange={handleBuildingChange}
              />
            )
          }
          {
            ['constructionYear', 'area', 'totalArea', 'floors'].map(key =>
              <TextField
                key={`building_field_${key}`}
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                label={t(key)}
                name={key}
                value={ getValueFromStateOrObject(buildingDetailsState, buildingDetails, key) }
                onChange={ e => setBuildingDetailsState({ ...buildingDetailsState, [e.target.name]: sanitizeNumberField(e.target.value) })}
              />
            )
          }
          <FormControl
            sx={{ mt: 1, mb: 1 }}
            fullWidth
          >
            <InputLabel>{t('Structure type')}</InputLabel>
            <Select
              label={t('Structure type')}
              name="structure"
              value={ getValueFromStateOrObject(buildingDetailsState, buildingDetails, 'structure') }
              onChange={handleBuildingChange}
            >
              <MenuItem value="wood">{t('Wood')}</MenuItem>
              <MenuItem value="stone">{t('Stone')}</MenuItem>
              <MenuItem value="other">{t('Other')}</MenuItem>
            </Select>
          </FormControl>
        </>
      }

      {
        getValueFromStateOrObject(state, serviceBook, 'type') === 'vehicle' &&
        <>
        {
            ['licensePlate', 'manufacturedYear'].map(key =>
              <TextField
                key={`vehicle_field_${key}`}
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                label={t(key)}
                name={key}
                value={ getValueFromStateOrObject(vehicleDetailsState, vehicleDetails, key) }
                onChange={handleVehicleChange}
              />
            )
          }
        </>
      }
      {
        getValueFromStateOrObject(state, serviceBook, 'type') === 'building' &&
          <Button variant="contained" onClick={handleNextPhase} type="submit">{t('Next page')}</Button>
      }
      {
         showSave ?
          (showLoader ? <CircularProgress /> : <Button variant="contained" onClick={submit} type="submit">{t('Save')}</Button>)
          : null
      }

    </Box>
  );
}