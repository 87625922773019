import { AddRounded, EditRounded, KeyboardArrowLeft } from '@mui/icons-material';

import { Container, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Box, Button, Typography } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import SidePanel from '../sidepanel/SidePanel';
import { useEffect, useState } from 'react';
import { useServiceBookOutletContext } from '../../containers/ServiceBookContainer';
import ServicePlanItems from './ServicePlanItems';
import AddEditServicePlanItemType from './AddEditServicePlanItem';
import ConfirmableButton from '../buttons/ConfirmableButton';
import { useAppDispatch } from '../../hooks';
import { deleteServiceBookPlan } from '../../redux/serviceBooks/serviceBookSlice';
import { useNavigate } from 'react-router-dom';

type StateType = {
  open: boolean;
  edit?: number;
  listItems: boolean;
}

export default function ServiceBookPlan() {
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState<StateType>({ open: false, listItems: false  });

  const { serviceBook } = useServiceBookOutletContext();

  const servicePlan = serviceBook.servicePlanItems || [];

  const closePanel = () => setState({ ...state, open: false, edit: undefined, listItems: false });

  const deleteItem = (id?: number) => {
    if (!id) {
      return;
    }
    dispatch(deleteServiceBookPlan({ serviceBookId: serviceBook.id, planId: id }));
  };

  return (
    <Container>
      <Button color="secondary" sx={{ fontWeight: 600 }} onClick={() => navigate(`/service-books/${serviceBook.id}/details`)} startIcon={<KeyboardArrowLeft />}>{t('Back')}</Button>
      <SidePanel open={ state.open } close={closePanel}>
        <AddEditServicePlanItemType
          serviceBookId={ serviceBook.id }
          serviceBookType={ serviceBook.type }
          close={ closePanel }
          item={  state.edit ? servicePlan.find(row => row.id === state.edit) : undefined }
        />
      </SidePanel>
      <SidePanel open={ state.listItems } close={closePanel}>
        <ServicePlanItems
          serviceBook={ serviceBook }
          close={ closePanel }
          item={  state.edit ? servicePlan.find(row => row.id === state.edit) : undefined }
        />
      </SidePanel>
      {
        servicePlan && servicePlan.length > 0 &&

          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Description')}</TableCell>
                  <TableCell>{t('Costs')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                servicePlan.map(row => {
                  let interval_unit = t('d');
                  let readable_interval = row.interval;
                  if (row.interval > 30 && row.interval < 365) {
                    interval_unit = t('mth');
                    readable_interval = row.interval / 30;
                  } else if (row.interval >= 365) {
                    interval_unit = t('yr');
                    readable_interval = Math.round(row.interval / 365);
                  }
                  return (
                    <TableRow key={`${row.id}_row`}>
                      <TableCell>{ row.name }</TableCell>
                      <TableCell>{ row.description }</TableCell>
                      <TableCell>{ row.cost } EUR</TableCell>
                      <TableCell>
                        <IconButton onClick={ () => setState({ ...state, open: true, edit: row.id })}><EditRounded /></IconButton>
                        <ConfirmableButton onSubmit={() => deleteItem(row.id)} />
                      </TableCell>
                    </TableRow>
                  );
                })
              }

              </TableBody>
            </Table>
        }
        <Box sx={{ mt: 2, width: '100%', ml: 'auto', textAlign: 'right', }}>
          <Box>
            <Button color="secondary" onClick={ () => setState({ ...state, open: true }) } startIcon={ <AddRounded />}>{t('Add new service plan item')}</Button>
          </Box>
          <Button color="secondary" onClick={ () => setState({ ...state, listItems: true }) } startIcon={ <AddRounded />}>{t('Select from service plan items')}</Button>
        </Box>
    </Container>
  );
}