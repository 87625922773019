import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { ProfileType, updateOwnProfileAction } from '../../redux/profile/profileSlice';

type PropsType = {
  details: ProfileType | null,
  cancel: () => void,
};

const EditProfileComponent = (props: PropsType) => {

  const [state, setState] = useState<Partial<ProfileType>>({ });
  const dispatch = useAppDispatch();
  const { updated } = useAppSelector(store => store.profile);
  const { t } = useLocales();

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value, level: 'admin' });

  useEffect(() => {
    if (updated) {
      props.cancel();
    }
  }, [updated]);

  const submit = () => {
    dispatch(updateOwnProfileAction({ ...state }));
  };

  return (

    <Box>
      <TextField
        sx={{ mt: 2 }}
        label={t('Name')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label={t('Phone')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, props.details, 'phone') }
        onChange={(e) => handleChange('phone', e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <Button onClick={props.cancel}>{t('Cancel')}</Button>
        <Button variant="contained" onClick={submit}>{t('Submit')}</Button>
      </Box>

    </Box>
  );
};

export default EditProfileComponent;