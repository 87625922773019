import { Container, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams, Location } from 'react-router-dom';
import { containerPaperStyle, tabsStyle, tabStyle } from '../../../styles/themeStylesSx';
import { useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';

export default function AdminContainer(props: any) {
  const { t } = useLocales();
  const location: Location = useLocation();
  const navigate = useNavigate();

  const { profile } = useAppSelector(store => store.profile);

  const [value, setValue] = React.useState('');

  useEffect(() => {
    let last = '';
    const parts = location.pathname.split('/');
    last = parts[parts.length - 1];
    if (last === 'admin') last = '';
    if (parts.length > 2) last = parts[2];
    if (last !== value) {
      setValue(last);
    }
  }, [location, value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/admin/${newValue}`);
    setValue(newValue);
  };
  if (!profile || profile.level !== 'admin') {
    return <>404 - {t('Page not found')}</>;
  }
  return (
    <Container>
      <Typography variant="h3">{t('Admin')}</Typography>
      <Tabs sx={ tabsStyle } value={value} onChange={handleChange} aria-label="Admin tabs">
        <Tab sx={ tabStyle } value="" label={t('Dashboard')} />
        <Tab sx={ tabStyle } value="customers" label={t('Customers')} />
        <Tab sx={ tabStyle } value="service-items" label={t('Service items')} />
        <Tab sx={ tabStyle } value="users" label={t('Users')} />
        <Tab sx={ tabStyle } value="subscriptions" label={t('Subscriptions')} />
      </Tabs>
      <Paper sx={ containerPaperStyle }>
        <Outlet />
      </Paper>
    </Container>
  );
}