import { Alert, Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoginPage from "../components/login/LoginPage";
import { useAppDispatch, useAppSelector } from '../hooks';
import { getCustomer } from '../redux/customer/customerSlice';
import { getProfile, setTokenAction, registerProfileAction } from '../redux/profile/profileSlice';
import { useAuth0 } from "@auth0/auth0-react";
import { layoutWidths } from "../layout";
import useLocales from '../hooks/useLocales';

type Props = {
  children: JSX.Element,
}

// Logic to check if authenticated and base details loaded
export default function AuthenticationContainer({ children }: Props) {
  const { user, isAuthenticated, getAccessTokenSilently, logout, loginWithRedirect, getIdTokenClaims } = useAuth0();
  const dispatch = useAppDispatch();
  const { t } = useLocales();
  const { loaded, loading, details, reload, error: customerError } = useAppSelector(store => store.customer);
  const { profile, token: tokenFromStore, loading: loadingProfile, loaded: loadedProfile, registering, error: profileError } = useAppSelector(store => store.profile);
  const [state, setState] = useState({ loading: true });
  const [showLogin, setShowLogin] = useState(false);

  // Handling for /login path to forward user automatically to login page.
  useEffect(() => {
    const splitUrl = window.location?.href?.split('/');
    if (splitUrl[splitUrl.length - 1] === 'register') {
      loginWithRedirect({ screen_hint: 'signup' });
    }
    if (splitUrl[splitUrl.length - 1] === 'login' || (splitUrl.length === 4 && splitUrl[splitUrl.length - 1] === '')) {
      loginWithRedirect();
    }
    const timeout = setTimeout(() => {
      setShowLogin(true);
    }, 2000);
    return () => clearTimeout(timeout);

  }, []);

  useEffect(() => {
    // should check whether loggedin, fetch customer etc. If any fails, show login page
    const getAndSetToken = async () => {
      let token;
      try {
        // console.log('start fetching the token');
        const claims = await getIdTokenClaims();
        token = claims?.__raw;
        if (token) {
          // console.log('Set token action', token);
          dispatch(setTokenAction(token));
        }
      } catch (error) {
        // no login
        console.error('Error with token', error);
      }

      setState({ loading: false });
    };

    setState({ loading: true });
    // console.log('get accesstoken silently');
    getAndSetToken();
  }, [dispatch, getAccessTokenSilently, user]);


  useEffect(() => {
    //console.log('tokenFromStore useEffect', tokenFromStore);
    if (tokenFromStore) {
      //console.log('tokenFromStore useEffect, getProfile, we got token');
      dispatch(getProfile());
    }
  }, [dispatch, tokenFromStore]);

  useEffect(() => {
    // console.log('profileGotUseEffect Profile, reload changed?', profile, reload);
    if (profile && profile.email && reload) {
      // console.log('profileGotUseEffect We got profile, email and customer is reload, fetch customer');
      dispatch(getCustomer());
    }
  }, [dispatch, profile, reload]);

  useEffect(() => {
    if (!details && user && loadedProfile) {
      dispatch(registerProfileAction());
    }
  }, [details, user, loadedProfile]);

  const clickLogout = () => {
    logout({ returnTo: 'https://www.kodinkanta.fi/palautesivu/' });
  };

  const showLoader = (loading || loadingProfile || state.loading === true || !showLogin) || (!details && user);

  if (showLoader) {
    return (<Box sx={{ width: '150px', ml: 'auto', mr: 'auto', mt: '5%'}}>
      <CircularProgress size={150} />
    </Box>);
  }
  if (!details && ((profileError && profileError === 'Network Error') || (customerError && customerError === 'Network Error'))) {
    return (<Box sx={{
      ...layoutWidths,
      ml: 'auto', mr: 'auto', mt: '5%'
    }}>
      <Alert severity='error' sx={{ p: 2 }}>
        {t('There seems to be technical issue, reload page or try again later')}
      </Alert>
    </Box>);
  }

  if (!isAuthenticated && !loading) {
    return <Box sx={{
      ...layoutWidths, ml: 'auto', mr: 'auto', mt: '5%'
    }}>
      <LoginPage />
    </Box>;
  }

  return children;
}