/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddRounded, VisibilityRounded, NotesRounded, NotInterestedRounded } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';

import { Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getAdminCustomersAction, deleteAdminCustomer } from '../../redux/admin/adminCustomerSlice';
import SidePanel from '../sidepanel/SidePanel';
import CustomerAdminComponent from './CustomerAdminComponent';
import CustomerAdminNotes from './CustomerAdminNotes';
import ConfirmableButton from '../buttons/ConfirmableButton';
import AddEditAdminCustomersUsers from './AddEditAdminCustomersUsers';
import AddEditAdminCustomer from './AddEditAdminCustomer';
import { CustomerType } from '../../types/customer';
import { colors } from '../../../styles/themeStylesSx';
import { parseISO } from 'date-fns/esm';
import { differenceInDays, format } from 'date-fns';

type CustomerStateType = {
  open?: boolean;
  edit?: boolean;
  openUser?: boolean;
  openNotes?: boolean;
  customerId?: number;
  subscriptionFilter?: string;
};

export default function ListAdminCustomers() {
  const { t } = useLocales();
  const [state, setState] = useState<CustomerStateType>({ open: false, edit: false, openUser: false, openNotes: false, customerId: 0, subscriptionFilter: 'all' });
  const { list, loading, reload, saving } = useAppSelector(store => store.adminCustomer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (reload && !loading) {
      dispatch(getAdminCustomersAction());
    }
  }, [reload]);

  const deleteItem = (id?: number) => {
    if (!id) {
      return;
    }
    dispatch(deleteAdminCustomer({ id }));
  };
  if(loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      { state.openUser &&
        <SidePanel
          open={ state.openUser }
          close={ () => setState({ openUser: false })}
        >
          <AddEditAdminCustomersUsers saving={ saving } customer={list?.find(row => row.id === state.customerId )} cancel={ () => setState({ ...state, openUser: false }) } />
        </SidePanel>
      }
      { state.open &&
        <SidePanel
          open={ state.open }
          close={ () => setState({ open: false })}
        >
          <AddEditAdminCustomer customer={ list?.find((row: any) => row.id === state.customerId )} cancel={ () => setState({ open: false }) } />
        </SidePanel>
      }
      { state.edit &&
        <SidePanel
          open={ state.edit }
          close={ () => setState({ edit: false })}
        >
          <CustomerAdminComponent  customer={ list?.find(row => row.id === state.customerId )} close={ () => setState({ edit: false }) }  />
        </SidePanel>
      }
      { state.openNotes &&
        <SidePanel
          open={ state.openNotes }
          close={ () => setState({ openNotes: false })}
        >
          <CustomerAdminNotes customer = { list?.find(row => row.id === state.customerId )} close={ () => setState({ openNotes: false }) }  />
        </SidePanel>
      }

      { loading && <Skeleton animation="wave" />}
      <Paper sx={{ p: 2 }}>

      <FormControl
        sx={{ mt: 1, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('Filter by subscription')}</InputLabel>
        <Select
          label={t('Filter by subscription')}
          name="subscriptionFilter"
          value={ state.subscriptionFilter }
          onChange={(e) => setState({ ...state, subscriptionFilter: e.target.value })}
        >
          <MenuItem value='all'>{t('All')}</MenuItem>
          <MenuItem value='none'>{t('No subscription')}</MenuItem>
          <MenuItem value='expired'>{t('Expired test users')}</MenuItem>
          <MenuItem value='any'>{t('Any subscription')}</MenuItem>
        </Select>
      </FormControl>
      </Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Identifier')}</TableCell>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Description')}</TableCell>
            <TableCell>{t('Subscription')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list?.filter(row => {
              switch (state.subscriptionFilter) {
                case 'all':
                default:
                  return true;
                case 'none':
                  return !row.subscription &&  differenceInDays(new Date(), parseISO(row.createdAt)) < 30;
                case 'expired':
                  return !row.subscription &&  differenceInDays(new Date(), parseISO(row.createdAt)) > 30;
                case 'any':
                  return row.subscription && row.subscription.id;
              }
            }).map( (row: CustomerType, i: number) => {
              return (
                <TableRow sx={{ borderRadius: 1, backgroundColor: i % 2 ? colors.backgroundGreen : '#fff' }} key={`${row.id}_row`}>
                  <TableCell>{ row.id }</TableCell>
                  <TableCell>{ row.name  }</TableCell>
                  <TableCell>{ row.description }</TableCell>
                  <TableCell>
                     { row.subscription ? t(row.subscription.name) : <NotInterestedRounded /> }
                  </TableCell>
                  <TableCell>
                    {
                      row.createdAt && new Date(row.createdAt) ? format(parseISO(row.createdAt), 'yyyy-MM-dd') : 'Not set'
                    }
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex' }}>
                      <IconButton onClick={() => setState({ openNotes: true, customerId: row.id })}><NotesRounded /></IconButton>
                      <IconButton onClick={() => setState({ edit: true, customerId: row.id })}><VisibilityRounded /></IconButton>
                      <Button onClick={() => setState({ openUser: true,  customerId: row.id })} startIcon={<GroupIcon />}></Button>
                      <ConfirmableButton onSubmit={ () => deleteItem(row.id) } />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      <Box>
        <Box sx={{ mt: 2, ml: 'auto', width: '200px' }}>
          <Button
            color="secondary"
            onClick={() => setState({ open: true }) }
            startIcon={<AddRounded />}
          >{t('Add new customer')}</Button>
        </Box>
      </Box>
    </div>
  );
}