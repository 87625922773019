import { HelpRounded } from '@mui/icons-material';
import { Box, IconButton, SxProps } from '@mui/material';
import React, { useState } from 'react';
import SidePanel from '../sidepanel/SidePanel';

const ContextualHelp = ({ variant, styles }: { styles?: SxProps, variant: 'serviceBook' | 'fixedCosts' | 'documents' | 'receipts' | 'agreements' }) => {

  const [state, setState] = useState<{ showHelp: boolean }>({ showHelp: false });

  const validVarians = ['serviceBook', 'fixedCosts', 'documents', 'receipts', 'agreements'];

  if (!validVarians.includes(variant)) {
    return <></>;
  }

  const getLink = (variant: string) => {
    switch (variant) {
    case 'serviceBook':
      return 'https://www.kodinkanta.fi/oma-kodinkanta-ohje-huoltokirjat/';
    case 'fixedCosts':
      return 'https://www.kodinkanta.fi/oma-kodinkanta-ohje-kuluseuranta/';
    case 'documents':
      return 'https://www.kodinkanta.fi/oma-kodinkanta-ohje-dokumenttipankki/';
    case 'agreements':
      return 'https://www.kodinkanta.fi/oma-kodinkanta-ohje-sopimukset/';
    case 'receipts':
      return 'https://www.kodinkanta.fi/oma-kodinkanta-ohje-takuukuitit/';

    }
  };

  return (
    <Box sx={ styles || {} }>
      <SidePanel open={ state.showHelp } close={ () => setState({ ...state, showHelp: false })}>
        <Box>
          <iframe
            width='100%'
            height='600px'
            scrolling='yes'
            frameBorder='0'
            allowFullScreen
            src={ getLink(variant) }
          />
        </Box>
      </SidePanel>
      <Box sx={{ marginLeft: 'auto'}}>
        <IconButton onClick={() => setState({ ...state, showHelp: true })}>
          <HelpRounded />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ContextualHelp;