import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { addAdminServicePlan, updateAdminServicePlan } from '../../redux/admin/adminServicePlanSlice';
import { ServicePlanItemType } from '../../types/servicePlanItem';

type AddEditAdminServiceItemProp = {
  item?: Partial<ServicePlanItemType>,
  close: () => void
}


export default function AddEditAdminServiceItem (props: AddEditAdminServiceItemProp) {
  const { item } = props;
  const [state, setState] = useState<Partial<ServicePlanItemType>>({ forType: 'building', interval: 1, ...(item || {}) });
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { saved, saving } = useAppSelector(store => store.adminServicePlan);

  const handleChange = (name: string, value: number | string | null | undefined | boolean) => setState({ ...state, [name]: value });
  const handleIndoorChange = (name: string, value: boolean) => {
    if (state.indoorOrOutdoor === 'indoor' && name === 'indoor' && value === false) {
      setState({ ...state, indoorOrOutdoor: null });
    }
    else if (state.indoorOrOutdoor && name === 'outdoor' && value === false) {
      setState({ ...state, indoorOrOutdoor: null });
    }
    else {
      setState({ ...state, indoorOrOutdoor: name === 'indoor' ? 'indoor' : 'outdoor' });
    }
  };
  const submit = () => {
    const data = { ...state };
    if (item) {
       if (!item.id) {
         return;
       }
      dispatch(updateAdminServicePlan({ id: item.id, data }));
    }
    else {
      dispatch(addAdminServicePlan(data));
    }
  };

  useEffect(() => {
    if (saved) {
      setState({});
      props.close();
    }
  }, [saved, props]);

  useEffect(() => {
    if (props.item) {
      setState({ ...props.item });
    }
  }, [props.item]);

  return (
    <Box>
      <Typography variant="h6">
        { item ? t('Create new service plan item') : t('Edit service plan item') }
      </Typography>

      <FormControl
        sx={{ mt: 2, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('For service book type')}</InputLabel>
        <Select
          label={t('For service book type')}
          value={ getValueFromStateOrObject(state, item, 'forType') }
          onChange={(e: any) => handleChange('forType', e.target.value)}
        >
          <MenuItem value="building">{t('Building')}</MenuItem>
          <MenuItem value="vehicle">{t('Vehicle')}</MenuItem>
          <MenuItem value="other">{t('Other')}</MenuItem>
        </Select>

      </FormControl>
      <FormControl
        sx={{ mt: 2, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('Type')}</InputLabel>
        <Select
          label={t('Type')}
          value={ getValueFromStateOrObject(state, item, 'type') }
          onChange={(e: any) => handleChange('type', e.target.value)}
        >
          <MenuItem value="serviceCase">{t('Service case')}</MenuItem>
          <MenuItem value="inspectionCase">{t('Inspection')}</MenuItem>
        </Select>

      </FormControl>
      <TextField
        sx={{ mt: 1 }}
        label={t('Category')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, item, 'category') }
        onChange={(e) => handleChange('category', e.target.value)}
      />
       <TextField
        sx={{ mt: 1 }}
        label={t('Name')}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, item, 'name') }
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <TextField
       sx={{ mt: 1 }}
       label={t('Description')}
       variant="outlined"
       rows={5}
       multiline
       fullWidth
       value={ getValueFromStateOrObject(state, item, 'description') }
       onChange={(e) => handleChange('description', e.target.value)}
     />
     <p><b>{t('Seasons')}</b></p>
     <Box sx={{ mt: 1, mb: 1 }}>
      <FormControl>
        <FormControlLabel id="AddEditServiceItem-season-winter" label={t('Winter')} control={<Checkbox onChange={(e) => handleChange('winter', e.target.checked) } checked={ state.winter === true } />} />
        <FormControlLabel id="AddEditServiceItem-season-spring" label={t('Spring')} control={<Checkbox onChange={(e) => handleChange('spring', e.target.checked)} checked={ state.spring === true } />} />
        <FormControlLabel id="AddEditServiceItem-season-summer" label={t('Summer')} control={<Checkbox onChange={(e) => handleChange('summer', e.target.checked)} checked={ state.summer === true } />} />
        <FormControlLabel id="AddEditServiceItem-season-fall" label={t('Fall')} control={<Checkbox onChange={(e) => handleChange('fall', e.target.checked)} checked={ state.fall === true } />} />
      </FormControl>
    </Box>

    <TextField
       sx={{ mt: 2 }}
       label={`${t('Approximate cost')} (EUR)`}
       variant="outlined"
       type="number"
       fullWidth
       value={ getValueFromStateOrObject(state, item, 'cost') }
       onChange={(e) => handleChange('cost', parseFloat(e.target.value))}
     />
     <FormControl
        sx={{ mt: 2, mb: 1 }}
        fullWidth
      >
        <InputLabel>{t('Interval')}</InputLabel>
        <Select
          label={t('Interval')}
          name="interval"
          value={ getValueFromStateOrObject(state, item, 'interval') }
          onChange={(e: any) => handleChange('interval', parseInt(e.target.value))}
        >
          <MenuItem value={12}>
            1
          </MenuItem>
          <MenuItem value={6}>
            2
          </MenuItem>
          <MenuItem value={4}>
            3
          </MenuItem>
          <MenuItem value={3}>
            4
          </MenuItem>
        </Select>

        <FormHelperText>{t('How many times it should be done in year')}</FormHelperText>
      </FormControl>
      <FormGroup sx={{ mb: 1, mt: 1 }}>
        <FormControlLabel control={
          <Switch
            checked={ state.indoorOrOutdoor === 'indoor' } onChange={ (e) => handleIndoorChange('indoor', e.target.checked) } defaultChecked
          />
        } label={t('Indoors')} />

        <FormControlLabel control={
          <Switch
            checked={ state.indoorOrOutdoor === 'outdoor' } onChange={ (e) => handleIndoorChange('outdoor', e.target.checked) }
          />
        } label={t('Outdoors')} />

        <FormHelperText>{t('Unselect both to not matter')}</FormHelperText>
      </FormGroup>
      {
        saving ? <CircularProgress /> :
          <Button onClick={submit} variant="contained">
              { props.item ? t('Save') : t('Add') }
          </Button>
      }


    </Box>
  );
}