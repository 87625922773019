import { AddRounded, VisibilityRounded } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { groupBy, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { addButtonStyle, colors } from '../../../styles/themeStylesSx';
import { valueAsDate } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getDocuments } from '../../redux/documents/documentsSlice';
import { DocumentType } from '../../types/documents';
import AttachmentsIcon from '../attachments/AttachmentsIcon';
import SkeletonRows from '../layout/SkeletonRows';
import SidePanel from '../sidepanel/SidePanel';
import AddEditDocument from './AddEditDocument';
import DocumentComponent from './DocumentComponent';

type DocumentStateType = {
  open?: boolean;
  documentId?: number;
  add?: boolean;
};

export default function ListDocuments() {
  const { t } = useLocales();


  const [state, setState] = useState<DocumentStateType>({ open: false, add: false });
  const { list, loading, loaded, reload } = useAppSelector(store => store.documents);
  const { details: customer } = useAppSelector(store => store.customer);
  const dispatch = useAppDispatch();

  const documentsPerBook = groupBy(list, 'serviceBookId');

  useEffect(() => {
    dispatch(getDocuments());
  }, []);

  useEffect(() => {
    if (reload && !loaded && !loading) {
      dispatch(getDocuments());
      setState({ open: false });
    }
  }, [reload, loading, loaded]);

  return (
    <div>
      <SidePanel
        open={ state.add === true }
        close={ () => setState({ add: false })}
      >
        <Box sx={{ ml: 2 }}>
          <AddEditDocument cancel={ () => setState({ add: false }) } />
        </Box>
      </SidePanel>

      <SidePanel
        open={ state.open === true }
        close={ () => setState({ open: false })}
      >
        <Box sx={{ ml: 2 }}>
          <DocumentComponent document={ list.find(row => row.id === state.documentId )} close={ () => setState({ open: false })} />
        </Box>
      </SidePanel>

      { loading && <SkeletonRows rows={5} /> }

      <Box sx={{ display: { xs: 'block', md: 'none' }}}>
        {
          list.map((row, idx) => {
            const dateValue = valueAsDate(row.date);
            const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';

            return (<Paper key={`document_${idx}`} sx={{ p: 1, mt: 1, mb: 1, lineHeight: 1.5, background: backgroundColor }}>
              <Box sx={{ display: 'flex' }}>
                <Box title={`${row.id}`}>
                  {idx + 1}: { dateValue ? format(dateValue, 'dd.MM.yyyy') : '' } { row.description }
                  { row.serviceBook && <Box sx={{ my: 0.25}}>{t('Service book')}: { row.serviceBook.name } </Box>}
                </Box>
                <Box sx={{ ml: 'auto'}}>
                  <IconButton onClick={() => setState({ open: true, documentId: row.id })}><VisibilityRounded /></IconButton>
                </Box>
              </Box>
            </Paper>);
          })
        }
      </Box>

      {
        Object.keys(documentsPerBook).map(key => key === 'null' ? 0 : +key).sort((a, b) => a - b).map((serviceBookKeyNumber: number) => {
          const serviceBookKey = serviceBookKeyNumber === 0 ? 'null' : `${serviceBookKeyNumber}`;
          const documents = documentsPerBook[serviceBookKey] as DocumentType[];
          if (!documents || documents.length === 0) {
            return <></>;
          }
          const groupName = +serviceBookKey > 0 ? `${t('Service book')}: ${documents[0].serviceBook?.name}` : t('Documents of customer');
          return (
            <Box key={`documents_for_${serviceBookKey}`} sx={{ display: { xs: 'none', md: 'block' }}}>
              <Typography variant='h6'>{groupName}</Typography>
              <Paper sx={{ my: 2, p: 2 }}>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Date')}</TableCell>
                      <TableCell>{t('Description')}</TableCell>
                      <TableCell>{t('Attachment')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      documents.map( (row, idx) => {
                        const dateValue = valueAsDate(row.date);
                        const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
                        return (
                          <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main', backgroundColor }} key={`${row.id}_row`}>
                            <TableCell>{ dateValue ? format(dateValue, 'dd.MM.yyyy') : '-' }</TableCell>
                            <TableCell>{ row.description }</TableCell>
                            <TableCell>
                              <AttachmentsIcon attachments={ row.attachments } />
                            </TableCell>
                            <TableCell sx={{
                              borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                              <IconButton sx={{p: 0}} onClick={() => setState({ open: true, documentId: row.id })}><VisibilityRounded /></IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </Paper>
            </Box>
          );
        })
      }
      {
        list.length === 0 &&
          <Paper sx={{ p: 2, my: 2}}>
            <Box sx={{ p: 2 }}>{t('No documents')}</Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                disabled={!customer?.isActive}
                sx={ addButtonStyle } variant="outlined" onClick={() => setState({ add: true })} endIcon={<AddRounded />}
              >
                {t('Add new document')}
              </Button>
            </Box>
          </Paper>
      }
      {
        list.length > 0 &&
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              disabled={!customer?.isActive}
              sx={ addButtonStyle } variant="outlined" onClick={() => setState({ add: true })} endIcon={<AddRounded />}
            >
              {t('Add new document')}
            </Button>
          </Box>
      }
    </div>
  );
}