import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

export default function SkeletonRows(props: { rows: number}) {
  return (<Box sx={{ mt: 2, mb: 2 }}>
    {
      [...new Array(props.rows)].map((_: any, idx: number) => 
        <Skeleton sx={{ height: '2em' }} animation="wave" key={ `${idx}_row` } />
      )
    }
  </Box>);
} 