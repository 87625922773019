import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { RootState } from '../../store';
import { ServiceBookType, ServiceCaseType } from '../../types/serviceBook';
import { isIncluded, notIncluded } from '../reduxHelper';
import { ServicePlanItemType } from '../../types/servicePlanItem';
import { parseError } from '../../helpers/helpers';

export type ServiceBookCalendarData = {
  datakey: string,
  data: ServiceBookCalendarItem[],
};

export type ServiceBookCalendarItem = {
  servicePlanId: number;
  serviceCase?: ServiceCaseType;
  season: string;
  name: string;
  description: string;
}
export interface ServiceBookState {
  list: ServiceBookType[];
  loading: number[];
  loaded: number[];
  saving: number[];
  saved: number[];
  error: any;
  planError: any;
  deleting: boolean;
  deleted: boolean;
  adding: boolean;
  added: boolean;
  loadingCalendar?: boolean;
  loadedCalendar?: boolean;
  calendar: ServiceBookCalendarData[];
}

const initialState: ServiceBookState = {
  list: [],
  loading: [],
  loaded: [],
  saving: [],
  saved: [],
  error: null,
  planError: null,
  deleting: false,
  deleted: false,
  adding: false,
  added: false,
  calendar: [],
};

export const getServiceBookForId = createAsyncThunk<any, number>('servicebook/get_for_id', async (serviceBookId: number, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${serviceBookId}`, method: 'GET', thunkApi });
});

export const deleteServiceBookAction = createAsyncThunk<any, number>('servicebook/delete', async (serviceBookId: number, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${serviceBookId}`, method: 'DELETE', thunkApi });
});

export const saveServicePlanForServiceBook = createAsyncThunk('servicebook/update_plan', async (params: { id: number, data: Partial<ServicePlanItemType>}, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.id}/plan`, data: params.data, method: 'POST', thunkApi});
});

export const deleteServiceBookPlan = createAsyncThunk('servicebook/delete_plan', async (params: { serviceBookId: number, planId: number }, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/plan/${params.planId}`, method: 'DELETE', thunkApi });
});

export const updateServiceBook = createAsyncThunk('servicebook/update', async (params: Partial<ServiceBookType>, thunkApi: any) => {
  if (!params.id) {
    throw new Error('Id is required param');
  }
  return await apiRequest({ path: `/service-book/${params.id}`, method: 'PATCH', data: params, thunkApi });
});

export const addServiceBookAction = createAsyncThunk('servicebook/add', async (params: Partial<ServiceBookType>, thunkApi: any) => {
  return await apiRequest({ path: `/service-book`, method: 'POST', data: params, thunkApi });
});

export const addServiceCaseAction = createAsyncThunk('servicecase/add', async (params: { serviceBookId: number, data: Partial<ServiceCaseType> }, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/service-case`, method: 'POST', data: params.data, thunkApi });
});

export const updateServiceCaseAction = createAsyncThunk('servicecase/update', async (params: { id: number, serviceBookId: number, data: Partial<ServiceCaseType>}, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/service-case/${params.id}`, data: params.data, thunkApi, method: 'PATCH' });
});

export const deleteServiceCaseAction = createAsyncThunk('servicecase/delete', async (params: { id: number, serviceBookId: number }, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/service-case/${params.id}`, thunkApi, method: 'DELETE' });
});

export const getServiceCaseCalendarForSeason = createAsyncThunk('servicecase/calendar', async (params: { serviceBookId: number, season: string, year: number}, thunkApi: any) => {
  return await apiRequest({ path: `/service-book/${params.serviceBookId}/service-calendar/${params.year}/${params.season}`, thunkApi, method: 'GET' });
});




export const serviceBookSlice = createSlice({
  name: 'serviceBooks',
  initialState,
  reducers: {
    clearAddedOrDeletedAction: (state) => {
      state.added = false;
      state.deleted = false;
    },
    clear: (state) => {
      state.list = [];
      state.loaded = [];
    }
  },
  extraReducers: (builder) => {

    // getServiceBookForId
    builder.addCase(getServiceBookForId.pending, (state, action) => {
      const id = action.meta.arg;
      state.error = null;
      state.loading = isIncluded(state.loading, id);
      state.loaded = notIncluded(state.loaded, id);
      state.saved = [];
    });
    builder.addCase(getServiceBookForId.fulfilled, (state, action: PayloadAction<ServiceBookType>) => {
      state.loading = [];
      state.loaded = isIncluded(state.loaded, action.payload.id);
      const data = action.payload;
      const workstate = state.list.filter(row => row.id !== action.payload.id);
      workstate.push(action.payload);
      state.list = workstate;
    });
    builder.addCase(getServiceBookForId.rejected, (state, action) => {
      console.log('Error fetching servicebook', action);
      state.loaded = [];
      state.loading = [];
      state.error = parseError(action.error);
    });

    //deleteServiceBookAction
    builder.addCase(deleteServiceBookAction.pending, (state) => {
      state.deleting = true;
    });
    builder.addCase(deleteServiceBookAction.fulfilled, (state) => {
      state.deleting = false;
      state.deleted = true;
      state.loaded = [];
      state.loading = [];
    });
    builder.addCase(deleteServiceBookAction.rejected, (state) => {
      state.deleting = false;
      state.deleted = false;
    });

    // saveServicePlanForServiceBook
    builder.addCase(saveServicePlanForServiceBook.pending, (state, action) => {
      const id = action.meta.arg.id;
      state.saving = isIncluded(state.saving, id);
      state.saved = notIncluded(state.saved, id);
    });
    builder.addCase(saveServicePlanForServiceBook.fulfilled, (state, action) => {
      const id = action.meta.arg.id;
      state.saving = notIncluded(state.saving, id);
      state.saved = isIncluded(state.saving, id);
      state.loaded = notIncluded(state.loaded, id);
    });
    builder.addCase(saveServicePlanForServiceBook.rejected, (state, action) => {
      const id = action.meta.arg.id;
      state.saved = notIncluded(state.saved, id);
      state.saving = notIncluded(state.saving, id);
      state.planError = parseError(action.error);
    });

    // updateServiceBook
    builder.addCase(updateServiceBook.pending, (state, action) => {
      const id = action.meta.arg.id;
      if (!id) {
        return;
      }
      state.saved = notIncluded(state.saved, id);
      state.saving = isIncluded(state.saving, id);
    });
    builder.addCase(updateServiceBook.fulfilled, (state, action) => {
      const id = action.meta.arg.id;
      if (!id) {
        return;
      }
      state.saved = isIncluded(state.saved, id);
      state.saving = notIncluded(state.saving, id);
      state.loaded = notIncluded(state.loaded, id);
    });
    builder.addCase(updateServiceBook.rejected, (state, action) => {
      const id = action.meta.arg.id;
      if (!id) {
        return;
      }
      state.saved = notIncluded(state.saved, id);
      state.saving = notIncluded(state.saving, id);
      state.error = parseError(action.error);
    });

    // addServiceBook
    builder.addCase(addServiceBookAction.pending, (state, action) => {
      state.adding = true;
      state.added = false;
    });
    builder.addCase(addServiceBookAction.fulfilled, (state, action) => {
      state.adding = false;
      state.added = true;
    });
    builder.addCase(addServiceBookAction.rejected, (state, action) => {
      state.adding = false;
      state.added = false;
      state.error = parseError(action.error);
    });

    // deleteServiceBookPlan
    builder.addCase(deleteServiceBookPlan.pending, (state, action) => {
      state.deleting = true;
    });
    builder.addCase(deleteServiceBookPlan.fulfilled, (state, action) => {
      state.deleting = false;
      const id = action.meta.arg.serviceBookId;
      state.loaded = notIncluded(state.loaded, id);
    });

    builder.addCase(addServiceCaseAction.pending, (state, action) => {
      state.adding = true;
      state.added = false;
    });
    builder.addCase(addServiceCaseAction.fulfilled, (state, action) => {
      state.adding = false;
      state.added = true;
      const bookId = action.meta.arg?.serviceBookId;
      if (bookId) {
        state.loaded = notIncluded(state.loaded, bookId);
      }
      state.calendar = [];
    });
    builder.addCase(addServiceCaseAction.rejected, (state, action) => {
      state.adding = false;
      state.added = false;
    });

    builder.addCase(updateServiceCaseAction.pending, (state, action) => {
      state.adding = true;
      state.added = false;
      const bookId = action.meta.arg?.serviceBookId;
      if (bookId) {
        state.loaded = notIncluded(state.loaded, bookId);
      }
    });
    builder.addCase(updateServiceCaseAction.fulfilled, (state, action) => {
      state.adding = false;
      state.added = true;
      state.calendar = [];
      const bookId = action.meta.arg?.serviceBookId;
      if (bookId) {
        state.loaded = notIncluded(state.loaded, bookId);
      }
    });
    builder.addCase(updateServiceCaseAction.rejected, (state, action) => {
      state.adding = false;
      state.added = false;
    });

    builder.addCase(deleteServiceCaseAction.pending, (state, action) => {
      state.deleting = true;
    });
    builder.addCase(deleteServiceCaseAction.fulfilled, (state, action) => {
      state.deleting = false;
      const bookId = action.meta.arg?.serviceBookId;
      if (bookId) {
        state.loaded = notIncluded(state.loaded, bookId);
      }
      state.calendar = [];
    });
    builder.addCase(deleteServiceCaseAction.rejected, (state, action) => {
      state.deleting = false;
    });

    builder.addCase(getServiceCaseCalendarForSeason.pending, (state, action) => {
      state.loadingCalendar = true;
    });
    builder.addCase(getServiceCaseCalendarForSeason.fulfilled, (state, action) => {
      const key = `${action.meta.arg.year}-${action.meta.arg.season}-${action.meta.arg.serviceBookId}`;
      state.loadedCalendar = true;
      state.loadingCalendar = false;
      if (state.calendar && state.calendar.length >= 0 && action.payload && action.payload.length > 0) {
        state.calendar = [...state.calendar?.filter(row => row.datakey !== key), { datakey: key, data: action.payload }];
      }
      else {
        state.calendar = [...state.calendar?.filter(row => row.datakey !== key), { datakey: key, data: [] }];
      }
    });
  }
});

export const { clearAddedOrDeletedAction, clear: clearServiceBookState } = serviceBookSlice.actions;

export const selectServiceBook = (state: RootState) => state.serviceBook.list;

export default serviceBookSlice.reducer;
