import { AddCircleRounded, AddRounded, InfoRounded } from "@mui/icons-material";
import { Card, CardContent, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Button, IconButton, TableFooter } from "@mui/material";
import { subYears } from "date-fns";
import { useState } from "react";
import { roundToTwoDecimals } from "../../helpers/helpers";
import useLocales from "../../hooks/useLocales";
import { FixedCost, FixedCostType } from "../../types/costs";
import { ServiceBookType } from "../../types/serviceBook";
import SidePanel from "../sidepanel/SidePanel";
import AddEditFixedCost from "./AddEditFixedCost";
import { orderBy } from 'lodash';
import { colors, headerStyles } from "../../../styles/themeStylesSx";
import { useAppSelector } from '../../hooks';

type Props = {
  serviceBook: ServiceBookType,
  costs: FixedCostType[],
}

export default function ServiceBookFixedCosts(props: Props) {
  const { serviceBook, costs } = props;
  const { t } = useLocales();
  const [state, setState] = useState<{ open: boolean, openId?: number, typeId?: number }>({ open: false });

  const { details: customer } = useAppSelector(store => store.customer);

  const close = () => {
    setState({ ...state, open: false });
  };

  const toggleCost = (costType: FixedCostType, cost?: FixedCost) => {
    if (cost) {
      setState({ ...state, open: true, openId: cost.id, typeId: costType.id });
    }
    else {
      setState({ ...state, open: true, typeId: costType.id });
    }
  };

  let lastYeartotal = 0;
  let thisYearTotal = 0;

  const currentYear = new Date().getFullYear();
  const lastYearYear = subYears(new Date(), 1).getFullYear();
  const yearBeforeYear = (subYears(new Date(), 2)).getFullYear();

  return (
    <>
      <SidePanel open={ state.open } close={ close }>
        <AddEditFixedCost
          cost={ state.openId ? costs.find(row => row.id === state.openId) : undefined }
          typeId={ state.typeId || undefined }
          costTypes={ (costs || []).filter(row => !row.dynamic) }
          close={ close }
          serviceBookId={ serviceBook.id }
        />
      </SidePanel>
      <Card
        key={`book_${serviceBook.id}`}
        sx={{
          width: '100%',
          p: { xs: 0.2, md: 2 }, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 2 }, mt: 2,
          fontSize: { xs: '0.9em' }
        }}
      >
        <CardContent sx={{ textAlign: 'center',}}>
          <Typography sx={{ ...headerStyles, display: 'flex' }}>
            { serviceBook.name }
            {
              costs && costs.length > 1 &&

                <Box sx={{ ml: 'auto' }}>
                  <IconButton
                    disabled={!customer?.isActive}
                    color="primary"
                    onClick={() => setState({ open: true })}
                  >
                    <AddCircleRounded fontSize="large" />
                  </IconButton>

                </Box>
            }
          </Typography>
          {
            costs && costs.length === 0 && <>{t('No cost types') }</>
          }
          {
            costs && costs.length > 0 &&
              <>
                <Box sx={{ textAlign: 'left', display: { md: 'none', xs: 'block' }}}>
                  {
                    orderBy(costs, 'name', 'asc').map( (costType: FixedCostType) => {
                      const lastYear = costType.fixedCosts.find(row => row.year && row.year === lastYearYear);
                      const yearBefore = costType.fixedCosts.find(row => row.year && row.year === yearBeforeYear);
                      const previousYear = lastYear || yearBefore;
                      const currentMonth = (new Date()).getMonth() + 1;
                      let thisYearValue = 0;
                      const averagePerMonth = lastYear && lastYear.total ? roundToTwoDecimals(lastYear.total / 12) : 0;
                      if (costType.dynamic === true) {
                        const thisYear = costType.fixedCosts.find(row => row.year && row.year === (new Date()).getFullYear());
                        thisYearValue = thisYear && thisYear.total ? thisYear.total : 0;
                      }
                      else {
                        thisYearValue = lastYear && lastYear.total ? roundToTwoDecimals(lastYear.total / 12 * currentMonth) : 0;
                      }
                      thisYearTotal += thisYearValue;
                      lastYeartotal += parseFloat(previousYear && previousYear.total ? `${previousYear.total}` : '0');
                      return (
                        <Box key={`type_${costType.id}`} sx={{ color: 'secondary.main', borderBottom: 'thin solid black' }}>
                          <Typography sx={{ mt: 2, mb: 1}} variant="h5">{costType.name}</Typography>
                          <Box sx={{ pb: 2, display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ width: '32%' }}>{t('Month (estimate)')} <br /><b>{ averagePerMonth}</b></Box>
                            <Box sx={{ width: '32%' }}>{ lastYearYear } <br /><b>{ lastYear ? lastYear.total : '-' }</b></Box>
                            <Box sx={{ width: '32%' }}>{ currentYear }<br /><b>{ thisYearValue }</b></Box>
                          </Box>
                        </Box>
                      );
                    })
                  }
                  <Box sx={{ backgroundColor: colors.backgroundGreen, borderRadius: 1, mt: 2, p: 2, color: 'secondary.main', borderBottom: 'thin solid black' }}>
                    <Typography sx={{ mt: 2, mb: 1}} variant="h5">{t('Total')}</Typography>
                    <Box sx={{ pb: 2, display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ width: '32%' }}>{t('Month (estimate)')} <br /><b>{ roundToTwoDecimals(lastYeartotal / 12) }</b></Box>
                      <Box sx={{ width: '32%' }}>{ lastYearYear } <br /><b>{ roundToTwoDecimals(lastYeartotal) }</b></Box>
                      <Box sx={{ width: '32%' }}>{ currentYear }<br /><b>{ roundToTwoDecimals(thisYearTotal) }</b></Box>
                    </Box>
                  </Box>
                </Box>
                <Table sx={{ display: { md: 'table', xs: 'none' }}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Name')}</TableCell>
                      <TableCell>{t('Per month (estimate)')}</TableCell>{}
                      <TableCell>{t('Previous year')}</TableCell>
                      <TableCell sx={{ display: { md: 'table-cell', xs: 'none' }}}>
                        {t('Current year')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      orderBy(costs, 'name', 'asc').map( (costType: FixedCostType) => {
                        const lastYear = costType.fixedCosts.find(row => row.year && row.year === (subYears(new Date(), 1)).getFullYear());
                        const yearBefore = costType.fixedCosts.find(row => row.year && row.year === (subYears(new Date(), 2)).getFullYear());
                        const previousYear = lastYear || yearBefore;
                        const currentMonth = (new Date()).getMonth() + 1;
                        let thisYearValue = 0;
                        const averagePerMonth = lastYear && lastYear.total ? roundToTwoDecimals(lastYear.total / 12) : 0;
                        if (costType.dynamic === true) {
                          const thisYear = costType.fixedCosts.find(row => row.year && row.year === (new Date()).getFullYear());
                          thisYearValue = thisYear && thisYear.total ? thisYear.total : 0;
                        }
                        else {
                          thisYearValue = lastYear && lastYear.total ? roundToTwoDecimals(lastYear.total / 12 * currentMonth) : 0;
                        }
                        return (
                          <TableRow key={`fixed_cost${costType.id}`}>
                            <TableCell>{ costType.name }</TableCell>
                            <TableCell>
                              { averagePerMonth }
                            </TableCell>
                            <TableCell>
                              { lastYear && roundToTwoDecimals(lastYear.total) }
                              {
                                !lastYear && previousYear && <>{ roundToTwoDecimals(previousYear.total)}<IconButton onClick={() => toggleCost(costType)}><InfoRounded /></IconButton></>
                              }
                              {
                                !lastYear && !previousYear && !costType.dynamic && <Box> <IconButton disabled={!customer?.isActive} onClick={() => toggleCost(costType)}><InfoRounded /></IconButton></Box>
                              }
                            </TableCell>
                            <TableCell sx={{ display: { md: 'table-cell', xs: 'none' }}}>
                              { thisYearValue }
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        {t('Total')}
                      </TableCell>
                      <TableCell>
                        { roundToTwoDecimals(lastYeartotal / 12) }
                      </TableCell>
                      <TableCell>
                        { roundToTwoDecimals(lastYeartotal) }
                      </TableCell>
                      <TableCell sx={{ display: { md: 'table-cell', xs: 'none' }}}>
                        { roundToTwoDecimals(thisYearTotal) }
                      </TableCell>
                    </TableRow>

                  </TableFooter>
                </Table>
              </>
          }
        </CardContent>
      </Card>
    </>
  );
}