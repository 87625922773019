
import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import useLocales from '../hooks/useLocales';
import { containerPaperStyle } from '../../styles/themeStylesSx';

export default function ProfileContainer(props: any) {
  const { t } = useLocales();

  const { profile } = useAppSelector(store => store.profile);

  if (!profile) {
    return <>404 - {t('Profile Page not found')}</>;
  }
  return (
    <Container>
    <Paper sx={ containerPaperStyle }>
      <Box sx={{ ml: 2 }}>
        <Outlet />
      </Box>
    </Paper>
  </Container>
  );
}