import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { RootState } from '../../store';

export type ProfileType = {
  name: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  level?: string;
  subscription?: string;
  createdAt: string;
};

export type CustomerState = {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  profile: ProfileType | null;
  token: string | null;
  registering: boolean;
  registered: boolean;
  updating: boolean;
  updated: boolean;
}

const initialState: CustomerState = {
  loading: false,
  loaded: false,
  error: null,
  profile: null,
  token: null,
  registering: false,
  registered: false,
  updating: false,
  updated: false,
};


export const getProfile = createAsyncThunk<any>('profile/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/user', thunkApi, method: 'GET'});
});

export const registerProfileAction = createAsyncThunk<any>('profile/create', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/user/register', thunkApi, method: 'GET' });
});

export const updateOwnProfileAction = createAsyncThunk<ProfileType, Partial<ProfileType>>('profile/update', async (profile: Partial<ProfileType>, thunkApi: any) => {
  return await apiRequest({ path: '/user/update', thunkApi, method: 'PATCH', data: profile });
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(getProfile.fulfilled, (state, action: PayloadAction<ProfileType>) => {
      state.loading = false;
      state.loaded = true;
      state.updated = false;
      state.profile = action.payload;
    });
    builder.addCase(getProfile.rejected, (state, action: any) => {
      state.loading = false;
      state.loaded = false;
      state.error = action.error ? action.error.message : 'Error with api';
    });
    builder.addCase(registerProfileAction.pending, (state, action) => {
      state.registering = true;
      state.registered = false;
    });

    builder.addCase(registerProfileAction.fulfilled, (state, action) => {
      state.registering = false;
      state.registered = true;
      state.loaded = true;
      state.profile = action.payload;
    });

    builder.addCase(registerProfileAction.rejected, (state, action: any) => {
      state.registering = false;
      state.registered = false;
      state.error = action.payload;
    });


    builder.addCase(updateOwnProfileAction.pending, (state, action) => {
      state.updating = true;
      state.updated = false;
    });

    builder.addCase(updateOwnProfileAction.fulfilled, (state, action) => {
      state.updating = false;
      state.updated = true;
      state.loaded = true;
      state.profile = action.payload;
    });

    builder.addCase(updateOwnProfileAction.rejected, (state, action: any) => {
      state.updating = false;
      state.updated = false;
      state.error = action.payload;
    });
  }
});

export const selectProfile = (state: RootState) => state.profile.profile;
export const getToken = (state: RootState): string | null => state.profile.token;

export const { setToken: setTokenAction } = profileSlice.actions;

export default profileSlice.reducer;
