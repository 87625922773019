import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Box, Button, CircularProgress, FormHelperText, TextField, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getCustomer } from '../../redux/customer/customerSlice';
import { getServiceCaseCalendarForSeason } from '../../redux/serviceBooks/serviceBookSlice';
import { useAddReminderMutation } from '../../redux/services/userProfileService';
import { ServicePlanItemType } from '../../types/servicePlanItem';
import { uniqBy } from 'lodash';

export type Props = {
  items: ServicePlanItemType[];
  selectedId?: number;
  serviceBookId?: number,
  season?: string,
  year?: number,
  close?: () => void
}

export default function AddEditReminderComponent(props: Props) {
  const { t } = useLocales();
  const items  = props.items;

  const dispatch = useAppDispatch();

  const [ addReminder, { isLoading: adding, isSuccess: added }]  = useAddReminderMutation();

  const selected = props.selectedId ? items.find(row => row.id === props.selectedId) : undefined;

  const [state, setState] = useState<{ servicePlanItem?: ServicePlanItemType, reminderDate?: Date }>({ reminderDate: addDays(new Date(), 1), servicePlanItem: selected || undefined });

  const handleChange = (name: string, value: number | string | Date | null | ServicePlanItemType) => setState({ ...state, [name]: value });

  useEffect(() => {
    if (added === true) {
      dispatch(getCustomer());
      if (props.serviceBookId && props.season && props.year) {
        dispatch(getServiceCaseCalendarForSeason({ serviceBookId: props.serviceBookId, season: props.season, year: props.year }));
      }
      if (props.close) {
        props.close();
      }
    }
  }, [added]);

  const submit = () => {
    console.log('item', state.servicePlanItem, state.reminderDate);
    if (state.servicePlanItem && state.reminderDate) {
      addReminder({ servicePlanItemId: state.servicePlanItem.id, scheduledAt: state.reminderDate });
    }
  };

  return (
    <Box sx={{ ml: 1 }}>
      <Typography sx={{ mb: 3 }} variant='h6'>{t('Add sms reminder')}</Typography>
      <Box sx={{ my: 1 }}>
        <>
            {
              items && items.length > 0 &&
              <Autocomplete
                disablePortal
                sx={{ mb: 2 }}
                id="add--user"
                noOptionsText={t('No options')}
                value={ state.servicePlanItem ? { label: state.servicePlanItem?.name, id: state.servicePlanItem?.id } : null}
                options={uniqBy((items || []), 'id').map(row => ({ label: `${row.name}`, id: row.id })) }
                renderInput={(params) => <TextField {...params} fullWidth label={t('Select service')} />}
                onChange={(event, item: { label: string | undefined, id: number | undefined } | null) => {
                  if (item && item.id) {
                    const obj = items.find(row => row.id === item.id);
                    if (obj) {
                      handleChange('servicePlanItem', obj);
                    }
                  }
                }}
              />
            }
          </>
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker
            label={t('Reminder date and time')}
            inputFormat="dd.MM.yyyy hh:mm"
            value={state.reminderDate || new Date()}
            onChange={(value: Date | null) => handleChange('reminderDate', value)}
            renderInput={(params: any) => <TextField sx={{ mt: 3 }} variant="outlined" {...params} />}
          />
        </LocalizationProvider>
        <Box sx={{ mt: 1 }}>
          <FormHelperText>{ t('Reminder will be sent to the phone number of this user') }</FormHelperText>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        {
          adding ? <CircularProgress /> :
            <Button onClick={submit} variant='contained' color='primary'>{t('Save')}</Button>
        }
      </Box>
    </Box>
  );
}