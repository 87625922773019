import { Box } from '@mui/system';
import useLocales from '../../hooks/useLocales';

export default function AdminDashboard(props: any) {
  const { t } = useLocales();
  return (
    <Box sx={{ m: 2, p: 2 }}>
      {t('Admin dashboard content')}
    </Box>
  );
}