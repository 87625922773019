import { EditRounded } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import CustomerSubscription from '../customer/CustomerSubscription';
import SidePanel from '../sidepanel/SidePanel';
import EditCustomerComponent from './EditCustomerComponent';

export default function ProfileCustomerComponent() {
  const { t } = useLocales();
  const { details, list } = useAppSelector(store => store.customer);

  const [state, setState] = useState<any>({ editCustomer: false, showSubscription: false });

  const customer = details;
  if (!customer) {
    return <></>;
  }

  return (
    <div>
      <SidePanel
        open={ state.editCustomer }
        close={ () => setState({ ...state, editCustomer: false })}
      >
        <Typography sx={{ p: { md: 2, xs: 0 } }} variant="h6">{t('Edit customer')}</Typography>
        <EditCustomerComponent cancel={ () => setState({ ...state, editCustomer: false }) } details={ customer } />
      </SidePanel>

      <SidePanel
        open={ state.selectSubscription }
        close={() => setState({ ...state, selectSubscription: false })}
      >
        <Typography variant="h5" sx={{ pb: 0, ml: { lg: 2, md: 0, xs: 0} }}>{t('Activate service')}</Typography>
        <CustomerSubscription
          cancel={ () => setState({ ...state, selectSubscription: false })}
        />
      </SidePanel>
      <Box sx={{ mt: 1, pl: 1 }}>
        <Typography variant="h4">{t('Customer details')}</Typography>
        <Box sx={{ py: 1 }}>
          {t('Name')}: { customer.name || '' }
        </Box>
        <Box sx={{ py: 1 }}>
          {t('Phone')}: { customer.phone || '' }
        </Box>

        <Box sx={{ py: 1 }}>
          {t('Invoicing email')}: { customer.email || '' }
        </Box>
        <Box sx={{ py: 1 }}>
          {t('Address')}: {customer.address && customer.address}
        </Box>
        <Box sx={{ py: 1 }}>
          {t('Postal code')}: {customer.postalCode && customer.postalCode}
        </Box>
        <Box sx={{ py: 1 }}>
          {t('City')}: {customer.city && customer.city}
        </Box>
        <Box sx={{ py: 1 }}>
          {t('Country')}: {customer.country && customer.country}
        </Box>

        { customer.subscription ?
          <Box sx={{ py: 1 }}>
            {t('Subscription')}:  {t(customer.subscription?.name)}
            <Box sx={{ pt: 2 }}>
              {t('SMS reminders limit')}: {customer.reminderLimit || 20}
            </Box>
            <Box sx={{ p: 1, pt: 2, fontSize: 12 }}>
              { t('To change the subscription type contact the customer service via email {1} or calling the customer service {2}', ['asiakaspalvelu@kodinkanta.fi', '050 556 6265']) }

            </Box>

          </Box>
        :
        <Box sx={{ py: 1 }}>
          {t('No subscription')}
          <Box sx={{ mt: 2 }}>
            <Button
              onClick={ () => setState({ ...state, selectSubscription: true }) }
              color="primary"
              variant="contained"
            >
              {t('Activate subscription')}
            </Button>
          </Box>
        </Box>
      }
        <Box sx={{ p: 1 }}>
          <Button onClick={() => setState({ ...state, editCustomer: true })} startIcon={<EditRounded />}>{t('Edit')}</Button>
        </Box>
      </Box>
    </div>
  );
}