import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { valueAsDate } from '../../helpers/helpers';
import useLocales from '../../hooks/useLocales';
import { useDeleteProfileReminderMutation, useGetProfileRemindersQuery } from '../../redux/services/userProfileService';
import SkeletonRows from '../layout/SkeletonRows';
import { ReminderType } from '../../types/reminder-type';
import ConfirmableButton from '../buttons/ConfirmableButton';

export default function ProfileRemindersComponent (props: any) {
  const { t } = useLocales();

  const { data: reminders, error, isLoading: loading } = useGetProfileRemindersQuery();
  const [ deleteReminder, { isLoading: deleting }]  = useDeleteProfileReminderMutation();

  if (loading && !error) {
    return <SkeletonRows rows={5} />;
  }
  const activeReminders = reminders?.filter(row => row.fulfilledAt === null);
  const fulfilledReminders = reminders?.filter(row => row.fulfilledAt && row.fulfilledAt !== null);

  const renderTable = (reminders: ReminderType[]) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              #
            </TableCell>
            <TableCell>
              {t('Scheduled at')}
            </TableCell>
            <TableCell>
              {t('Target')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (reminders || []).map((reminder, idx) => {
              return (
                <TableRow key={`reminder_${reminder.id}`}>
                  <TableCell>
                    { idx + 1 }
                  </TableCell>
                  <TableCell>
                    { reminder.scheduledAt ? format(valueAsDate(reminder.scheduledAt), 'dd.MM.yyyy HH:mm') : '' }
                  </TableCell>
                  <TableCell>
                    { reminder.serviceCase?.servicePlanItem?.name }
                    <Box sx={{ fontSize: '0.75em' }}>
                      { reminder.customer?.name }: { reminder.serviceCase?.serviceBook?.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {
                      !deleting && !reminder.fulfilledAt &&
                        <ConfirmableButton onSubmit={ () => reminder.id && deleteReminder(reminder.id) } />
                    }
                  </TableCell>
                </TableRow>
              );
            })
          }

        </TableBody>
      </Table>
    );
  };

  return (
    <Box>
      <Box sx={{ my: 2 }}>
        {
          error && <Box>{t('Failure loading the reminders')}</Box>
        }
        {
          !error && (!reminders || reminders.length === 0) && <Box>{t('No reminders added. Create reminders through service case handling')}</Box>
        }
        {
          deleting && <CircularProgress />
        }
        {
          activeReminders && activeReminders.length > 0 &&
            <Box sx={{ mb: 4 }}>
              <Typography variant='h6'>{t('Active reminders')}</Typography>
              { renderTable(activeReminders) }
            </Box>
        }

        {
          fulfilledReminders && fulfilledReminders.length > 0 &&
            <Box>
              <Typography variant='h6'>{t('Fulfilled reminders')}</Typography>
              { renderTable(fulfilledReminders) }
            </Box>
        }
        {
          reminders && reminders.length > 0 && <Box sx={{ fontSize: 12, mt: 4 }}>{t('Limit of reminders is per customer, check the customer page to review that') }</Box>
        }
      </Box>
    </Box>
  );
}