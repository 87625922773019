import { AddRounded, VisibilityRounded } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { valueAsDate } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { addButtonStyle, colors } from '../../../styles/themeStylesSx';
import { getReceipts } from '../../redux/receipts/receiptsSlice';
import AttachmentsIcon from '../attachments/AttachmentsIcon';
import SkeletonRows from '../layout/SkeletonRows';
import SidePanel from '../sidepanel/SidePanel';
import AddEditReceipt from './AddEditReceipt';
import ReceiptComponent from './ReceiptComponent';

type ReceiptStateType = {
  open?: boolean;
  receiptId?: number;
  add?: boolean;
};

export default function ListReceipts() {
  const { t } = useLocales();


  const [state, setState] = useState<ReceiptStateType>({ open: false, add: false });
  const { list, loading, loaded, reload } = useAppSelector(store => store.receipts);
  const { details: customer } = useAppSelector(store => store.customer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getReceipts());
  }, []);

  useEffect(() => {
    if (reload && !loaded && !loading) {
      dispatch(getReceipts());
      setState({ open: false });
    }
  }, [reload, loading, loaded]);

  return (
    <div>
      <SidePanel
        open={ state.add === true }
        close={ () => setState({ add: false })}
      >
        <Box sx={{ ml: 2 }}>
          <AddEditReceipt cancel={ () => setState({ add: false }) } />
        </Box>
      </SidePanel>

      <SidePanel
        open={ state.open === true }
        close={ () => setState({ open: false })}
      >
        <Box sx={{ }}>
          <ReceiptComponent receipt={ list.find(row => row.id === state.receiptId )} close={ () => setState({ open: false })} />
        </Box>
      </SidePanel>

      { loading && <SkeletonRows rows={5} /> }

      <Box sx={{ display: { xs: 'block', md: 'none' }}}>
        {
          list.map((row, idx) => {
            const dateValue = valueAsDate(row.date);
            const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';
            return (<Paper sx={{ p: 1, mt: 1, mb: 1, lineHeight: 1.5, background: backgroundColor }} key={idx}>
              <Box sx={{ display: 'flex'}}>
                <Box title={`${row.id}`}>{idx + 1}: { dateValue ? format(dateValue, 'dd.MM.yyyy') : '' } { row.description }</Box>
                <Box sx={{ ml: 'auto'}}>
                  <IconButton onClick={() => setState({ open: true, receiptId: row.id })}><VisibilityRounded /></IconButton>
                </Box>
              </Box>
              <Box>{row.total} €</Box>
              <Box sx={{ color: 'gray' }}>{t('Bought from')}: {row.boughtFrom}</Box>
            </Paper>);
          })
        }
      </Box>
      <Table sx={{ display: { xs: 'none', md: 'inline-table', color: 'secondary.main' }}}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{t('Date')}</TableCell>
            <TableCell>{t('Bought from')}</TableCell>
            <TableCell>{t('Description')}</TableCell>
            <TableCell>{t('Total')}</TableCell>
            <TableCell>{t('Attachment')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.map( (row, i)=> {
              const dateValue = valueAsDate(row.date);
              const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${row.id}_row`}>
                  <TableCell sx={{backgroundColor}}>{ dateValue ? format(dateValue, 'dd.MM.yyyy') : '-' }</TableCell>
                  <TableCell sx={{backgroundColor}}>{ row.boughtFrom }</TableCell>
                  <TableCell sx={{backgroundColor}}>{ row.description }</TableCell>
                  <TableCell sx={{backgroundColor}}>{ row.total }</TableCell>
                  <TableCell sx={{backgroundColor}}>
                    <AttachmentsIcon attachments={ row.attachments } />
                  </TableCell>
                  <TableCell sx={{
                    backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                    <IconButton sx={{p: 0}} onClick={() => setState({ open: true, receiptId: row.id })}><VisibilityRounded /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      <Box>
        <Box sx={{ mt: 2, ml: 'auto', width: '200px' }}>
          <Button
            disabled={!customer?.isActive}
            color="secondary" sx={ addButtonStyle } variant="outlined" onClick={() => setState({ add: true })} endIcon={<AddRounded />}
          >
            {t('Add new receipt')}
          </Button>
        </Box>
      </Box>
    </div>
  );
}