import { Box, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { AgreementType } from '../../types/agreement';
import { getDateFromStateOrObject, getValueFromStateOrObject } from '../../helpers/helpers';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FileUpload from '../common/FileUpload';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { uploadFilesAction, resetUploadedAttachmentsAction } from '../../redux/attachment/attachmentSlice';
import { AttachmentType } from '../../types/attachment';
import { DeleteRounded } from '@mui/icons-material';
import { addAgreement, updateAgreement, clearAgreementSavedAction } from '../../redux/agreements/agreementsSlice';

type AddEditAgreementPropsType = {
  cancel: () => void;
  agreement?: Partial<AgreementType>;
  saving?: boolean;
  source?: "topbar";
}

export default function AddEditAgreement(props: AddEditAgreementPropsType) {
  const { cancel, agreement, source } = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<AgreementType>>({});
  const dispatch = useAppDispatch();
  const { loading, uploaded, result: uploadResult } = useAppSelector(store => store.attachment);
  const { details: customer } = useAppSelector(store => store.customer);
  const { saving, saved, reload } = useAppSelector(store => store.agreements);
  const [attachments, setAttachments] = useState<Array<Partial<AttachmentType>>>([]);

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });
  useEffect(() => {
    setState({});
  }, []);
  const uploadFiles = (files: any) => {
    dispatch(uploadFilesAction(files));
  };

  useEffect(() => {
    if (uploaded && uploadResult) {
      setAttachments([...attachments, ...uploadResult]);
    }
  }, [uploaded, uploadResult]);

  useEffect(() => {
    if (saved) {
      setState({});
      setAttachments([]);
      dispatch(resetUploadedAttachmentsAction());
      dispatch(clearAgreementSavedAction());
    }
    if (source === 'topbar' && saved && reload) {
      cancel();
    }
  }, [source, saved, reload, cancel]);

  const submit = () => {
    const data = { ...state, attachments };
    if (agreement && agreement.id && customer) {
      dispatch(updateAgreement({ id: agreement.id, data: { ...data, id: agreement.id } }));
    }
    else if (customer) {
      if (!data.expiryDate) {
        data.expiryDate = new Date();
      }
      dispatch(addAgreement({ ...data, customerId: customer.id }));
    }
  };


  return (
    <Box sx={{ mt: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label={t('Expiry date')}
          inputFormat="dd.MM.yyyy"
          value={ getDateFromStateOrObject(state, agreement, 'expiryDate', true) }
          onChange={ (value: Date | null) => handleChange('expiryDate', value) }
          renderInput={(params: any) => <TextField variant="outlined" {...params} />}
        />
      </LocalizationProvider>
      <TextField
        sx={{ mt: 1 }}
        label={t('Description')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, agreement, 'description') }
        onChange={(e) => handleChange('description', e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Value')}
        type="number"
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, agreement, 'value') }
        onChange={(e) => handleChange('value', +e.target.value)}
      />
      <TextField
        sx={{ mt: 1 }}
        label={t('Unit')}
        type="string"
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, agreement, 'unit') }
        onChange={(e) => handleChange('unit', e.target.value)}
      />

      {
        loading ? <CircularProgress /> :
          <FileUpload onDrop={ files => uploadFiles(files) } />
      }
      {
        (attachments || []).map(attachment => {
          return (
            <Box key={`att_id_${attachment.id}`} sx={{ mt: 1, p: 1 }}>
              { attachment.id }: { attachment.filename } <IconButton onClick={() => setAttachments(attachments.filter(row => row.id !== attachment.id))}><DeleteRounded /></IconButton>
            </Box>
          );
        })
      }

      <Box sx={{ mt: 1 }}>
        <Button variant="outlined" onClick={cancel}>
          {t('Cancel')}
        </Button>
        {
          (saving && saving === true) ? <CircularProgress /> :
            <Button
              color="primary"
              variant="contained"
              sx={{ ml: 1 }}
              onClick={submit}
            >
              {t('Submit')}
            </Button>
        }
      </Box>
    </Box>
  );
}