import { Box, Button, CircularProgress, TextField,
 IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Autocomplete, Typography } from '@mui/material';
 import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useState, useEffect } from 'react';
import useLocales from '../../hooks/useLocales';
import { CustomerType } from '../../types/customer';
import {  getValueFromStateOrObject } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getUsers } from '../../redux/user/adminUserSlice';
import { addUserForCustomerAction, deleteUserFromCustomerAction } from '../../redux/admin/adminCustomerSlice';
import { AddRounded } from '@mui/icons-material';

// import { LocalizationProvider, MobileDatePicker } from '@mui/lab';

type AddEditCustomerPropsType = {
  cancel: () => void;
  // submit: (data: Partial<CustomerType>) => void;
  customer?: Partial<CustomerType>;
  saving?: boolean;
}

type UserSelectOption = {
  label: string;
  id: number;
};

export default function AddEditAdminCustomersUsers(props: AddEditCustomerPropsType) {
  const { cancel, customer} = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<CustomerType>>({});
  const dispatch = useAppDispatch();
  const [selectedUser, setSelectedUser] = useState<number | undefined>();
  const { list, loading, saving } = useAppSelector(store => store.adminUser);
  useEffect(() => {
    if (!list) {
      dispatch(getUsers());
    }
  }, [dispatch, list]);

  if (!customer) {
    return <>{t('No customer')}</>;
  }

  const addUser = (userId?: number) => {
    if (!userId) {
      return;
    }
    dispatch(addUserForCustomerAction({ id: customer?.id as number, userId }));
  };

  const deleteUserId = (userId: number) => {
    dispatch(deleteUserFromCustomerAction({ id: customer.id as number, userId: userId as number }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">{ customer.name }</Typography>

      <Typography sx={{ p: 2 }} variant="h6">{t('Edit customer users')}</Typography>
    <div>
      { loading  && <Skeleton animation="wave" />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Identifier')}</TableCell>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Email')}</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            customer?.users?.map( row => {
               return (
                <TableRow key={`${row.id}_row`}>
                  <TableCell>{ row.id }</TableCell>
                  <TableCell>{ row.name  }</TableCell>
                  <TableCell>{ row.email }</TableCell>
                  <TableCell>
                    <IconButton onClick={() => { deleteUserId(row.id); }}><RemoveCircleOutlineRoundedIcon    /></IconButton>
                  </TableCell>
                </TableRow>
               );
            })
          }
        </TableBody>
      </Table>
      <Autocomplete
        disablePortal
        sx={{ mt: 4 }}
        id="add--user"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={(list || []).filter(row => !customer?.users?.find(user => user.id === row.id)).map(row => ({ label: `${row.id}. ${row.email}`, id: row.id }))}
        renderInput={(params) => <TextField {...params} fullWidth label={t('Add user to customer')} />}
        onChange={(event: any, user: { label: string, id: number } | null) => setSelectedUser(user?.id) }
      />
      <Button
        color="secondary"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={ selectedUser === null && selectedUser === undefined }
        startIcon={<AddRounded />}
        onClick={ () => addUser(selectedUser) }
      >
        {t('Add')}
      </Button>

    </div>

    </Box>
  );
}