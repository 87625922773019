
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { colors } from '../../../styles/themeStylesSx';
import { useAppDispatch, useAppSelector } from "../../hooks";
import useLocales from "../../hooks/useLocales";
import { getAdminServicePlans } from "../../redux/admin/adminServicePlanSlice";
import { saveServicePlanForServiceBook } from "../../redux/serviceBooks/serviceBookSlice";
import { ServiceBookType } from '../../types/serviceBook';
import { ServicePlanItemType } from "../../types/servicePlanItem";


type Props = {
  item?: Partial<ServicePlanItemType>,
  close: () => void,
  serviceBook: ServiceBookType,
}

type StateType = { line: number, selected: boolean };

export default function ServicePlanItems(props: Props) {
  const { serviceBook } = props;
  const { t } = useLocales();
  const { list, loaded } = useAppSelector(store => store.adminServicePlan);
  const { saved: savedPlans, saving: savingPlans } = useAppSelector(store => store.serviceBook);
  const item = props.item;
  const [state, setState] = useState<Partial<ServicePlanItemType>>({});
  const [selectedState, setSelectedState] = useState<StateType[]>([]);
  const [allSelected, setAllSelected] = useState({all: false, notAll: false});
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(getAdminServicePlans());
    }
  }, [loaded]);

  useEffect(() => {
    if (savedPlans.includes(serviceBook.id)) {
      props.close();
    }
  }, [savedPlans]);

  const handleSelected = (line: number , selected: boolean) => {
    const work = [...selectedState];
    const idx = work.findIndex(li => li.line === line);
    if (idx === -1) {
      setSelectedState([...work, { line, selected }]);
      setAllSelected({ all: false, notAll: false });
      return;
    }
    work[idx] = { ...work[idx], selected };
    setSelectedState(work);
    setAllSelected({ all: false, notAll: false });
  };

  const handleAll = (selectOrUnselect: string) =>  {
    const work: any = [];
    if (selectOrUnselect === 'all') {
      setAllSelected({ all: true, notAll: false });
      droppedList.forEach((list: any) => {
       work.push({ line: list.id, selected: true });
      });
    }
    else {
      setAllSelected({ all: false, notAll: true });
      droppedList.forEach((list: any) => {
        work.push({ line: list.id, selected: false });
      });
    }
    setSelectedState(work);
  };
  const submitAll = () => {
    const data = {
      ...state,
      cost: state.cost ? +state.cost : undefined,
      interval: state.interval ? +state.interval : undefined,
    };
    if (item) {
      data.id = item.id;
    }
    selectedState.forEach(item => {
      if (item.selected === true) {
        droppedList.filter((selected: any) => selected.id === item.line).map((filtered: any) => {
          data.name = filtered.name;
          data.category = filtered.category;
          data.description = filtered.description;
          data.interval = filtered.interval;
          data.winter = filtered.winter;
          data.summer = filtered.summer;
          data.fall = filtered.fall;
          data.spring = filtered.spring;
          data.type = filtered.type;
          data.indoorOrOutdoor = filtered.indoorOrOutdoor;
          dispatch(saveServicePlanForServiceBook({ id: serviceBook.id, data }));
        });
      }
    });
  };

  if (list.length === 0) {
    return <Box>{t('No items found, you can still add your own')}</Box>;
  }

  const includedItems = serviceBook.servicePlanItems;
  const droppedList = list.filter(row => {
    return row.forType === serviceBook.type &&
     !includedItems.find(item => {
     return item.name === row.name ;
    });
  });

  return (
    <Box>
       <FormControl>
        <FormControlLabel id="" label={t('Select all')} control={<Checkbox onChange={(e: any) => handleAll('all') } checked={ allSelected.all } />} />
        <FormControlLabel id="" label={t('Unselect all')} control={<Checkbox onChange={(e: any) => handleAll('notAll')} checked={ allSelected.notAll } />} />
      </FormControl>
      <Box  sx={{ mt: 2, mb: 1 }}>
        <List>
            {
              droppedList.filter(row => row.forType === serviceBook.type).map((row: any, i: number )=> {
                return (
              <ListItem key={`${row.id}_row`} sx={{ backgroundColor: i % 2 ? colors.backgroundGreen : '#fff', borderRadius: 1 }}>
                <FormControl>
                  <FormControlLabel
                    label={row.name}
                    control={
                      <Checkbox onChange={(e: any) =>
                          handleSelected( row.id, e.target.checked)
                        }
                        checked={selectedState.find(ss => ss.selected === true && ss.line === row.id) ? true : false  }
                      />}
                  />
                </FormControl>
              </ListItem>);
            })
            }
        </List>
      </Box>
      <Box>
        {
          savingPlans.includes(serviceBook.id) ? <CircularProgress /> :
            <Button onClick={submitAll} variant="contained">
                { props.item ? t('Save') : t('Add') }
            </Button>
        }


      </Box>
    </Box>
  );
}