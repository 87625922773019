/* eslint-disable @typescript-eslint/no-explicit-any */

import { AddRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { valueAsDate } from '../../helpers/helpers';
import useLocales from '../../hooks/useLocales';
import SidePanel from '../sidepanel/SidePanel';
import { CustomerType } from '../../types/customer';
import { colors } from '../../../styles/themeStylesSx';
import AddEditAdminNote from './AddEditAdminNote';


type ListNotePropsType = {
  customer?: Partial<CustomerType>;
  saving?: boolean;
}
type StateType = {
  open: boolean;
  update: boolean;
  logId?: number;
}

export default function ListAdminNotes(props: ListNotePropsType) {
  const { customer } = props;
  const { t } = useLocales();
  const { loading } = useAppSelector(store => store.adminUser);
  const [addState, setAddState] = useState<StateType>({open: false, update: false });

  return (
    <div>
      <SidePanel
        open={ addState.open }
        close={ () => setAddState({ open: false, update: false, logId: 0 })}
      >
        {addState.update ?
        <Typography sx={{ p: 2 }} variant="h6">{t('Update/Delete note')}</Typography>
        :
        <Typography sx={{ p: 2 }} variant="h6">{t('Add new note')}</Typography>
        }

        <AddEditAdminNote  logId = {addState.logId} update= {addState.update} customer={customer} cancel={ () => setAddState({ open: false, update: false }) }  />
      </SidePanel>

      { loading  && <Skeleton animation="wave" />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Id')}</TableCell>
            <TableCell>{t('Message')}</TableCell>
            <TableCell>{t('Created') } </TableCell>
            <TableCell>{t('Updated') } </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            customer?.logs?.map( (row: any, idx: number) => {
              const dateValue = valueAsDate(row.createdAt);
              const dateValueUpd = valueAsDate(row.updatedAt);
              return (
                <TableRow key={`${row.id}_row`} sx={{ backgroundColor: idx % 2 ? colors.backgroundGreen : '#fff' }}>
                  <TableCell>{ row.id}</TableCell>
                  <TableCell>{ row.message}</TableCell>
                  <TableCell >{ dateValue ? format(dateValue, 'dd.MM.yyyy') : '-' }</TableCell>
                  <TableCell >{ dateValue ? format(dateValueUpd, 'dd.MM.yyyy') : '-' }</TableCell>
                  <TableCell sx={{
                    borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                    <IconButton sx={{p: 0}} onClick={() => setAddState({ update: true, open: true, logId: row.id })}><VisibilityRounded /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      <Box sx={{ mt: 2, ml: 'auto', width: '200px' }}>
        <Button
          color="secondary"
          onClick={() => setAddState({ open: true , update: false, logId: 0}) }
          startIcon={<AddRounded />}
        >{t('Add new Note')}</Button>
      </Box>
    </div>
  );
}