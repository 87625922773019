import { Box } from '@mui/system';
import { AttachmentType } from '../../types/attachment';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { loadAttachmentAction, resetLoadedAttachment } from '../../redux/attachment/attachmentSlice';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { PictureAsPdfRounded } from '@mui/icons-material';
import SkeletonRows from '../layout/SkeletonRows';

type Props = {
  attachments: Partial<AttachmentType>[],
};

const ListAttachments = (props: Props) => {

  const dispatch = useAppDispatch();
  const [openedLinks, setOpenedLinks] = useState<{ urls: string[] }>({ urls: [] });
  const { loadedAttachment, loadingAttachment, attachment } = useAppSelector(store => store.attachment);

  useEffect(() => {
    if (loadedAttachment === true && attachment && attachment.link) {
      if (!openedLinks.urls.includes(attachment.link)) {
        window.open(attachment.link, '_blank', 'noopener');
      }
      dispatch(resetLoadedAttachment());
    }
  }, [loadedAttachment]);


  const load = (id: number | undefined) => {
    if (!id) {
      return;
    }
    setOpenedLinks({ urls: [] });
    dispatch(loadAttachmentAction(id));
  };
  if (loadingAttachment) {
    return <SkeletonRows rows={ props.attachments.length } />;
  }
  return (
    <Box>
    {
      (props.attachments || []).map((attachment: Partial<AttachmentType>, idx: number) =>
        <Box key={`Attachment_${idx}`} sx={{ mt: 1 }}>
          <Button
            onClick={ () => load(attachment.id)}
            startIcon={<PictureAsPdfRounded />}
          >
            { attachment.filename && attachment.filename.length > 20 ? `${attachment.filename.substring(0, 20)}...` : (attachment ? attachment.filename : '') }
          </Button>
        </Box>
      )
    }
    </Box>
  );
};

export default ListAttachments;