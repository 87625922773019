// Or from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkQueryRequest } from '../../helpers/apiHelper';
import { DocumentType } from '../../types/documents';

export const serviceBookApi = createApi({
  reducerPath: 'serviceBookApi',
  baseQuery: rtkQueryRequest,
  tagTypes: ['Documents', 'ServiceBooks'],
  endpoints: (builder) => ({
    getServiceBookDocuments: builder.query<DocumentType[], number>({
      query: (id) => ({ url: `service-book/${id}/documents` }),
      providesTags: ['Documents'],
    }),
  })
});

export const { useGetServiceBookDocumentsQuery } = serviceBookApi;