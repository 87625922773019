
import Box from '@mui/material/Box';
import SidePanel from '../sidepanel/SidePanel';
import { useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { useAppSelector } from '../../hooks';
 import { IconButton, Typography, Button } from '@mui/material';
import { EditRounded } from '@mui/icons-material';
import EditProfileComponent from './EditProfileComponent';

export default function ProfileComponent() {
  const { t } = useLocales();
  const [state, setState] = useState<{customerOpen: boolean, profileOpen: boolean, customerId: number | null}>({ customerOpen: false, profileOpen: false, customerId: null } );
  const { profile } = useAppSelector(store => store.profile);

  const editDetails = () => {
    setState({ ...state, profileOpen: true});
  };

  return (
    <Box>
        <SidePanel
        open={ state.profileOpen }
        close={ () => setState({ ...state, profileOpen: false })}
      >
        <Typography sx={{ p: 2 }} variant="h6">{t(`Profile`)}: {profile?.email}</Typography>
        <EditProfileComponent cancel={ () => setState({ ...state, profileOpen: false }) } details={ profile } />
      </SidePanel>

      <Typography variant="h6">{t(`My profile `)}: {profile?.email}</Typography>

      <Box sx={{ p: 1 }}>
        {t('Name')}: { profile && profile.name ? profile.name : '-' }
      </Box>

      <Box sx={{ p: 1 }}>
        {t('Level')}: { profile ? profile.level : '' }
      </Box>
      <Box sx={{ p: 1 }}>
        {t('Phone')}: { profile && profile.phone ? profile.phone : '-' }
      </Box>
      <Box sx={{ p: 1 }}>
        <Button onClick={editDetails} startIcon={<EditRounded />}>{t('Edit')}</Button>
      </Box>
  </Box>
  );
}