import { ArrowBackRounded, EditRounded, PictureAsPdfRounded } from '@mui/icons-material';
import { Button, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { ReceiptType } from '../../types/receipts';
import AddEditReceipt from './AddEditReceipt';
import SidePanel from '../sidepanel/SidePanel';
import { deleteReceiptAction, getReceipts, updateReceipt } from '../../redux/receipts/receiptsSlice';
import { valueAsDate } from '../../helpers/helpers';
import { loadAttachmentAction, resetLoadedAttachment } from '../../redux/attachment/attachmentSlice';
import { AttachmentType } from '../../types/attachment';
import ConfirmableButton from '../buttons/ConfirmableButton';

type ReceiptComponentType = {
  receipt?: ReceiptType,
  close: () => void,
};

export default function ReceiptComponent(props: ReceiptComponentType) {
  const { receipt, close } = props;
  const { t } = useLocales();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({ edit: false });

  const { loadedAttachment, attachment } = useAppSelector(store => store.attachment);
  const { reload, saving, saved, loading, loaded, deleting, deleted } = useAppSelector(store => store.receipts);

  useEffect(() => {
    if (saved && loaded) {
      setState({ edit: false });
      close();
    }
  }, [saved, loaded]);

  useEffect(() => {
    if (loadedAttachment && attachment) {
      window.open(attachment.link);
      dispatch(resetLoadedAttachment());
    }
  }, [loadedAttachment, attachment]);

  useEffect(() => {
    if (deleted) {
      close();
    }
  }, [deleted]);

  if (!receipt) {
    return (
      <Box>
        {t('Not found')}
      </Box>
    );
  }

  const load = (id: number | undefined) => {
    if (!id) {
      return;
    }
    dispatch(loadAttachmentAction(id));
  };

  const dateParsed = valueAsDate(receipt.date);
  if (state.edit) {
    return (
      <Box>
        <Typography sx={{ p: 2 }} variant="h6">{t('Edit receipt')}</Typography>
        <AddEditReceipt receipt={receipt} cancel={ () => setState({ edit: false }) } />
      </Box>
    );
  }
  return (
    <Box>
      <Box sx={{ p: 1 }}>
      {t('Receipt')}: { receipt.id }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Bought from')}: { receipt.boughtFrom }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Description')}: { receipt.description }
      </Box>
      <Box sx={{ p: 1 }}>
      {t('Date')}: { dateParsed ? format(dateParsed, 'dd.MM.yyyy') : '-' }
      </Box>
      <Box sx={{ p: 1 }}>
        {t('Value')}: { receipt.total }
      </Box>
      {
        (receipt.attachments || []).map((attachment: Partial<AttachmentType>, idx: number) =>
          <Box key={`Attachment_${idx}`} sx={{ mt: 1 }}>
            <Button
              onClick={ () => load(attachment.id)}
              startIcon={<PictureAsPdfRounded />}
            >
              { attachment.filename}
            </Button>
          </Box>
        )
      }
      <Box sx={{ borderTop: 'thin solid gray', mt: 2 }}>
        <Button color="secondary" onClick={() => setState({ edit: true })} startIcon={<EditRounded />}>{t('Edit')}</Button>
      </Box>
      {
        receipt && receipt.id &&
          <Box sx={{ mt: 2 }}>
            <ConfirmableButton
              showLoader={ deleting }
              onSubmit={ () => dispatch(deleteReceiptAction(receipt.id as number)) }
              buttonText={ t('Delete') }
            />
          </Box>
      }
    </Box>
  );
}