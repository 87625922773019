import React, { useState, useEffect} from 'react';
import { Button, Container, Paper, Tab, Tabs, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';

import { Outlet, Link as RouterLink, useNavigate } from 'react-router-dom';

import { containerPaperStyle, simpleContainerPaperStyle } from '../../styles/themeStylesSx';
import useLocales from '../hooks/useLocales';

const windowWidth = window ? window.innerWidth : 1000;


export default function ReportsContainer() {
  const { t } = useLocales();
  const [width, setWidth] = useState(windowWidth);
  const navigate = useNavigate();
  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);

  const [value, setValue] = React.useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`${newValue}`);
    setValue(newValue);
  };
  return (
    <Container>
      <Box>
        <Typography sx={{ color: 'secondary.main' }}  variant="h3">{t('Reports')}</Typography>
      </Box>
      <Paper sx={ simpleContainerPaperStyle }>

        <Tabs orientation={ width < 1000 ? 'vertical' : 'horizontal' } value={value === '' ? 'receipts' : value} onChange={handleChange} aria-label="servicebook tabs">
          <Tab value="receipts" label={t('Receipts')} />
          <Tab value="servicecase" label={t('Service cases')} />
        </Tabs>

        <Outlet />
      </Paper>
    </Container>
  );
}