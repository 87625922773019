
import { useAppSelector } from '../../hooks';

const styles: any = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

const axisBottom: any = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: -30,
  legend: "Year/Month",
  legendPosition: "middle",
  legendOffset: 32
};

const axisLeft: any = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "ServiceBook cost/ types",
  legendPosition: "middle",
  legendOffset: -40
};

const theme: any = {
  background: "#D5D2AF",
  axis: {
    fontSize: "14px",
    tickColor: "#0D1E0A",
    ticks: {
      line: {
        stroke: "#555555"
      },
      text: {
        fill: "#081006"
      }
    },
    legend: {
      text: {
        fill: "#aaaaaa"
      }
    }
  },
  grid: {
    line: {
      stroke: "#555555"
    }
  }
};
let colors:any = {};
const colorCodes: any = [
  '#A52A2A',
  '#7FFD4',
  '#008000',
  '#FFA500',
  '#FFC0CB',
  '#00FF00',
  '#FF0000',
  '#0000FF',
  '#00008B',
  '#800080',
  '#7FFFD4',
  '#00FFFF',
  '#A52A2A',
  '#C0C0C0',
  '#00008B',
  '#FF00FF',
  '#FFC0CB',
  '#808000',
  '#008000',
  '#800000',
  '#000000',
  '#808080',
  '#FFFFFF',

];

const colorBy: any = ({id}: {id: string}) => colors[id];

const legends: any = [
  {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 120,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    itemTextColor: "#081006",
    symbolSize: 20,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1
        }
      }
    ]
  }
];

const ReportServiceCaseGraph = () => {
  const { details } = useAppSelector(store => store.reports);
  const nameArray: any = [];
  const mapped = details?.months.reduce((results: any, data: any) => {
    const result = results;
    const exist: number = result.findIndex((row: any )=> row.month === data.month && row.year === data.year );
    if (exist !== -1) {
      result[exist] = { ...result[exist], [data.name]: data.total, month: data.month, year: data.year, yearMonth : data.year + '.' + data.month  };
      if (!nameArray.includes (data.name )) {nameArray.push(data.name );}
      const idx: number = nameArray.findIndex((na: any ) => {return na === data.name;} );
      if (idx !== -1) { colors =  {...colors,[data.name]: colorCodes[idx] }; }
    }
    else {
      result.push({ year: data.year, month: data.month, [data.name]: data.total, yearMonth : data.year + '.' + data.month });
      if (!nameArray.includes (data.name )) { nameArray.push(data.name ); }
      const idx: number = nameArray.findIndex((na: any ) => {return na === data.name;} );
      if (idx !== -1) { colors =  {...colors,[data.name]: colorCodes[idx] };}
    }
    return result;
  }, []);


return (
  <div style={styles}>
    <></>
  </div>
);
};
export default ReportServiceCaseGraph;


