import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { simpleContainerPaperStyle, headerStyles } from '../../styles/themeStylesSx';
import ValidateAccessToFeature from '../components/authorization/ValidateAccessToFeature';
import ContextualHelp from '../components/help/ContextualHelp';
import useLocales from '../hooks/useLocales';

export default function DocumentsContainer() {
  const { t } = useLocales();

  return (
    <ValidateAccessToFeature feature='any'>
      <Container>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={headerStyles}>{t('Documents')}</Typography>
          <ContextualHelp styles={{ marginLeft: 'auto' }} variant='documents' />
        </Box>
        <Outlet />
      </Container>
    </ValidateAccessToFeature>
  );
}