import { Button, Card, CardActions, CardContent, Link, CardMedia, Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { colors, containerPaperStyle, headerStyles, subHeaderStyles } from '../../styles/themeStylesSx';
import { useAppSelector } from '../hooks';
import useLocales from '../hooks/useLocales';
import { useEffect, useState } from 'react';
import SidePanel from '../components/sidepanel/SidePanel';
import AddEditServiceBook from '../components/serviceBooks/AddEditServiceBook';
import { layoutFlexRow } from '../layout';
import { AddRounded, ArrowRightAltRounded, ArrowRightRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { CustomIcon } from '../components/icons/CustomIcon';

export default function ServiceBookContainersContainer() {
  const { t } = useLocales();
  const { details } = useAppSelector(store => store.customer);

  const [showAddNew, setShowAddNew] = useState(false);
  const navigate = useNavigate();
  if (!details) {
    return <Box>Customer not found</Box>;
  }
  return (
    <Container>
      <SidePanel
        open={ showAddNew }
        close={() => setShowAddNew(false) }
      >
        <Box sx={{ ml: 2, mr: 2, mb: 2, p: 0 }}>
          <Typography sx={{ mb: 2 }} variant="h6">{t('Add new service book')}</Typography>
          <AddEditServiceBook new close={ () => setShowAddNew(false) } />
        </Box>
      </SidePanel>
      <Box sx={ containerPaperStyle }>
        <Box>
          <Typography sx={ headerStyles }>{t('Service books')}</Typography>
        </Box>

        <Box sx={{
          ...layoutFlexRow,
        }}>
          {
            details.serviceBooks.map(serviceBook =>
              <Card
                key={`book_${serviceBook.id}`}
                sx={{
                  width: { md: '40%', xs: '100%' },
                  p: { xs: 0.2, md: 2 }, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 2 }, mt: 2,
                  cursor: 'pointer'
                }}
                onClick={() => navigate(`/service-books/${serviceBook.id}/status`)}
              >
                <Box sx={{ mt: '10%', height: '180px', color: colors.darkerGreen }}>
                  {
                    serviceBook.type === 'building' &&
                      <CustomIcon type='house' />
                  }
                  {
                    serviceBook.type === 'vehicle' &&
                      <CustomIcon type='car' />
                  }
                  {
                    serviceBook.type === 'other' &&
                      <CustomIcon type='person' />
                  }

                </Box>
                <CardContent>
                  <Typography variant="h4" sx={ subHeaderStyles } component="div">
                      { serviceBook.name }
                    </Typography>
                    <Typography sx={{ mt: 2, display: 'flex', fontSize: 18 }} color="secondary.main" gutterBottom>
                      { t(serviceBook.type) }
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                      <Link
                        component={RouterLink}
                        sx={{ textDecoration: 'none'}}
                        to={`/service-books/${serviceBook.id}/status`}
                      >
                        <Button
                          sx={{ fontSize: 18, textTransform: 'none', color: 'secondary.main' }}
                          size="small"
                          endIcon={<ArrowRightAltRounded/>}
                        >
                          {t('Open')}
                        </Button>
                      </Link>
                    </Box>
                </CardContent>
              </Card>
            )
          }
          {
            details.isActive &&
            <Card sx={{ width: { md: '30%', xs: '90%' }, p: 2, m: 2, cursor: 'pointer' }} onClick={() => setShowAddNew(true) }>
              <CardContent sx={{ height: '100px', textAlign: 'center', mt: { xs: '15%', md: '30%' } }}>
                <Button sx={{ color: 'secondary.main', textTransform: 'none' }} endIcon={<AddRounded />}>{t('Add new')}</Button>
              </CardContent>
            </Card>
          }
        </Box>
      </Box>
    </Container>
  );
}