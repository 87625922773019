import { CheckBoxOutlineBlankRounded, CheckCircleOutlineRounded, Flag, InfoRounded, NotificationAddRounded, NotificationsActiveRounded } from "@mui/icons-material";
import { Button, IconButton, List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { colors } from '../../../styles/themeStylesSx';
import { getCurrentSeason, isInThePast, parseAndFormatDate } from '../../helpers/helpers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useLocales from '../../hooks/useLocales';
import { getCustomer } from '../../redux/customer/customerSlice';
import { getServiceCaseCalendarForSeason, ServiceBookCalendarItem } from "../../redux/serviceBooks/serviceBookSlice";
import { useDeleteProfileReminderMutation } from '../../redux/services/userProfileService';
import { ReminderType } from '../../types/reminder-type';
import { ServiceBookType } from "../../types/serviceBook";
import ConfirmableButton from '../buttons/ConfirmableButton';
import AddEditReminderComponent from '../reminders/addEditReminderComponent';
import AddEditServiceCase from "../serviceCases/AddEditServiceCase";
import ServiceCaseInfo from "../serviceCases/ServiceCaseInfo";
import SidePanel from "../sidepanel/SidePanel";
import { SeasonType } from './ServiceCalendar';

type Props = {
  seasonItems: ServiceBookCalendarItem[],
  serviceBook: ServiceBookType,
  season: SeasonType,
  year: number,
};

type StateType = {
  showCase: boolean;
  showInfo: boolean;
  showFlagged?: boolean;
  showAddReminder?: boolean;
  showReminder?: boolean;
  id?: number;
}
export default function ServiceBookCalendarSeasonItems(props: Props) {
  const seasonItems = props.seasonItems;
  const serviceBook = props.serviceBook;
  const [state, setState] = useState<StateType>({
    showInfo: false,
    showCase: false,
  });
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { details: customer } = useAppSelector(store => store.customer);
  const [ deleteReminder, { isLoading: deleting, isSuccess: deleted }]  = useDeleteProfileReminderMutation();

  const reminderAvailable = (customer?.reminders?.length || 0) < (customer?.reminderLimit || 0);

  useEffect(() => {
    if (deleted === true) {
      dispatch(getServiceCaseCalendarForSeason({ serviceBookId: serviceBook.id, season: props.season, year: props.year }));
      dispatch(getCustomer());
    }
  }, [deleted]);

  const cancelEdits = () => {
    setState({
      ...state,
      showFlagged: false, id: undefined, showInfo: false, showCase: false,
      showAddReminder: false, showReminder: false
    });
  };

  const getReminderComponent = (id: number) => {
    const reminder = customer?.reminders.find(row => row.id === id);
    if (!reminder || !reminder.scheduledAt) {
      return <Box>{t('No reminder found')}</Box>;
    }
    return (
      <Box>
        <Box sx={{ my : 1 }}>{t('Scheduled at')}: { parseAndFormatDate(reminder.scheduledAt, 'dd.MM.yyyy HH:mm:ss') } </Box>
        {
          reminder.fulfilledAt && <Box sx={{ my : 2 }}>{t('Sent at')}: { parseAndFormatDate(reminder.fulfilledAt, 'dd.MM.yyyy HH:mm:ss') }</Box>
        }
        {
          !deleting && !reminder.fulfilledAt &&
            <Box sx={{ my: 2 }}>
              <ConfirmableButton buttonText={t('Delete reminder')} onSubmit={ () => reminder.id && deleteReminder(reminder.id) } />
            </Box>
        }

      </Box>
    );
  };

  const getColorForReminder = (reminder: ReminderType, inPast: boolean): 'primary.main' | 'secondary.main' | 'gray' => {
    if (reminder.fulfilledAt) {
      return 'primary.main';
    }
    if (inPast) {
      return 'gray';
    }
    return 'secondary.main';
  };

  const selectedPlanItem = state.id !== undefined ? seasonItems.find(row => row.servicePlanId === state.id) || null : null;
  const planItem = serviceBook.servicePlanItems?.find(row => row.id === selectedPlanItem?.servicePlanId);

  const currentSeason = getCurrentSeason();
  const currentYear = new Date().getFullYear();
  const inPast = isInThePast(currentSeason, currentYear, props.season, props.year);
  return (
    <Box>
      {
        selectedPlanItem && state.showFlagged &&
        <SidePanel
          open={ state.showFlagged }
          close={ cancelEdits }
        >
          <AddEditServiceCase
            cancel={ cancelEdits }
            serviceBook={ serviceBook }
            serviceName={ selectedPlanItem.name }
            serviceCase={ selectedPlanItem.serviceCase }
            source="dashboard"
          />
        </SidePanel>
      }
      {
        selectedPlanItem ?
          <SidePanel
            open={ state.showInfo }
            close={ cancelEdits }
          >
            <ServiceCaseInfo
              close={ cancelEdits }
              serviceItem={ planItem } />
          </SidePanel> : <></>
      }
      {
        state.showAddReminder &&
        <SidePanel
          open={ state.showAddReminder }
          close={ cancelEdits }
        >
          <AddEditReminderComponent
            items={serviceBook.servicePlanItems || []}
            selectedId={ state.id || undefined }
            serviceBookId={serviceBook.id}
            season={props.season}
            year={props.year}
            close={ cancelEdits }
          />
        </SidePanel>
      }
      {
        state.showReminder && state.id &&
        <SidePanel
          open={ state.showReminder }
          close={ cancelEdits }
        >
          {
            getReminderComponent(state.id)
          }
        </SidePanel>
      }
      <SidePanel
        open={ state.showCase }
        close={ cancelEdits }
      >
        <Box sx={{ pl: 2 }}>
          {
            selectedPlanItem && <div>
              <AddEditServiceCase
                cancel={ cancelEdits }
                serviceBook={ serviceBook }
                serviceName={ selectedPlanItem.name }
                serviceCase={ selectedPlanItem.serviceCase || {
                  date: new Date(),
                  serviceBookId: serviceBook.id,
                  servicePlanItemId: selectedPlanItem.servicePlanId,
                  cost: 0.0,
                  comment: '',
                  type: planItem ? planItem.type : 'serviceCase',
                }}
                source="dashboard"
              />
            </div>
          }

        </Box>
      </SidePanel>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ my: 2, marginLeft: 'auto' }}>
          {
            <Button
              size='small'
              variant='outlined'
              startIcon={<NotificationAddRounded />}
              onClick={() => setState({ ...state, showAddReminder: true })}
            >
              {t('Add reminder')}
            </Button>
          }
        </Box>
      </Box>
      {
        seasonItems && seasonItems.length > 0 && <List>
          {
            seasonItems.map((item, i) => {
              const isFlagged = item.serviceCase && (item.serviceCase.attention === true);
              const isDone = item.serviceCase !== undefined && (item.serviceCase.actionDate || (item.serviceCase.date && !isFlagged));
              const reminder = customer?.reminders?.find(row => {
                return row.servicePlanItemId === item.servicePlanId;
              });
              return (
                <ListItem sx={{
                  borderRadius: 1,
                  height: '50px',
                  backgroundColor: i % 2 ? colors.backgroundGreen : 'transparent'
                }} key={`service_${i}`}>
                  { item.name }
                  <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                    {
                      reminder &&
                        <IconButton disabled={ inPast } onClick={() => setState({ ...state, showReminder: true, id: reminder.id })}>
                          <NotificationsActiveRounded sx={{ color: getColorForReminder(reminder, inPast) }} />
                        </IconButton>
                    }
                    {
                      isDone ?
                        <IconButton
                          onClick={() => setState({ ...state, showCase: true, id: item.servicePlanId })}
                          sx={{ color: 'secondary.main', pr: 1 }}
                        >
                          <CheckCircleOutlineRounded />
                        </IconButton>:
                        <>
                          <IconButton size="small"  onClick={() => setState({ ...state, showInfo: true, id: item.servicePlanId })}><InfoRounded /></IconButton>

                          {
                            isFlagged ?
                              <IconButton size="small" onClick={() => setState({ ...state, showFlagged: true, id: item.servicePlanId })}>
                                <Flag sx={{ color: 'secondary.main' }} />
                              </IconButton> :
                            <IconButton disabled={ !customer?.isActive } size="small"  onClick={() => setState({ ...state, showCase: true, id: item.servicePlanId })}>
                              <CheckBoxOutlineBlankRounded />
                            </IconButton>
                          }
                        </>
                    }
                  </Box>
                </ListItem>
              );
            })
          }
      </List>
      }
    </Box>
  );
}