import { AttachmentRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import { AttachmentType } from "../../types/attachment";

type Props = {
  attachments?: Partial<AttachmentType>[],
}
// If need to add more logic which icon to show, include it here
export default function AttachmentsIcon(props: Props) {
  const attachments = props.attachments;
  if (!attachments || attachments.length === 0) {
    return <></>;
  }
  const length = attachments.length;

  return (
    <AttachmentRounded />
  );
}