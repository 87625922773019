import { useAuth0 } from "@auth0/auth0-react";
import { Button, Paper, TextField, Typography, Box } from "@mui/material";
import useLocales from "../../hooks/useLocales";

export default function LoginPage() {

  const { loginWithRedirect } = useAuth0();
  const { t } = useLocales();
  return (
    <Paper sx={{ p: 2, pt: 10, pb: 10 }}>
      <Box sx={{ textAlign: 'center', mb: 3 }}><Typography variant="h3">KodinKanta</Typography></Box>
      <Box sx={{
        display: {
          md: 'flex',
          xs: 'block',
        },
        flexDirection: 'row'
      }}>
        <Box sx={{
          width: '50%',
          ml: 'auto',
          mr: 'auto',
          textAlign: 'center',
          pl: 2
        }}>

          <Button sx={{ mt: 2 }} variant="contained"  onClick={() => loginWithRedirect()}>{t('Kirjaudu sisään')}</Button>
        </Box>
      </Box>
    </Paper>
  );
}