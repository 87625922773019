import { Button, Divider, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import {
  AssessmentRounded, DashboardRounded, DirectionsCarRounded,
  HomeRounded, PersonRounded, QuestionMarkRounded,
  SettingsRounded, ReceiptLongOutlined, DescriptionOutlined, InsertChartOutlinedTwoTone, PlaylistAddCheckOutlined, StarOutlineOutlined, FilePresentOutlined
} from '@mui/icons-material';

import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { ServiceBookType } from '../../types/serviceBook';
import React, { useState } from 'react';
import { Box } from '@mui/system';
import Logo from '../../logo/KodinKanta-logo-sloganiton-RGB1.svg';
import { colors } from '../../../styles/themeStylesSx';


export type NavigationItemType = {
  label: string,
  icon?: string,
  path: string,
  subLinks?: NavigationItemType[],
  feature?: string;
}

const items: NavigationItemType[] = [
  {
    label: 'Landing',
    path: '/',
    icon: 'landing'
  },
  /*{
    label: 'Service status',
    path: '/dashboard',
    icon: 'dashboard',
  },*/
  {
    label: 'Service books',
    path: '/service-books',
    icon: 'folder',
  },
  // {
  //   label: 'Fixed costs',
  //   path: '/fixed-costs',
  //   icon: 'money',
  // },
  {
    label: 'Documents',
    icon: 'document',
    path: '/documents',
    },
  {
    label: 'Warranty receipts',
    icon: 'receipts',
    feature: 'receipts',
    path: '/receipts',
  },
  {
    label: 'Agreements',
    icon: 'agreements',
    feature: 'agreements',
    path: '/agreements',
  },
  /*
  Hidden for now.
  {
    label: 'Reports',
    icon: 'reports',
    path: '/reports/receipts',
  }*/
];

const getIcon = (icon?: string) => {
  switch (icon) {
  case 'home':
  case 'building':
    return <HomeRounded />;
  case 'folder':
    return <PlaylistAddCheckOutlined />;
  case 'receipts':
    return <ReceiptLongOutlined />;
  case 'agreements':
    return <DescriptionOutlined />;
  case 'reports':
    return <AssessmentRounded />;
  case 'dashboard':
    return <DashboardRounded />;
  case 'vehicle':
    return <DirectionsCarRounded />;
  case 'document':
    return <FilePresentOutlined />;
  case 'person':
  case 'other':
    return <PersonRounded />;
  case 'money':
    return <InsertChartOutlinedTwoTone />;
  case 'landing':
    return <StarOutlineOutlined />;
  default:
    return <QuestionMarkRounded />;
  }
};

export const drawerWidth = 300;

export default function SideNavigation() {
  const { t } = useLocales();

  const { details: customer } = useAppSelector(store => store.customer);
  const { profile } = useAppSelector(store => store.profile);
  const [showSidebar, toggleShowSidebar] = useState(false);
  const location = useLocation();

  const enabledFeatures = customer?.subscription?.features?.map(row => row.name) || [];

  const isServiceBookSelected = (location.pathname || '').indexOf(`/service-books`) !== -1;

  if (!customer) {
    return <>..</>;
  }

  const renderList = () => {
    return (
      <>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
            <Box sx={{ width: '240px' }}>
              <img width="100%" height="100%" src={Logo} alt="KodinKanta" />
            </Box>
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ ml: 'auto', display: { xs: 'block', md: 'none' } }}
              onClick={ () => toggleShowSidebar(false) }
            >
              X
            </IconButton>
          </Box>
        <List>
          {
            items.filter(row => {
              if (row.path === '/fixed-costs' && (!customer?.serviceBooks || customer?.serviceBooks.length === 0)) {
                return false;
              }
              return true;
            }).map((item: NavigationItemType) => {
              const pathSplit = location.pathname.split('/').filter(r => r !== '');
              const itemSplit = item.path.split('/').filter(r => r !== '');
              let isTopSelected = (item.path === '/' && pathSplit.length === 0) ? location.pathname === '/' : pathSplit[0] === itemSplit[0];
              if (item.label === 'Service books' && pathSplit.length > 1) {
                isTopSelected = false;
              }
              const topBackgroundColor = isTopSelected ? colors.highlightItem : '#fff';
              const expandBooks = isServiceBookSelected;
              let isEnabled = false;
              if (!item.feature || item.feature === '' || customer.isTesting) {
                isEnabled = true;
              }
              else if (enabledFeatures.includes(item.feature)){
                isEnabled = true;
              }
              return (
                <React.Fragment key={item.label}>
                  <ListItemButton
                    disabled={ !isEnabled }
                    sx={{
                      m: 0,
                      pt: 2,
                      pb: 2,
                      backgroundColor: topBackgroundColor,
                      color: isTopSelected ? '#fff' : 'secondary.main',
                      '&:hover': { backgroundColor: colors.navigationSelectedList, color: 'secondary.main' },
                    }}
                    component={RouterLink}
                    to={item.path}
                    onClick={ () => toggleShowSidebar(false)}
                  >
                    <ListItemIcon
                      sx={{
                        fontSize: 20,
                      }}
                    >{ getIcon(item.icon) }</ListItemIcon>
                    <ListItemText
                      sx={{ my: 0 }}
                      primary={t(item.label)}
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        letterSpacing: 0,
                      }}
                    />
                  </ListItemButton>
                  {
                    expandBooks && item.label && item.label === 'Service books' && customer && customer.serviceBooks.map((target: ServiceBookType, idx: number) => {
                      const isSelected = parseInt(pathSplit[1]) === target.id;
                      const backgroundColor = isSelected ? colors.highlightItem : colors.navigationSelectedList;
                      return (
                        <ListItemButton
                          sx={{
                            ml: 0,
                            pl: 5,
                            pt: 2,
                            pb: 2,
                            backgroundColor,
                            color: isSelected ? '#fff' : 'secondary.main',
                            '&:hover': { backgroundColor: colors.highlightItem, color: '#fff' },
                          }}
                          key={`${idx}_${target.name}`}
                          component={RouterLink}
                          to={`/service-books/${target.id}/status`}
                          onClick={ () => toggleShowSidebar(false) }
                        >
                          <ListItemIcon
                            sx={{
                              fontSize: 20,
                              color: 'inherit',
                            }
                          }>{ getIcon(target.type) }</ListItemIcon>
                          <ListItemText
                            sx={{ my: 0 }}
                            primary={target.name}
                            primaryTypographyProps={{
                              fontSize: 15,
                              fontWeight: 400,
                              letterSpacing: 0,
                            }}
                          />
                        </ListItemButton>
                      );
                    })
                  }
                </React.Fragment>
              );
            })
          }
        </List>
        {
          profile && profile.level === 'admin' &&
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2, mt: 'auto', borderTop: 'thin solid gray' }}>
              <Link component={RouterLink} to="/admin">
                <Button sx={{ color: 'secondary.main' }} startIcon={<SettingsRounded />}
                  onClick={ () => toggleShowSidebar(false) }
                >
                  {t('Settings')}
                </Button>
              </Link>
            </Box>
        }
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', lg: 'none' },
        }}>
          <Box
            sx={{ zIndex: 1200, ml: 2, position: 'fixed', top: '5px', left: '10px' }}
            onClick={ () => toggleShowSidebar(true) }
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={ () => toggleShowSidebar(true) }
            >
              <MenuIcon />
            </IconButton>
          </Box>
        <Drawer
          sx={{
            zIndex: 1300,
            '& .MuiDrawer-paper': {
              width: {
                sm: drawerWidth,
                xs: drawerWidth - 20,
              },
              boxSizing: 'border-box',
              // backgroundColor: '#96CBA0',
            },
          }}
          variant="temporary"
          anchor="left"
          open={showSidebar}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={() => toggleShowSidebar(false)}
        >
          {renderList()}
        </Drawer>
      </Box>
      <Box sx={{
          display: { xs: 'none', lg: 'block' },
          width: drawerWidth
        }}
      >
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              // backgroundColor: '#96CBA0',
            },
            width: drawerWidth,
          }}
        >
          {renderList()}
        </Drawer>
      </Box>

    </>
  );
}