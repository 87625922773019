import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosRequestConfig } from 'axios';
import { selectDetails } from '../redux/customer/customerSlice';
import { showNotification } from '../redux/notificationSlice';
import { getToken } from '../redux/profile/profileSlice';
import { CustomerType } from '../types/customer';

export type ApiRequestType = {
  path: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  data?: any;
  token?: string | null;
  dispatch?: any;
  thunkApi?: any;
  files?: any;
}

export const rtkQueryRequest = async (args: any, store: any, options: any) => {
  const storeState = store.getState();
  const baseUrl = process.env.REACT_APP_API_URL;
  const customer = storeState.customer?.details;

  const rtkQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }: { getState: any }) => {
      const token = getState().profile?.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (customer) {
        headers.set('x-customer-id', customer.id);
      }
      return headers;
    }
  });
  return rtkQuery(args, store, options);
};

export const apiRequest = async (params: ApiRequestType): Promise<any> => {
  const { token: tokenProp, path, method, data, thunkApi } = params;
  let token = tokenProp;
  let dispatch: any;
  let customer: CustomerType | null = null;
  if (thunkApi) {
    const state = thunkApi.getState();
    token = getToken(state);
    customer = selectDetails(state);
    dispatch = thunkApi.dispatch;
  }
  const fullUrl = `${process.env.REACT_APP_API_URL}${path}`;

  const headers: any = {
    'Authorization': `Bearer ${token}`
  };
  if (customer && customer.id) {
    headers['x-customer-id'] = customer.id;
  }
  if (['PUT', 'POST', 'PATCH'].includes(method)) {
    headers['Content-Type'] = 'application/json';
  }
  const axiosOptions: AxiosRequestConfig = {
    url: fullUrl,
    method,
    data: data ? JSON.stringify(data) : undefined,
    headers
  };

  if (params.files) {
    const formData = new FormData();
    params.files.forEach((file: any) => formData.append('file[]', file));
    axiosOptions.data = formData;
    headers['Content-type'] = 'multipart/form-data';
  }
  const response = await axios(axiosOptions).catch(error => {
    console.error('AXIOS error', error);
    if (dispatch) {
      const errorRes = error.response?.data;
      dispatch(showNotification({ message: `${path} / ${ errorRes.message || error.message }`, severity: 'error' }));
    }
    throw error;
  });
  if (!response) {
    console.error(`Error with request to: ${fullUrl}`, response);
    throw new Error(`API request ${params.path} failed`);
  }
  return response.data;

};