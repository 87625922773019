import { Button, Container, Box, Card, Typography, CardContent, Link, SxProps, Alert } from '@mui/material';
import useLocales from '../hooks/useLocales';
import { useAppDispatch, useAppSelector } from '../hooks';
import { addDays, addMonths, differenceInDays, parseISO } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import { getCustomerDashboardAction } from '../redux/customer/customerSlice';
import { colors, containerPaperStyle, headerStyles } from '../../styles/themeStylesSx';
import { layoutFlexRow } from '../layout';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  ArrowRightAltRounded,
} from '@mui/icons-material';
import { CustomIcon } from '../components/icons/CustomIcon';
import AddEditServiceBook from '../components/serviceBooks/AddEditServiceBook';
import SidePanel from '../components/sidepanel/SidePanel';
import CustomerSubscription from '../components/customer/CustomerSubscription';

const cardStyle: SxProps = {
  width: { md: '40%', xs: '90%' },
  ml: { md: 2, xs: 0 },
  p: { md: 2, xs: 0.5 }, m: { md: 2, xs: 0.5 }, cursor: 'pointer'
};

export default function DashboardContainer() {
  const { t } = useLocales();
  const navigate = useNavigate();

  const [state, setState] = useState<any>({ selectSubscription: false, showAddServiceBook: false });
  const dispatch = useAppDispatch();
  const { details, dashboard, loadedDashboard } = useAppSelector(store => store.customer);
  const { profile } = useAppSelector(store => store.profile);
  const { added } = useAppSelector(store => store.serviceBook);

  useEffect(() => {
    if (!loadedDashboard) {
      dispatch(getCustomerDashboardAction());
    }
  }, [loadedDashboard]);

  useEffect(() => {
    if (added === true) {
      dispatch(getCustomerDashboardAction());
    }
  }, [added]);

  const linkCards: { label: string, icon: ReactElement, link: string }[] = [
    {
      label: t('Agreements'),
      link: '/agreements',
      icon: <CustomIcon type="agreement" />,
    },
    {
      label: t('Receipts'),
      link: '/receipts',
      icon: <CustomIcon type='receipt' />,
    },
    {
      label: t('Documents'),
      link: '/documents',
      icon: <CustomIcon type='document' />,
    },
  ];
  if (!details) {
    return <>{t('Something went wrong. Customer not found')}</>;
  }
  const createdDateParsed = parseISO(details.createdAt);
  return (
    <Container sx={{ mt: { xs: 2 }}}>
      <SidePanel
        open={ state.showAddServiceBook }
        close={() => setState({ ...state, showAddServiceBook: false }) }
      >
        <Box sx={{ ml: 2, mr: 2, mb: 2, p: 0 }}>
          <Typography sx={{ mb: 2 }} variant="h6">{t('Add new service book')}</Typography>
          <AddEditServiceBook new close={ () => setState({ ...state, showAddServiceBook: false }) } />
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.selectSubscription }
        close={() => setState({ ...state, selectSubscription: false }) }
      >
        <Box sx={{ ml: 2, mr: 2, mb: 2, p: 0 }}>
          <Typography variant="h5" sx={{ pb: 0, ml: { lg: 2, md: 0} }}>{t('Activate service')}</Typography>
          <CustomerSubscription
            cancel={ () => setState({ ...state, selectSubscription: false })}
          />
        </Box>
      </SidePanel>
      <Box sx={ containerPaperStyle }>
      {
        profile && !details.subscription &&
        <Alert severity="warning" sx={{ mt: 0, mb: 1 }}>

            {
              (!details.subscriptionId && !details.isTesting) &&
              <Box sx={{ cursor: 'pointer' }} onClick={() => setState({ ...state, selectSubscription: true })}>
                { t('Your test subscription has ended') }
              </Box>
            }

            {
              !details.subscriptionId && details.isTesting &&
              <Link
                underline="none"
                component={RouterLink}
                to='/profile/customer'
              >
                { t('Your test subscription ends in {1} days', [`${30 - differenceInDays(new Date(), createdDateParsed)}`]) }
              </Link>
            }
        </Alert>

      }
        {
          profile && profile.email === profile.name &&
            <Alert severity="info" sx={{ mt: 2, mb: 1 }}>
              <Link
                underline="none"
                component={RouterLink}
                to='/profile'
              >
                {t('Finish your profile details from here!')}
              </Link>
            </Alert>
        }

        <Box sx={{
          ...layoutFlexRow,
        }}>
          {
            (details?.serviceBooks || []).length === 0 && details.isActive &&

            <Card sx={{ width: { md: '40%', xs: '85%' }, p: 2, m: 2, pt: 5, cursor: 'pointer' }} onClick={() => setState({ ...state, showAddServiceBook: true }) }>
              <CardContent sx={{ height: '100px', textAlign: 'center', mt: { xs: '15%', md: '30%' } }}>
                <Button sx={{ color: 'secondary.main', textTransform: 'none', fontWeight: 'bold', fontSize: '1.2em' }}>{t('Add your first service book from here')}</Button>
              </CardContent>
            </Card>
          }

          {
            (details?.serviceBooks || []).map(serviceBook => {
              const status = dashboard?.find(row => row.serviceBookId === serviceBook.id);
              return (
                <Card
                  key={`book_${serviceBook.id}`}
                  sx={cardStyle}
                  onClick={() => navigate(`/service-books/${serviceBook.id}/status`)}
                >
                  <Box sx={{ height: '30px', ml: 'auto', textAlign: 'right', fontSize: 18, color: 'secondary.main', }}>
                    {
                      status && status.todo > 0 && <>{t('Inspection status')}: { status?.done } / { status.todo}</>
                    }
                  </Box>
                  <Box sx={{ mt: 6, mb: 3, height: '180px', color: colors.darkerGreen }}>
                    {
                      serviceBook.type === 'building' &&
                        <CustomIcon type='house' />
                    }
                    {
                      serviceBook.type === 'vehicle' &&
                        <CustomIcon type='car' />
                    }
                    {
                      serviceBook.type === 'other' &&
                        <CustomIcon type='person' />
                    }

                  </Box>
                  <CardContent>
                    <Typography sx={headerStyles} component="div">
                      { serviceBook.name }
                    </Typography>
                    <Typography sx={{ mt: 2, display: 'flex', fontSize: 18 }} color="secondary.main" gutterBottom>
                      { t(serviceBook.type) }
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                      <Link
                        component={RouterLink}
                        sx={{ textDecoration: 'none'}}
                        to={`/service-books/${serviceBook.id}/status`}
                      >
                        <Button
                          sx={{ fontSize: 18, textTransform: 'none', color: 'secondary.main' }}
                          size="small"
                          endIcon={<ArrowRightAltRounded/>}
                        >
                          {t('Open')}
                        </Button>
                      </Link>
                    </Box>
                  </CardContent>
                </Card>
              );
            })
          }
          {
            linkCards.map(linkCard =>
              <Card
                  key={`link_${linkCard.label}`}
                  sx={cardStyle}
                  onClick={() => navigate(linkCard.link)}
                >
                  <Box sx={{ mt: 6, mb: 3, height: '180px', color: colors.darkerGreen }}>
                    { linkCard.icon }

                  </Box>
                  <CardContent>
                    <Typography sx={{ ...headerStyles }} component="div">
                      { linkCard.label }
                    </Typography>
                    <Box sx={{ ml: 'auto', textAlign: 'right', }}>
                      <Link
                        component={RouterLink}
                        sx={{ textDecoration: 'none'}}
                        to={linkCard.link}
                      >
                        <Button
                          sx={{ fontSize: 18, textTransform: 'none', color: 'secondary.main' }}
                          size="small"
                          endIcon={<ArrowRightAltRounded/>}
                        >
                          {t('Open')}
                        </Button>
                      </Link>
                    </Box>
                  </CardContent>
                </Card>
            )
          }
        </Box>
      </Box>
    </Container>
  );
}
