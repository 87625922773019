import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '../../helpers/apiHelper';
import { isCustomerActive } from '../../helpers/helpers';
import { RootState } from '../../store';
import { CustomerSubmitType, CustomerType } from '../../types/customer';
import { DashboardServiceBookDto } from '../../types/dashboard';
import { UpcomingServiceCaseType } from '../../types/serviceBook';
import { getToken } from '../profile/profileSlice';

export type CustomerState = {
  details: CustomerType | null;
  list: CustomerType[] | null;
  loading: boolean;
  loaded: boolean;
  saving:boolean;
  saved:boolean;
  reload:boolean;
  error: string | null;
  loadingDashboard: boolean;
  loadedDashboard: boolean;
  dashboard?: DashboardServiceBookDto[];
  updating: boolean;
  updated: boolean;
}

const initialState: CustomerState = {
  list: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  reload: true,
  error: null,
  details: null,
  loadingDashboard: false,
  loadedDashboard: false,
  dashboard: [],
  updating: false,
  updated: false,
};

export const getCustomer = createAsyncThunk<any>('customer/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: `/customer/for_user`, method: 'GET', thunkApi });
});

export const getCustomerDashboardAction = createAsyncThunk<any>('customer/dashboard', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/customer/dashboard', method: 'GET', thunkApi });
});

export const updateOwnCustomerAction = createAsyncThunk<CustomerType, any>('customer/update', async  (params: any, thunkApi: any) => {
  if (!params.id) {
    return;
  }
  return await apiRequest({ path: `/customer/${params.id}`, method: 'PATCH', data: params.data, thunkApi });
});

export const enableSubscriptionAction = createAsyncThunk<CustomerSubmitType, any>('customer/subscription/activate', async (props: CustomerSubmitType, thunkApi: any) =>
  await apiRequest({ path: `/customer/${props.id}/subscription/activate`, method: 'POST', data: props, thunkApi })
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedCustomerAction: (state, action: PayloadAction<number>) => {
      const found = state.list?.find(row => row.id === action.payload);
      if (found) {
        localStorage.setItem('customer', `${found.id}`);
        found.isActive = isCustomerActive(found);
        state.details = found;
        state.dashboard = [];
        state.loadedDashboard = false;
      }
    },
    reloadCustomerAction: (state) => {
      state.reload = true;
    },
    setUpcomingInspections: (state, action: PayloadAction<UpcomingServiceCaseType[]>) => {
      if (state.details) {
        state.details.upcomingInspections = action.payload;
      }
    },
    setUpcomingServiceCases: (state, action: PayloadAction<UpcomingServiceCaseType[]>) => {
      if (state.details) {
        state.details.upcomingServiceCases = action.payload;
      }
    }
  },
  extraReducers: (builder) => {

    builder.addCase(getCustomer.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
      state.reload = false;
    });

    builder.addCase(getCustomer.fulfilled, (state, action: PayloadAction<CustomerType> | PayloadAction<CustomerType[]>) => {
      state.loading = false;
      state.loaded = true;

      // Handle case where customers response is array
      if (action.payload && (action.payload instanceof Array)) {
        state.list = action.payload;
        const instore = localStorage.getItem('customer');
        const exist = action.payload?.find(row => instore && row.id === +instore);
        if (exist) {
          exist.isActive = isCustomerActive(exist);
          state.details = exist;
        }
        else {
          const details = action.payload[0];
          details.isActive = isCustomerActive(details);
          state.details = details;
        }
      }
      else {
        const details = action.payload;
        details.isActive = isCustomerActive(details);
        state.details = details;
      }
    });
    builder.addCase(getCustomer.rejected, (state, action: any) => {
      console.log('Error fetching customer', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload || 'Error with api';
    });

    builder.addCase(getCustomerDashboardAction.pending, (state, action: PayloadAction<any>) => {
      state.loadingDashboard = true;
    });

    builder.addCase(getCustomerDashboardAction.fulfilled, (state, action: PayloadAction<DashboardServiceBookDto[]>) => {
      state.loadingDashboard = false;
      state.loadedDashboard = true;
      state.dashboard = action.payload;
    });

    builder.addCase(updateOwnCustomerAction.pending, (state, action: any) => {
      state.updating = true;
      state.updated = false;
    });

    builder.addCase(updateOwnCustomerAction.rejected, (state, action: PayloadAction<any>) => {
      state.updating= false;
      state.updated = false;
      state.error = action.payload;
    });

    builder.addCase(updateOwnCustomerAction.fulfilled, (state, action: PayloadAction<CustomerType>) => {
      state.updating = false;
      state.updated = true;
      state.reload = true;
    });

    builder.addCase(enableSubscriptionAction.pending, (state, action: any) => {
      state.updating = true;
      state.updated = false;
    });

    builder.addCase(enableSubscriptionAction.rejected, (state, action: PayloadAction<any>) => {
      state.updating= false;
      state.updated = false;
      state.error = action.payload;
    });

    builder.addCase(enableSubscriptionAction.fulfilled, state => {
      state.updating = false;
      state.updated = true;
      state.reload = true;
    });
  }
});

export const { setUpcomingInspections, setUpcomingServiceCases, reloadCustomerAction, setSelectedCustomerAction } = customerSlice.actions;

export const selectDetails = (state: RootState) => state.customer.details;

export default customerSlice.reducer;
