import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMonths } from 'date-fns';
import { apiRequest } from '../../helpers/apiHelper';
import { RootState, AppThunk } from '../../store';
import { AgreementType } from '../../types/agreement';
import { getToken } from '../profile/profileSlice';

export interface AgreementsState {
  list: AgreementType[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
  saving: boolean;
  saved: boolean;
  reload: boolean;
  deleting: boolean;
  deleted: boolean;
}
const fakeData = [

  { customerId: 1, id: 1, description: 'Puhelinliittymä Elisa', value: 39.9, unit: '€/kk', expiryDate: addMonths(new Date(), 5), created: new Date() },
  { customerId: 1, id: 2, description: 'Nettiliittymä DNA', value: 39.9, unit: '€/kk', expiryDate: addMonths(new Date(), 5), created: new Date() },
  { customerId: 1, id: 3, description: 'Sähkösopimus', value: 5.49, unit: 'c/kWh', expiryDate: addMonths(new Date(), 5), created: new Date() }

];
const initialState: AgreementsState = {
  list: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
  reload: false,
  deleting: false,
  deleted: false,
};

export const getAgreements = createAsyncThunk<AgreementType[]>('agreements/get', async (_: any, thunkApi: any) => {
  return await apiRequest({ path: '/agreement', thunkApi, method: 'GET' });
});

export const addAgreement = createAsyncThunk<void, Partial<AgreementType>>('agreements/add', async (data: Partial<AgreementType>, thunkApi: any) => {
  return await apiRequest({ path: '/agreement', data, thunkApi, method: 'POST' });
});
export const updateAgreement = createAsyncThunk('agreements/update', async (params: { id: number, data: Partial<AgreementType>}, thunkApi: any) => {
  return await apiRequest({ path: `/agreement/${params.id}`, data: params.data, thunkApi, method: 'PATCH' });
});

export const deleteAgreementAction = createAsyncThunk('agreements/delete', async (id: number, thunkApi: any) => {
  return await apiRequest({ path: `/agreement/${id}`, thunkApi, method: 'DELETE' });
});

export const agreementsSlice = createSlice({
  name: 'agreements',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      state.list = [];
      state.loaded = false;
      state.reload = true;
    },
    clearAgreementSavedAction: (state) => {
      state.saved = false;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(getAgreements.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.loaded = false;
      state.deleted = false;
    });

    builder.addCase(getAgreements.fulfilled, (state, action: PayloadAction<AgreementType[]>) => {
      state.loading = false;
      state.loaded = true;
      state.list = action.payload;
    });

    builder.addCase(getAgreements.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error getting agreements', action);
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    });

    builder.addCase(addAgreement.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(addAgreement.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(addAgreement.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error adding agreements', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(updateAgreement.pending, (state, _: any) => {
      state.saving = true;
    });
    builder.addCase(updateAgreement.fulfilled, (state, _: any) => {
      state.saving = false;
      state.saved = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(updateAgreement.rejected, (state, action: PayloadAction<any>) => {
      console.error('Error updating agreement', action);
      state.saving = false;
      state.saved = false;
      state.error = action.payload;
    });

    builder.addCase(deleteAgreementAction.pending, (state, _: any) => {
      state.deleting = true;
      state.deleted = false;
    });
    builder.addCase(deleteAgreementAction.fulfilled, (state, _: any) => {
      state.deleting = false;
      state.deleted = true;
      state.reload = true;
      state.loaded = false;
    });
    builder.addCase(deleteAgreementAction.rejected, (state, _: any) => {
      state.deleting = false;
      state.deleted = false;
    });
  }
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAgreemnts = (state: RootState) => state.agreements.list;

export const { clear: clearAgreementsState, clearAgreementSavedAction } = agreementsSlice.actions;

export default agreementsSlice.reducer;
