import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Button, Checkbox, CircularProgress, FormControlLabel, List, ListItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import SkeletonRows from '../layout/SkeletonRows';
import useLocales from '../../hooks/useLocales';
import { ServicePlanItemType } from '../../types/servicePlanItem';
import { orderBy } from 'lodash';
import { ServiceBookProperty } from '../../types/serviceBookProperty';
import { getAdminServiceBookPropertiesAction, saveAdminServiceBookPropertyMappingAction } from '../../redux/admin/adminServiceBookPropertySlice';

type Props = {
  close?: () => void,
}

const AdminMapItemsToServiceBookProperties = (props: Props) => {
  const { t } = useLocales();
  const dispatch = useAppDispatch();

  const { list: properties, loading: loadingProperties, savedMapping, savingMapping } = useAppSelector(store => store.adminServiceBookProperties);
  const { list: serviceItems, loading: loadingItems } = useAppSelector(store => store.adminServicePlan);
  const [state, setState] = useState<{ selectedProperty: null | number, selectedItems: number[], filterValue: string, }>({ selectedProperty: null, selectedItems: [], filterValue: '' });

  const getCurrentItemsForPropertyId = (id: number): number[] => {
    const item = properties?.find(row => row.id === id);
    if (item && item.servicePlanItems) {
      const ids = item.servicePlanItems.map((row: Partial<ServicePlanItemType>) => row && row !== undefined ? row.id : undefined);
      return ids.filter(row => row) as number[];
    }
    return [];
  };

  useEffect(() => {
    if (savedMapping) {
      setState({ ...state, selectedProperty: null, selectedItems: [] });
      dispatch(getAdminServiceBookPropertiesAction());
    }
  }, [savedMapping]);

  const selectProperty = (id: number, checked: boolean) => {
    const list = state.selectedItems.filter(row => row !== id);
    if (checked) {
      list.push(id);
    }
    setState({ ...state, selectedItems: list });
  };

  const submit = () => {
    if (!state.selectedProperty) {
      return;
    }
    const prop: Partial<ServiceBookProperty> = {
      id: state.selectedProperty,
      servicePlanItems: state.selectedItems.map(row => ({ id: row }))
    };
    dispatch(saveAdminServiceBookPropertyMappingAction(prop));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Box sx={{ width: '30%', borderRight: 'thin solid gray',  }}>
          {
            loadingProperties && <SkeletonRows rows={5} />
          }
          <Typography variant="h6">{t('Select property')}</Typography>
          <RadioGroup
            value={ state.selectedProperty }
            onChange={(e) =>
              setState({ ...state, selectedProperty: +e.target.value, selectedItems: getCurrentItemsForPropertyId(+e.target.value) })
            }
          >
            <List>
              {
                (properties || []).map(row =>
                  <ListItem
                    key={row.id}
                  >
                    <FormControlLabel
                      value={row.id}
                      control={<Radio color="primary" />}
                      label={row.property}
                    />
                  </ListItem>
                )
              }
            </List>
          </RadioGroup>
        </Box>
        <Box sx={{ width: '60%' }}>
          <Typography variant="h6">{t('Select service items')}</Typography>
          <TextField fullWidth label={t('Filter')} onChange={e => setState({...state, filterValue: e.target.value })} />
          <List>
            {
              orderBy(serviceItems, 'name').filter(row => state.filterValue.length > 0 ? row.name?.indexOf(state.filterValue) !== -1 : true)
                .map((item: Partial<ServicePlanItemType>) => {
                  if (!item.id || !item.name) {
                    return;
                  }
                  return (<ListItem key={`item_${item.id}`}>
                      <FormControlLabel
                        label={item.name}
                        control={
                          <Checkbox
                            onChange={ (e) => item && item.id && selectProperty(item.id, e.target.checked) }
                            checked={ state.selectedItems.find(row => row === item.id) ? true : false }
                          />
                        }
                      />
                    </ListItem>);
              })
            }
          </List>
        </Box>

      </Box>
      {
        state.selectedProperty && state.selectedItems &&
          <Box sx={{ position: 'fixed', bottom: 20 }}>
            {
              savingMapping ? <CircularProgress /> :
                <Button variant="contained" onClick={submit}>{t('Submit')}</Button>
            }
          </Box>
      }
    </Box>
  );
};

export default AdminMapItemsToServiceBookProperties;