import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import translationsReducer from './redux/translations/translationsSlice';
import agreementsReducer from './redux/agreements/agreementsSlice';
import receiptsReducer from './redux/receipts/receiptsSlice';
import documentsReducer from './redux/documents/documentsSlice';
import customerReducer from './redux/customer/customerSlice';
import serviceBookReducer from './redux/serviceBooks/serviceBookSlice';
import profileReducer from './redux/profile/profileSlice';
import adminServicePlanReducer from './redux/admin/adminServicePlanSlice';
import notificationReducer from './redux/notificationSlice';
import adminUserReducer from './redux/user/adminUserSlice';
import attachmentSlice from './redux/attachment/attachmentSlice';
import adminCustomerSlice from './redux/admin/adminCustomerSlice';
import reportSlice from './redux/report/reportSlice';
import fixedCostsSlice from './redux/fixedCosts/fixedCostsSlice';
import adminServiceBookPropertySlice from './redux/admin/adminServiceBookPropertySlice';
import { serviceBookApi } from './redux/services/serviceBookService';
import { userProfileApi } from './redux/services/userProfileService';
import { rtkErrorLoggerMiddleware } from './redux/middleware/rtkErrorMiddleware';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false })
    .concat(
      serviceBookApi.middleware,
      userProfileApi.middleware,
      rtkErrorLoggerMiddleware
    ),
  reducer: {
    translation: translationsReducer,
    agreements: agreementsReducer,
    receipts: receiptsReducer,
    documents: documentsReducer,
    customer: customerReducer,
    serviceBook: serviceBookReducer,
    profile: profileReducer,
    adminServicePlan: adminServicePlanReducer,
    notification: notificationReducer,
    adminUser: adminUserReducer,
    attachment: attachmentSlice,
    adminCustomer: adminCustomerSlice,
    reports: reportSlice,
    fixedCosts: fixedCostsSlice,
    adminServiceBookProperties: adminServiceBookPropertySlice,
    [serviceBookApi.reducerPath]: serviceBookApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
