/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { useAppDispatch } from '../../hooks';
import { CustomerType } from '../../types/customer';
import { getValueFromStateOrObject } from '../../helpers/helpers';
import { deleteLogFromCustomer, updateLogToCustomer, addLogToCustomer} from '../../redux/admin/adminCustomerSlice';

type AddEditNotePropsType = {
  cancel?: () => void;
  customer?: Partial<CustomerType>;
  saving?: boolean;
  update: boolean;
  logId?: number;
}

export default function AddEditAdminNote(props: AddEditNotePropsType) {
  const { customer, saving, cancel, update, logId } = props;
  const { t } = useLocales();
  const [state, setState] = useState<Partial<CustomerType> | any>({});
  const dispatch = useAppDispatch();

  const handleChange = (name: string, value: number | string | Date | null) => setState({ ...state, [name]: value });

  useEffect(() => {
    if (update) {
        const noteToUpdate: any = customer?.logs?.find((log: any) => logId === log.id);
        setState({ message: noteToUpdate?.message, logId: noteToUpdate.id });
    }
    else setState({message: ''});
    }, [props.logId]);


  const submit = () => {
    const message: any = ({message: state.message});

    if (customer && customer.id !== undefined) {
      if (!update) {
        dispatch(addLogToCustomer({ id: customer.id, message: message }));
      } else {
        dispatch(updateLogToCustomer({ id: customer.id, logId: state.logId, message }));
      }
      if (cancel) {
        cancel();
      }
    }
  };
  const deleteNote = () => {
    if (customer && customer.id !== undefined) {

      dispatch(deleteLogFromCustomer({ id: customer.id, logId: state.logId , message: state.message}));
      if (cancel) {
        cancel();
      }
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <TextField
        sx={{ mt: 1 }}
        label={t('Note')}
        multiline
        rows={5}
        variant="outlined"
        fullWidth
        value={ getValueFromStateOrObject(state, customer, 'message') }
        onChange={(e) => handleChange('message', e.target.value)}
      />

      <Box>
        <Box sx={{ mt: 1 }}>
          {
          (saving && saving === true) ? <CircularProgress />
            :
            <Box>
              <Button color="primary" variant="contained" sx={{ ml: 1 }}
                onClick= {submit}>
                {t('Submit')}
              </Button>
              {update &&
                <Button color="primary" variant="contained" sx={{ ml: 1 }}
                  onClick= {deleteNote}>
                    {t('Delete')}
                </Button>
              }
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
}