import { SxProps, Theme } from '@mui/material/styles';

export const layoutWidths: SxProps = {
  width: {
    md: '50%',
    xs: '90%',
  },
  textAlign: {
    md: 'left',
    xs: 'center',
  }
};

export const layoutFlexRow: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
};

export const mobileWidth50: SxProps = {
  width: {
    md: '50%',
    xs: '90%',
  }, 
};