import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import AddEditServiceCase from './AddEditServiceCase';
import { ServiceBookType, ServiceCaseType } from '../../types/serviceBook';
import { valueAsDate } from '../../helpers/helpers';
import useLocales from '../../hooks/useLocales';
import { EditRounded, KeyboardArrowLeft } from '@mui/icons-material';
import { format } from 'date-fns';
import ListAttachments from '../attachments/ListAttachments';
import ConfirmableButton from '../buttons/ConfirmableButton';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteServiceCaseAction } from '../../redux/serviceBooks/serviceBookSlice';

type Props = {
  cancel: () => void,
  serviceBook: ServiceBookType,
  serviceCase?: ServiceCaseType,
}

export default function ServiceCaseComponent(props: Props) {
  const { serviceBook, serviceCase } = props;

  const [state, setState] = useState({ edit: false });
  const { t } = useLocales();
  const dispatch = useAppDispatch();
  const { deleting } = useAppSelector(store => store.serviceBook);
  const { details: customer } = useAppSelector(store => store.customer);

  useEffect(() => {
    setState({ edit: false });
  }, [serviceCase]);

  const deleteItem = (id: number) => {
    if (id && serviceBook && serviceBook.id) {
      dispatch(deleteServiceCaseAction({ id, serviceBookId: serviceBook.id }));
    }
  };

  if (!serviceCase) {
    return <Box>{t('Given service case not found')}</Box>;
  }
  if (state.edit) {
    return (
      <AddEditServiceCase
        cancel={() => setState({ edit: false })}
        serviceBook={ serviceBook }
        serviceCase={ serviceCase }
      />
    );
  }

  return (
    <Box sx={{ mt: 1, ml: 1 }}>

      <Box sx={{ mt: 1, fontWeight: 'bold', display: 'flex' }}>
        <span>{ serviceCase.servicePlanItem ? serviceCase.servicePlanItem.name : t('Other') }</span>
        <Box sx={{ ml: 'auto' }}>
          { customer?.isActive && <ConfirmableButton buttonText={t('Delete')} showLoader={ deleting } onSubmit={ () => deleteItem(serviceCase.id as number) } /> }
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        {t('Date')}: { serviceCase.date ? format(valueAsDate(serviceCase.date), 'dd.MM.yyyy') : '-' }
      </Box>
      <Box sx={{ mt: 1 }}>
        {t('Comment')}: { serviceCase.comment }
      </Box>
      {
        serviceCase.cost > 0 &&
          <Box sx={{ mt: 1 }}>
            {t('Cost')}: { serviceCase.cost }
          </Box>
      }
      {
        serviceCase.attachments && serviceCase.attachments.length > 0 && <ListAttachments attachments={serviceCase.attachments} />
      }
      <Box sx={{ borderTop: 'thin solid gray', mt: 4  }}>
        <Button
          disabled={!customer?.isActive}
          color="secondary"
          sx={{ mt: 2 }} startIcon={<EditRounded />} onClick={() => setState({ edit: true })}
        >
          {t('Edit')}
        </Button>
      </Box>

    </Box>

  );
}