// Or from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkQueryRequest } from '../../helpers/apiHelper';
import { ReminderType } from '../../types/reminder-type';

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: rtkQueryRequest,
  tagTypes: ['Reminders'],
  endpoints: (builder) => ({
    getProfileReminders: builder.query<ReminderType[], void>({
      query: () => ({ url: `user/reminders` }),
      providesTags: ['Reminders'],
    }),
    addReminder: builder.mutation<void, Partial<ReminderType>>({
      query: (data: Partial<ReminderType>) => {
        return {
          url: `customer/reminder`,
          method: 'post',
          body: data,
        };
      },
      invalidatesTags: ['Reminders'],
    }),
    deleteProfileReminder: builder.mutation<void, number>({
      query: (id: number) => {
        return {
          url: `user/reminders/${id}`,
          method: 'delete'
        };
      },
      invalidatesTags: ['Reminders'],
    })
  })
});

export const { useGetProfileRemindersQuery, useDeleteProfileReminderMutation, useAddReminderMutation } = userProfileApi;