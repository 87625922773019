import { Box } from '@mui/system';
import React, { useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import useLocales from '../../hooks/useLocales';

type FileUploadPropsType = {
  onDrop: (x: any) => void;
}

export default function FileUpload(props: FileUploadPropsType) {
  const { t } = useLocales();
  const onDrop = useCallback(acceptedFiles => {
    props.onDrop(acceptedFiles);
  }, [props]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <Box sx={{ m: 1, p: 1, pl: 3, ml: 0, border: 'thin dotted gray', borderRadius: 1 }} {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>{t('Drop the files here')}</p> :
          <p>{t('Drag and drop some files here, or click to select files')}</p>
      }
    </Box>
  );
}
