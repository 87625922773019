import * as React from "react";
import { useAppSelector } from '../../hooks';

export default function ReportReceiptsGraph() {

  const { details } = useAppSelector(store => store.reports);
  const months: any = details?.months.map(line => {
    let mo: string = line.month.toString();
    if (mo !== '10' && mo !== '11' && mo !== '12') mo = '0' + mo;
    const yearmonth = line.year + '.' + mo;
    return ({
      yearmonth: yearmonth,
      total: line.total,
    });
  });

  return (<></>
  );
}
